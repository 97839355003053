var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{attrs:{"title":"Users"}},[_c('div',{staticClass:"d-flex justify-space-between flex-wrap-reverse"},[_c('div',{staticClass:"d-flex flex-grow-1 mb-3"},[_c('div',{staticClass:"d-flex flex-grow-1"},[_c('e-text-field',{attrs:{"placeholder":"Search...","prepend-inner-icon":"icon-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"pl-3"},[_c('e-select',{staticClass:"select",attrs:{"items":_vm.filters,"placeholder":_vm.filters[0].text},model:{value:(_vm.searchBy),callback:function ($$v) {_vm.searchBy=$$v},expression:"searchBy"}})],1)])]),(_vm.search === '')?_c('no-data-block',{attrs:{"icon":"icon-search","title":"Please use search to find users","description":"To find a user - please use a search bar and type user data to find it."}}):_c('div',[_c('e-data-table',{staticClass:"clickable",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.search === '' || null || _vm.isLoading ? [] : _vm.users,"hide-default-footer":false,"loading":_vm.isLoading,"options":_vm.options,"items-per-page":_vm.options.perPage,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"d-flex align-center"},[(item.verified_at)?_c('e-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"badge-icon"},'div',attrs,false),on),[_c('badge-icon-verified',{staticClass:"mr-3"})],1)]}}],null,true)},[_vm._v(" Identity verified via "+_vm._s(item.verified_with)+" ")]):_c('badge-icon-not-verified',{staticClass:"mr-3"}),_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.id)+" ")]),_c('div')],1)}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.email)+" ")])])}},{key:"item.email",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.email)+" ")]),(item.email)?_c('div',[(item.email_verified_at)?_c('e-chip',{attrs:{"prepend-icon":"icon-checkmark","variant":"success","small":""}},[_vm._v("Verified")]):_c('e-chip',{attrs:{"variant":"warning","small":""}},[_vm._v("Not verified")])],1):_vm._e()])}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.phone_number)+" ")]),(item.phone_number)?_c('div',[(item.phone_verified_at)?_c('e-chip',{attrs:{"prepend-icon":"icon-checkmark","variant":"success","small":""}},[_vm._v("Verified")]):_c('e-chip',{attrs:{"variant":"warning","small":""}},[_vm._v("Not verified")])],1):_vm._e()])}},{key:"item.active",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[(item.active==true)?_c('div',{staticClass:"active_account"},[_vm._v("Activated")]):_c('div',{staticClass:"not_active_account"},[_vm._v("Not activated")])]),_c('div')])}},{key:"no-data",fn:function(){return [_c('no-data-block',{directives:[{name:"show",rawName:"v-show",value:(_vm.search !== '' || null),expression:"search !== '' || null"}],attrs:{"icon":"icon-search","title":"No results!","description":"Nothing matched your search, please try a different combination."}})]},proxy:true},{key:"no-results",fn:function(){return [_c('no-data-block',{directives:[{name:"show",rawName:"v-show",value:(_vm.search !== '' || null),expression:"search !== '' || null"}],attrs:{"icon":"icon-search","title":"No results!","description":"Nothing matched your search, please try a different combination."}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <page-container flat>
      <div>
        <div class="headline-3 graybase--text pa-3 pa-sm-5">Email notifications</div>
        <div class="table-style pt-3 pt-sm-5 d-flex flex-column flex-sm-row">
          <!-- <div
          class="pl-3 pl-sm-5 graybase--text flex mb-3 mb-sm-0 pb-3 pb-sm-5"
          :class="$vuetify.breakpoint.smAndUp ? 'border-right' : 'border-bottom'"
        >
          <div class="subhead-1 pb-6">Messaging preferences</div>
          <div class="callout-3 pb-2">Email notifications frequency</div>
          <e-select class="select-style mr-3 mr-sm-5 body-2" v-model="value" :items="items" :placeholder="items[0]" />
          <e-switch v-model="newMessages" label="New messages" />
        </div> -->
          <div class="pl-3 pl-sm-5 pb-3 pb-sm-5 flex">
            <div class="subhead-1 graybase--text mb-4">General preferences</div>
            <div class="pa-0">
              <div>
                <e-switch
                  class="pa-0 ma-0 mb-5"
                  label="Advertisements"
                  @click="saveUser"
                  v-model="user.disable_advertisements"
                />
              </div>
              <div>
                <e-switch class="pa-0 ma-0 mb-5" label="Newsletter" disabled v-model="user.wish_ekool_news" />
              </div>
              <div>
                <e-switch class="pa-0 ma-0" :label="`Partner's offers`" disabled v-model="user.wish_partner_ads" />
              </div>
              <!-- <div v-for="items of generalPreferences" :key="items">
              <e-switch class="pa-0 ma-0" :label="items" />
            </div> -->
            </div>
          </div>
        </div>
      </div> </page-container
    >
    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import PageContainer from '@/components/PageContainer'
export default {
  components: { PageContainer },
  data() {
    return {
      value: null,
      newMessages: '',
      items: ['Once per week on Friday at 18:00', 'Once per two weeks on Friday'],
      generalPreferences: [
        'Product news and updates',
        'Mentions',
        'Grades',
        'Discounts and offers',
        'Absences',
        'Announcements',
        'Upcoming tests',
      ],
      user: {},
      overlay: false,
    }
  },
  computed: {
    userBusy() {
      return this.$store.getters.userInfoBusy
    },
  },
  methods: {
    async saveUser() {
      this.overlay = true
      try {
        await this.$store.dispatch('saveUser', this.user)
      } catch (e) {
        this.$eToast.error(e)

      } finally {
        this.overlay = false
      }
    },
  },
  async created() {
    this.user = { ...this.$store.getters.userInfo }
  },
}
</script>

<style scoped>
.select-style {
  max-width: 343px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-layout',{staticStyle:{"max-height":"662px"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('e-data-table',{staticClass:"table",attrs:{"itemKey":"id","headers":_vm.headers,"itemsPerPage":50,"items":_vm.rolesBusy ? [] : _vm.roles,"loading":_vm.rolesBusy,"hide-default-footer":false},scopedSlots:_vm._u([{key:"item.school_name",fn:function(ref){
var item = ref.item;
return [_c('e-button',{attrs:{"variant":"link","small":""},on:{"click":function($event){$event.preventDefault();return _vm.chooseSchool(item.institution_id)}}},[_vm._v(_vm._s(item.school_name))])]}},{key:"item.type_id",fn:function(ref){
var item = ref.item;
return [(item.type_id == 'Parent')?_c('span',[_vm._v(_vm._s(item.type_id)+" "),_c('e-button',{attrs:{"variant":"link","small":""},on:{"click":function($event){$event.preventDefault();return _vm.chooseUser(item.person_id)}}},[_vm._v(_vm._s(item.name1)+" "+_vm._s(item.name2))])],1):_c('span',[_vm._v(_vm._s(item.type_id))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'Active')?_c('e-chip',{attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(item.status)+" ")]):(item.status === 'Left')?_c('e-chip',{attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('e-chip',[_vm._v(_vm._s(item.status))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setDateFormat(item.created)))]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setDateFormat(item.modified)))]}},{key:"no-data",fn:function(){return [_c('no-data-block',{attrs:{"icon":"icon-person-with-plus","title":"No roles added!"}})]},proxy:true}],null,true)})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
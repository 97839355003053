<template>
  <div class="card border border-radius">
    <div class="method-desc">
      <h4 class="graybase--text">{{ title }}</h4>
      <span v-if="dateUpdate === date" class="grayscale500--text body-3">Added on {{ setDateFormat(date) }}</span>
      <span v-else class="grayscale500--text body-3">Edited at {{ setDateFormat(dateUpdate) }}</span>
    </div>
    <div class="buttons d-flex align-center justify-end flex-wrap-reverse flex-sm-nowrap">
      <e-button variant="danger" @click="showDeletePaymentModal">Delete</e-button>

      <e-button variant="secondary" @click="showEditPaymentModal">Edit</e-button>
<!--      <edit-payment :id="id" :activeMethodName="title" />-->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import EditPayment from './modals/EditPaymentModal.vue'
export default {
  components: {
    EditPayment,
  },
  props: ['title', 'date', 'dateUpdate', 'id'],
  data() {
    return {
    }
  },
  methods: {
    setDateFormat(value) {
      if (value != null) {
        return moment(value).format('ddd, DD MMM  HH:mm')
      }
    },
    showEditPaymentModal() {
      this.$eDialog({
        component: EditPayment,
        componentProps: {
          id: this.id,
          activeMethodName: this.title
        }
      })
    },
    showDeletePaymentModal() {
      this.$eDialog({
        preset: 'dialog',
        presetProps: {
          title: 'Are you sure you want to continue?',
          description: `You are about to delete ${this.title} from the payment methods, this action is irreversible.`,
          closeButtonLabel: 'Cancel',
          deleteButtonLabel: 'Yes, delete',
        },
        presetEvents: {
          'click:delete': async () => {
            return await this.deletePaymentMethod()
          }
        }
      })
    },
    async deletePaymentMethod() {
      try {
        await this.$store.dispatch('payments/deletePaymentMethod', { id: this.id })
        this.$eToast.success('Payment method was successfully removed')
      } catch (e) {
        this.$eToast.error(e)
        return false
      }
    },
  },
  computed: {
    ...mapState('payments', ['paymentDeletedSuccess', 'paymentDeletedFail', 'errorMessages']),
  },
}
</script>

<style scoped>
.card {
  background: #ffffff;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: auto;
}
.method-desc {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: auto;
}
.buttons {
  display: flex;
  gap: 12px;
}
.e-dialog-body {
  color: #161718 !important;
}
.e-dialog-content {
  padding: 0 !important;
}
</style>

<template>
  <div>
    <e-dialog-header @click:close="close">
      Add local government
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
        <e-text-field
          class="pb-5 pt-5"
          v-model="governmentName"
          label="Local government's name"
          required
          :rules="[validators.required]"
          placeholder="e.g. Yexs"
        />
        <e-text-field
          class="pb-5 pt-5"
          v-model="schoolCount"
          label="Number of schools"
          placeholder="e.g. 30"
        />
        <e-text-field
          class="pb-5 pt-5"
          v-model="population"
          label="Population"
          placeholder="e.g. 21,000,006"
        />

        <loading-overlay :value="overlay"/>
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="addGovernment">Confirm</e-button>
    </e-dialog-actions>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from '@core/utils/validation'

export default {
  name: 'AddGovernmentModal',
  data() {
    return {
      dialog: false,
      valid: false,
      governmentName: '',
      schoolCount: null,
      population: null,
      overlay: false,
      governmentAddedSuccess: false,
    }
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    addGovernment() {
      this.$refs.form.validate()
      if (this.valid) {
        this.overlay = true
        this.$store.dispatch('governments/addGovernment', {
          name: this.governmentName,
          school_count: this.schoolCount,
          population: this.population,
        }).then(() => {
          this.$store.dispatch('governments/getLocalGovernments')
          this.$eToast.success('Local government was successfully added')
          this.overlay = false
          this.close()
        }).catch(e => {
          this.overlay = false
          this.$eToast.error(e)
        })
      }
    },
  },
  computed: {
    ...mapState('governments', ['localGovernments', 'localGovernmentsBusy', 'errorMessages']),
    validators() {
      return {
        required,
      }
    },
  },
}
</script>

<template>
  <page-container title="Statistics">
    <e-circular-loading class="py-10" :value="statisticsBusy" />
    <div v-if="!statisticsBusy" class="row gutter-2">
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <messaging-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <workplan-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <journal-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <discipline-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <development-interview-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <formative-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <subsitutition-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <consent-management />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <development-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <alarmbell-statistics />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <opiq-card />
      </div>
      <div class="col-12 col-sm-6 pa-2 overflow-hidden">
        <riks-notifications />
      </div>
    </div>
  </page-container>
</template>

<script>
import MessagingCard from './cards/MessagingCard.vue'
import WorkplanCard from './cards/WorkplanCard.vue'
import JournalCard from './cards/JournalCard.vue'
import DisciplineCard from './cards/DisciplineCard.vue'
import DevelopmentInterviewCard from './cards/DevelopmentInterviewCard.vue'
import FormativeCard from './cards/FormativeCard.vue'
import SubsitutitionCard from './cards/SubstitutionCard.vue'
import OpiqCard from './cards/OpiqCard.vue'
import DevelopmentCard from './cards/DevelopmentCard.vue'
import ConsentManagement from './cards/ConsentManagement.vue'
import RiksNotifications from './cards/RiksNotifications.vue'
import { mapState } from 'vuex'
import PageContainer from '@/components/PageContainer'
import AlarmbellStatistics from '@/views/data/schools/school-details/statistics/cards/AlarmbellStatistics.vue'

export default {
  components: {
    AlarmbellStatistics,
    PageContainer,
    MessagingCard,
    WorkplanCard,
    JournalCard,
    DisciplineCard,
    DevelopmentCard,
    FormativeCard,
    SubsitutitionCard,
    OpiqCard,
    DevelopmentInterviewCard,
    ConsentManagement,
    RiksNotifications,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('school', [
      'schoolDevelopmentInterviewStatisticsBusy',
      'schoolDevelopmentCardStatisticsBusy',
      'schoolDisciplineStatisticsBusy',
      'schoolFormativeAssessmentStatisticsBusy',
      'schoolJournalStatisticsBusy',
      'schoolMessagingStatisticsBusy',
      'schoolOpiqStatisticsBusy',
      'schoolSubstitutionStatisticsBusy',
      'schoolWorkPlanStatisticsBusy',
      'schoolConsentManagementBusy',
    ]),
    statisticsBusy() {
      return (
        this.schoolDevelopmentInterviewStatisticsBusy ||
        this.schoolDevelopmentCardStatisticsBusy ||
        this.schoolDisciplineStatisticsBusy ||
        this.schoolFormativeAssessmentStatisticsBusy ||
        this.schoolJournalStatisticsBusy ||
        this.schoolMessagingStatisticsBusy ||
        this.schoolOpiqStatisticsBusy ||
        this.schoolSubstitutionStatisticsBusy ||
        this.schoolWorkPlanStatisticsBusy ||
        this.schoolConsentManagementBusy
      )
    },
  },
  async created() {
    let schoolId = this.$route.params.id
    const actionList = [
      'school/getSchoolMessagingStatistics',
      'school/getSchoolPollingStatistics',
      'school/getSchoolRiksStatistics',
      'school/getSchoolWorkPlanStatistics',
      'school/getSchoolJournalStatistics',
      'school/getSchoolTaskStatistics',
      'school/getSchoolDisciplineStatistics',
      'school/getSchoolFormativeAssessmentStatistics',
      'school/getSchoolDevelopmentInterviewStatistics',
      'school/getSchoolSubstitutionStatistics',
      'school/getSchoolOpiqStatistics',
      'school/getSchoolDevelopmentCardStatistics',
      'school/getSchoolConsentManagement',
      'school/getSchoolAlarmbellStatistics',
    ]
    actionList.forEach((action) => {
      this.$store.dispatch(action, { id: schoolId })
    })
  },
}
</script>

import Vue from 'vue'
import * as api from '../../api'
const stateObject = {
  schoolMainInfo: {},
  schoolModules:{},
  schoolModulesBusy: false,
  schoolModuleDeletedSuccess: false,
  schoolMainInfoBusy: false,
  schoolHeadmasters: [],
  schoolHeadmastersBusy: true,
  activeSchoolName: '',
  schoolAdministrators: [],
  schoolAdministratorsBusy: false,
  schoolPaymentsBusy: true,
  schoolPayments: [],
  schoolPaymentAddedSuccess: false,
  schoolPaymentAddedFail: false,
  schoolPaymentUpdatedSuccess: false,
  schoolPaymentUpdatedFail: false,
  schoolPaymentDeletedSuccess: false,
  schoolPaymentDeletedFail: false,
  schoolStudyYear: [],
  schoolActiveRoles: {},
  schoolStudyYearsHistoryBusy: true,
  schoolStudyYearsHistory: {},
  schoolConsentManagement: {},
  schoolConsentManagementBusy: true,
  schoolWorkPlanStatisticsBusy: true,
  schoolWorkPlanStatistics: {},
  schoolJournalStatisticsBusy: true,
  schoolJournalStatistics: {},
  schoolTaskStatisticsBusy: true,
  schoolTaskStatistics: {},
  schoolOpiqStatisticsBusy: true,
  schoolOpiqStatistics: {},
  schoolSubstitutionStatisticsBusy: true,
  schoolSubstitutionStatistics: {},
  schoolFormativeAssessmentStatisticsBusy: true,
  schoolFormativeAssessmentStatistics: {},
  schoolDevelopmentInterviewStatisticsBusy: true,
  schoolDevelopmentInterviewStatistics: {},
  schoolDisciplineStatisticsBusy: true,
  schoolDisciplineStatistics: {},
  schoolDevelopmentCardStatisticsBusy: true,
  schoolDevelopmentCardStatistics: {},
  schoolMessagingStatisticsBusy: true,
  schoolMessagingStatistics: {},
  schoolPollingStatisticsBusy: true,
  schoolPollingStatistics: {},
  schoolAlarmbellStatisticsBusy: true,
  schoolAlarmbellStatistics: {},
  schoolRiksStatisticsBusy: true,
  schoolRiksStatistics: {},
  errorMessages: [],
  schoolPackageList: [],
  schoolPackageListBusy: false,
  updateSchoolPackageListSuccess: false,
}
export default {
  namespaced: true,
  state: { ...stateObject },
  mutations: {
    updateInterviewEnabled(state, newValue) {
      state.schoolMainInfo.interview_enabled = newValue;
      return state.schoolMainInfo
    },
    updateSchoolValueSuccess(state, { name, value }) {
      state[name] = value
      state[name + 'Busy'] = false
    },
    updateSchoolValueFail(state, { name }) {
      state[name + 'Busy'] = false
      state[name + 'Fail'] = true
    },
    updateSchoolValue(state, { name }) {
      state[name + 'Busy'] = true
    },
    updateSchoolModulesSuccess(state, { name, value }) {
      state[name] = value
      state[name + 'Busy'] = false
    },
    updateSchoolModulesFail(state, { name }) {
      state[name + 'Busy'] = false
      state[name + 'Fail'] = true
    },
    updateSchoolModules(state, { name }) {
      state[name + 'Busy'] = true
    },
    deleteModuleSuccess(state, id) {
      state.schoolModuleDeletedSuccess = true
      state.schoolModules = state.schoolModules.filter((e) => e.id !== id)
    },
    saveSchoolInfoSuccess(state, response) {
      if (!response.data.success) {
        state.errorMessages = response.data
        return
      }
    },
    saveSchoolInfoFail(state, response) {
      state.errorMessages = response.data
    },
    saveSchoolInfo(state, data) {
      state.schoolMainInfo = JSON.parse(JSON.stringify(data))
    },
    updatePaymentSuccess(state, response) {
      let data = response.data.data
      state.schoolPaymentUpdatedSuccess = true
      state.schoolPayments = state.schoolPayments.map((item) => {
        if (item.id === data.id) {
          return data
        }
        return item
      })
    },
    addPaymentSuccess(state, response) {
      state.schoolPayments.push(response.data.data)
      state.schoolPaymentAddedSuccess = true
    },
    deletePaymentSuccess(state, id) {
      state.schoolPaymentDeletedSuccess = true
      state.schoolPayments = state.schoolPayments.filter((e) => e.id !== id)
    },
    
    addPaymentFail(state, error) {
      state.errorMessages = error.data.error || error.response.data || []
      state.schoolPaymentAddedFail = true
    },
    updatePaymentFail(state, error) {
      state.errorMessages = error.data.error || error.response.data || []
      state.schoolPaymentUpdatedFail = true
    },
    deletePaymentFail(state, response) {
      state.errorMessages = response.data
      state.schoolPaymentDeletedFail = true
    },
    updateStudyYearHistory(state, response) {
      let data = response.data.data

      state.schoolStudyYearsHistory = state.schoolStudyYearsHistory.map((year) => {
        if (year.id == data.id) {
          return data
        }
        return year
      })
    },
    updateStudyYearHistoryFail(state, response) {
      state.errorMessages = response.data
    },
    updateModules(state, response) {
      let data = response.data.data
      state.schoolModules.push(data)
    },
    updateModulesFail(state, response) {
      state.errorMessages = response.data
    },
    resetState(state) {
      for (let key in state) {
        state[key] = stateObject[key]
      }
    },
    updateSchoolSuccess(state, { response, key }) {
      if (key === 'published') {
        state.schoolMainInfo.published = response.data.data.published
      }
    },
    updateSchoolFail(state, response) {
      state.errorMessages = response.data
    },
    getSchoolPackageListSuccess(state, response) {
      state.schoolPackageListBusy = false

      if (response.data.success) {
        state.schoolPackageList = response.data.data
      }
    },
    getSchoolPackageListFail(state) {
      state.schoolPackageListBusy = false
    },
    setSchoolPackageListBusy(state, value) {
      state.schoolPackageListBusy = value
    },
    deleteSchoolPackageSuccess(state, { response, packageId }) {
      if (response.data.success) {
        const packageIndex = state.schoolPackageList.findIndex((schoolPackage) => schoolPackage.id == packageId)
        Vue.delete(state.schoolPackageList, packageIndex)
      }
    },
    updateSchoolPackageListSuccess(state, { response, packageName }) {
      if (response.data.success) {
        let data = response.data.data
        data.school_package_name = packageName
        state.schoolPackageList.push(data)
        state.updateSchoolPackageListSuccess = true
      }
    },
    setUpdateSchoolPackageListSuccess(state, value) {
      state.updateSchoolPackageListSuccess = value
    },
    updateSchoolPackageListFail(state, response) {},
    resetUpdateSchoolPackageListErrors(state) {},
  },
  actions: {
    getSchoolMainData: (store, { id }) => {
      store.commit('updateSchoolValue', { name: 'schoolMainInfo' })
      return api.getSchoolMainInfo(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolMainInfo' })
          return Promise.resolve(response)
        },

        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolMainInfo' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolModules: (store, { id }) => {
      store.commit('updateSchoolModules', { name: 'schoolModules' })
      return api.getSchoolModules(id).then(
        (response) => {
          store.commit('updateSchoolModulesSuccess', { value: response.data.data, name: 'schoolModules' })
          return Promise.resolve(response)
        },

        (error) => {
          store.commit('updateSchoolModulesFail', { name: 'schoolModules' })
          return Promise.reject(error)
        }
      )
    },
    saveSchoolInfo(store, { id, data }) {
      store.commit('saveSchoolInfo', data)
      return api.saveSchoolInfo(id, store.state.schoolMainInfo).then(
        (response) => {
          store.commit('saveSchoolInfoSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('saveSchoolInfoFail', error)
          return Promise.reject(error)
        }
      )
    },

    getSchoolHeadmasters: (store, { id }) => {
      return api.getSchoolHeadmasters(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolHeadmasters' })
          return Promise.resolve(response)
        },

        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolHeadmasters' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolAdministrators: (store, { id }) => {
      store.commit('updateSchoolValue', { name: 'schoolAdministrators' })
      return api.getSchoolAdministrators(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolAdministrators' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolAdministrators' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolPayments(store, { id }) {
      return api.getSchoolPayments(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolPayments' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolPayments' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolStudyYear(store, { id }) {
      return api.getSchoolStudyYear(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolStudyYear' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolStudyYear' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolActiveRoles(store, { id }) {
      return api.getSchoolActiveRoles(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolActiveRoles' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolActiveRoles' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolStudyYearsHistory(store, { id }) {
      return api.getSchoolStudyYearsHistory(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolStudyYearsHistory' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolStudyYearsHistory' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolConsentManagement(store, { id }) {
      return api.getConsentManagement(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolConsentManagement' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolConsentManagement' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolAlarmbellStatistics(store, { id }) {
      return api.getSchoolAlarmbellStatistics(id).then(
        (response) =>
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolAlarmbellStatistics' }),
        () => store.commit('updateSchoolValueFail', { name: 'schoolAlarmbellStatistics' })
      )
    },
    getSchoolWorkPlanStatistics(store, { id }) {
      return api.getSchoolWorkPlanStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolWorkPlanStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolWorkPlanStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolJournalStatistics(store, { id }) {
      return api.getSchoolJournalStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolJournalStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolJournalStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolTaskStatistics(store, { id }) {
      return api.getSchoolTaskStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolTaskStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolTaskStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolOpiqStatistics(store, { id }) {
      return api.getSchoolOpiqStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolOpiqStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolOpiqStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolSubstitutionStatistics(store, { id }) {
      return api.getSchoolSubstitutionStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolSubstitutionStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolSubstitutionStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolFormativeAssessmentStatistics(store, { id }) {
      return api.getSchoolFormativeAssessmentStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', {
            value: response.data.data,
            name: 'schoolFormativeAssessmentStatistics',
          })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolFormativeAssessmentStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolDevelopmentInterviewStatistics(store, { id }) {
      return api.getSchoolDevelopmentInterviewStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', {
            value: response.data.data,
            name: 'schoolDevelopmentInterviewStatistics',
          })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolDevelopmentInterviewStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolDisciplineStatistics(store, { id }) {
      return api.getSchoolDisciplineStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolDisciplineStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolDevelopmentInterviewStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolDevelopmentCardStatistics(store, { id }) {
      return api.getSchoolDevelopmentCardStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', {
            value: response.data.data,
            name: 'schoolDevelopmentCardStatistics',
          })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolDevelopmentCardStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolMessagingStatistics(store, { id }) {
      return api.getSchoolMessagingStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolMessagingStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolMessagingStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolPollingStatistics(store, { id }) {
      return api.getSchoolPollingStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolPollingStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolPollingStatistics' })
          return Promise.reject(error)
        }
      )
    },
    getSchoolRiksStatistics(store, { id }) {
      return api.getSchoolRiksStatistics(id).then(
        (response) => {
          store.commit('updateSchoolValueSuccess', { value: response.data.data, name: 'schoolRiksStatistics' })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolValueFail', { name: 'schoolRiksStatistics' })
          return Promise.reject(error)
        }
      )
    },
    setStudyYearHistoryStatus(store, { id, data }) {
      return api.setStudyYearHistoryStatus(id, data).then(
        (response) => {
          store.commit('updateStudyYearHistory', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateStudyYearHistoryFail', error)
          return Promise.reject(error)
        }
      )
    },
    addModule(store, { id, data }) {
      return api.addModule(id, data).then(
        (response) => {
          store.commit('updateModules', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateModulesFail', error)
          return Promise.reject(error)
        }
      )
    },
    deleteModule(store, { schoolId,moduleId }) {
      return api.deleteModule(schoolId,moduleId).then(
        (response) => {
          store.commit('deleteModuleSuccess', moduleId)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    saveNewPayment(store, { data }) {
      return api.saveNewPayment(data).then(
        (response) => {
          store.commit('addPaymentSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('addPaymentFail', error)
          return Promise.reject(error)
        }
      )
    },
    updatePayment(store, { data, id }) {
      return api.updatePayment(data, id).then(
        (response) => {
          store.commit('updatePaymentSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    deletePayment(store, { id }) {
      return api.deletePayment(id).then(
        (response) => {
          store.commit('deletePaymentSuccess', id)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },

    updateSchoolInformation(store, { published, id, key }) {
      return api.updateSchoolInformation(published, id).then(
        (response) => {
          store.commit('updateSchoolSuccess', { response, key })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolFail', { error, key })
          return Promise.reject(error)
        }
      )
    },
    getSchoolPackageList(store, { schoolId }) {
      store.commit('setSchoolPackageListBusy', true)
      return api.getSchoolPackageList(schoolId).then(
        (response) => {
          store.commit('getSchoolPackageListSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('getSchoolPackageListFail', error)
          return Promise.reject(error)
        }
      )
    },
    deleteSchoolPackage(store, { schoolId, packageId }) {
      return api.deleteSchoolPackage(schoolId, packageId).then(
        (response) => {
          store.commit('deleteSchoolPackageSuccess', { response, packageId })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('deleteSchoolPackageFail', error)
          return Promise.reject(error)
        }
      )
    },
    updateSchoolPackageList(store, { schoolId, packageData, packageName }) {
      return api.updateSchoolPackageList(schoolId, packageData).then(
        (response) => {
          store.commit('updateSchoolPackageListSuccess', { response, packageName })
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updateSchoolPackageListFail', error)
          return Promise.reject(error)
        }
      )
    },
  },
  getters: {
    schoolPayments: (state) => {
      return state.schoolPayments
    },
    schoolInfo: (state) => {
      return state.schoolMainInfo
    },
    schoolModules: (state) => {
      return state.schoolModules
    },
  },
}

<template>
  <v-overlay :value="value" :opacity="value && show ? '0.46' : 0">
    <e-circular-loading v-show="value && show"/>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingOverlay',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    timeout: {
      type: Number,
      default: 300,
    },
  },

  data() {
    return {
      show: false,
      timer: null,
    }
  },

  watch: {
    value: {
      handler(value) {
        this.destroyTimer()

        if (value) {
          if (this.timeout > 0) {
            this.timer = setTimeout(() => (this.show = true), this.timeout)
          } else this.show = true
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.destroyTimer()
  },

  methods: {
    destroyTimer() {
      this.timer && clearTimeout(this.timer)
      this.timer = null
      this.show = false
    },
  },
}
</script>

<template>
  <StatisticsCard title="Substitution statistics" icon="icon-person">
    <StatisticsCardField description="Teachers away" :value="schoolSubstitutionStatistics.teacher_is_away" />
    <StatisticsCardField description="Opened substitutions" :value="schoolSubstitutionStatistics.opened_substitutions" />
    <StatisticsCardField description="Substitution lessons" :value="schoolSubstitutionStatistics.subsitutition_lessons" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  components: {StatisticsCardField, StatisticsCard},
  computed: {
    ...mapState('school', [
      'schoolSubstitutionStatistics'
    ]),
  },
}
</script>

<template>
  <StatisticsCard title="Consent management statistics" icon="icon-file-folders">
    <StatisticsCardField description="Published consents" :value="schoolConsentManagement.filter((item) => item.status === 'published').length" />
    <StatisticsCardField description="Archived consents" :value="schoolConsentManagement.filter((item) => item.status === 'archived').length" />
    <StatisticsCardField description="Drafted consents" :value="schoolConsentManagement.filter((item) => item.status === 'drafted').length" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  components: {StatisticsCardField, StatisticsCard},
  computed: {
    ...mapState('school', ['schoolConsentManagement']),
  },
}
</script>

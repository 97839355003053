<template>
  <div>
    <e-dialog-header @click:close="close">
      Edit payment
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
          <e-text-field
            required
            :rules="[validators.required]"
            class="pb-8 pt-5"
            label="Payer"
            v-model="paymentData.payer"
          />
          <e-select
            class="pb-8"
            label="Payment method"
            v-model="paymentMethod"
            :items="paymentMethods"
            item-text="name"
            item-value="id"
            return-object
            single-line
          />

          <e-text-field
            required
            :rules="[validators.required]"
            class="pb-3"
            label="Price"
            prependInnerIcon="icon-money-bill"
            v-model="paymentData.fee"
          />
          <e-hint title="Price is expressed in Euros (EUR)" dismissible class="pb-3" />

          <e-text-field
            required
            :rules="[validators.required, validators.onlyDigits]"
            class="pb-8 pt-8"
            label="Customer ID"
            v-model="paymentData.customer_id"
          />
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="savePayment">Save edits</e-button>
    </e-dialog-actions>

    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from '@core/utils/validation'
export default {
  props: ['paymentId', 'paymentFee', 'paymentName', 'payer', 'customerId', 'paymentMethodId'],
  data() {
    return {
      overlay: false,
      valid: false,
      paymentMethod: { name: this.paymentName, id: this.paymentMethodId },
      paymentData: {
        payer: this.payer,
        fee: this.paymentFee,
        customer_id: this.customerId,
      },
    }
  },
  created() {
    this.$store.dispatch('payments/getPaymentMethods')
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async savePayment() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      let id = parseInt(this.$route.params.id)
      let data = {
        ...this.paymentData,
        institution_id: id,
        payment_method_id: this.paymentMethod.id,
      }
      await this.$store.dispatch('school/updatePayment', { data, id: this.paymentId }).then(
        () => {
          this.$eToast.success('Payment was successfully changed')
          this.close()
          this.overlay = false
        },
        (err) => {
          this.$eToast.error(err)
          this.overlay = false
        }
      )
    },
  },
  watch: {
    // schoolPaymentUpdatedSuccess() {
    //   if (this.schoolPaymentUpdatedSuccess) {
    //     this.overlay = false
    //     this.addPayment = false
    //     this.$eToast.success('Payment was successfully changed')
    //     this.$store.commit('school/updateSchoolValue', { name: 'schoolPaymentUpdatedSuccess', value: false })
    //   }
    // },
    // schoolPaymentUpdatedFail() {
    //   if (this.schoolPaymentUpdatedFail) {
    //     this.overlay = false
    //     this.$eToast.error(this.errorMessages)
    //     this.$store.commit('school/updateSchoolValue', { name: 'schoolPaymentUpdatedFail', value: false })
    //   }
    // },
  },
  computed: {
    ...mapState('payments', ['paymentMethods']),
    ...mapState('school', [
      'schoolPayments',
      'schoolPaymentsBusy',
      'schoolPaymentsFail',
      'schoolPaymentUpdatedFail',
      'schoolPaymentDeletedFail',
      'schoolPaymentUpdatedSuccess',
      'schoolPaymentDeletedSuccess',
      'errorMessages',
    ]),
    validators() {
      return {
        required,
        onlyDigits: (value) => {
          return !isNaN(value) || 'Customer ID must be a number'
        },
      }
    },
  },
}
</script>

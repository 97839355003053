<template>
  <page-container title="Grade logs">
    <div class="row gutter-2">
      <div class="col-12 col-md-6 px-2 pb-3 pb-sm-5">
        <div class="body-1 graybase--text mb-6">Fill in the form to search and generate logs data for grades</div>
        <div v-if="fileEmpty"> File is empty </div>

        <e-button
          v-if="gradeLogsSuccess && generatedSchoolID === this.schoolID"
          large
          variant="tertiary"
          @click="downloadFile"
          >Download {{ fileName }}</e-button
        >
      </div>
      <div class="px-2 col-12 col-md-6 pb-2">
        <e-hint
          v-if="gradeLogsFail"
          class="mb-3 mb-sm-5"
          variant="danger"
          dismissible
          small
          title="Data cannot be queried, please try again!"
        />
        <e-hint
          v-if="gradeLogsSuccess && generatedSchoolID === this.schoolID"
          class="mb-3 mb-sm-5"
          variant="info"
          dismissible
          small
          title="Data export generated!"
        />
        <e-text-field large v-model="studentId" placeholder="Add label" label="Student ID code" required class="pt-6" />
        <e-text-field large v-model="journalName" placeholder="Add label" label="Journal name" class="pt-10" />

        <div class="callout-3 graybase--text pt-3 pt-sm-5">Number of days</div>

        <v-btn-toggle v-model="toggle" class="pt-3 d-flex flex-wrap" v-if="!switchValue">
          <div v-for="(date, idx) in dates" :key="date" class="d-flex mb-3 align-center">
            <e-button variant="ghost" class="">{{ date }}</e-button>
            <div v-if="idx !== dates.length - 1" class="toggle-separator mx-3"></div>
          </div>
        </v-btn-toggle>
        <div class="pt-4">
          <e-switch label="Custom range" v-model="switchValue" @click="closeDatePickers" />
        </div>
        <div v-if="switchValue">
          <div class="pb-8 pt-7">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              nudge-left="293"
              min-width="auto"
              v-model="showDatePicker"
            >
              <template v-slot:activator="{ on, attrs }">
                <e-text-field
                  v-model="dateFormattedFrom"
                  @blur="startDate = parseDate(dateFormattedFrom)"
                  @keyup.enter="startDate = parseDate(dateFormattedFrom)"
                  @input="setDateFrom"
                  placeholder="Start date"
                  label="From"
                  append-icon="icon-calendar"
                  required
                  v-bind="attrs"
                  v-on="on"
                  @click:append="on.click"
                />
              </template>
              <e-date-picker v-model="startDate" @input="showDatePicker = false">
                <v-spacer></v-spacer>
              </e-date-picker>
            </v-menu>
          </div>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            nudge-left="293"
            min-width="auto"
            v-model="showDatePicker2"
          >
            <template v-slot:activator="{ on, attrs }">
              <e-text-field
                v-model="dateFormattedTo"
                @blur="endDate = parseDate(dateFormattedTo)"
                @keyup.enter="endDate = parseDate(dateFormattedTo)"
                @input="setDateTo"
                placeholder="End date"
                label="Until"
                append-icon="icon-calendar"
                required
                v-bind="attrs"
                v-on="on"
                @click:append="on.click"
              />
            </template>
            <e-date-picker v-model="endDate" @input="showDatePicker2 = false">
              <v-spacer></v-spacer>
            </e-date-picker>
          </v-menu>
        </div>
        <e-button block large class="mt-4" @click.prevent="generateData">Generate data</e-button>
      </div>
    </div>
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import PageContainer from '@/components/PageContainer'
export default {
  components: { PageContainer },
  data() {
    return {
      switchValue: false,
      showDatePicker: false,
      showDatePicker2: false,
      dates: ['14 days', '1 month', '6 months', '1 year', '2 years'],
      studentId: '',
      journalName: '',
      endDate: '',
      startDate: '',
      dateFormattedFrom: '',
      dateFormattedTo: '',
      toggle: 0,
      generatedSchoolID: null,
      appURL: process.env.APP_URL,
      pathGenerated: false,
      path: '',
      fileName: 'grade-logs.csv',
    }
  },
  computed: {
    ...mapState('gradeLogs', [
      'gradeLogsBusy',
      'gradeLogsSuccess',
      'gradeLogsFile',
      'errorMessage',
      'gradeLogsFail',
      'fileEmpty',
    ]),
    computedDateFormattedFrom() {
      return this.formatDate(this.startDate)
    },
    computedDateFormattedTo() {
      return this.formatDate(this.endDate)
    },
  },
  watch: {
    startDate(val) {
      this.dateFormattedFrom = this.formatDate(this.startDate)
    },
    endDate(val) {
      this.dateFormattedTo = this.formatDate(this.endDate)
    },
    gradeLogsSuccess() {
      if (this.gradeLogsSuccess) {
        this.path = 'data:text/csv' + encodeURI(this.gradeLogsFile)
      }
    },
    toggle: {
      handler(val) {
        const start = {
          0: moment().subtract(14, 'day').format('YYYY-MM-DD'),
          1: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          2: moment().subtract(6, 'month').format('YYYY-MM-DD'),
          3: moment().subtract(1, 'year').format('YYYY-MM-DD'),
          4: moment().subtract(2, 'year').format('YYYY-MM-DD'),
        }
        this.startDate = start[val]
        const end = {
          0: moment().format('YYYY-MM-DD'),
          1: moment().format('YYYY-MM-DD'),
          2: moment().format('YYYY-MM-DD'),
          3: moment().format('YYYY-MM-DD'),
          4: moment().format('YYYY-MM-DD'),
        }
        this.endDate = end[val]
      },
    },
  },
  methods: {
    downloadFile() {
      const url = window.URL.createObjectURL(new Blob([this.gradeLogsFile]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.fileName)
      document.body.appendChild(link)
      link.click()
    },
    closeDatePickers() {
      this.showDatePicker = false
      this.showDatePicker2 = false
    },
    async setDateFrom(value) {
      if (this.validateDate(value)) {
        this.startDate = this.parseDate(value)
      }
    },
    async setDateTo(value) {
      if (this.validateDate(value)) {
        this.endDate = this.parseDate(value)
      }
    },
    validateDate(value) {
      return value.match(/^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/)
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    generateData() {
      const data = {
        schoolID: this.schoolID,
        studentIDCode: this.studentId,
      }
      this.generatedSchoolID = this.schoolID
      if (this.startDate != '' && this.startDate != null) {
        data.dateFrom = moment(this.startDate).format('YYYY-MM-DD')
      }
      if (this.endDate != '' && this.endDate != null) {
        data.dateTo = moment(this.endDate).format('YYYY-MM-DD')
      }
      if (this.journalName != '') {
        data.journalName = this.journalName
      }
      this.$store.dispatch('gradeLogs/getGradeLogs', data)
    },
  },
  created() {
    this.schoolID = parseInt(this.$route.params.id)
  },
}
</script>

import axios from '@/plugins/axios'

export const getSessionList = (user_id) => {
  return axios.get('users/' + user_id + '/sessions')   
}

export const revokeSession =(user_id, session_id,session_type) => {
  const payload = { session_id: session_id ,session_type:session_type};
  return axios.post('users/' + user_id + '/sessions/revoke', payload)
 
}
export const revokeAllSessions = (user_id) => {
  return axios.post('users/' + user_id + '/sessions/revoke/all')
}

<template>
  <div>
    <e-dialog-header @click:close="close">
      Logs
    </e-dialog-header>
    <e-dialog-content>
  <div>No payments in last 18 months</div>
    </e-dialog-content>
  </div>
</template>

<script>

export default {
  name: 'PremiumLogsEmptyModal',
  data() {
    return {
    }
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
  },

}
</script>

import { render, staticRenderFns } from "./LogsTable.vue?vue&type=template&id=785f72f9&scoped=true&"
import script from "./LogsTable.vue?vue&type=script&lang=js&"
export * from "./LogsTable.vue?vue&type=script&lang=js&"
import style0 from "./LogsTable.vue?vue&type=style&index=0&id=785f72f9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785f72f9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VForm,VMenu,VSpacer})

import UserLogins from '@/views/statistics/logins/user-logins/UserLogins.vue'
import LoginMethods from '@/views/statistics/logins/login-methods/LoginMethods.vue'
import Messaging from '@/views/statistics/usage/messaging/Messaging.vue'
import Reactions from '@/views/statistics/usage/reactions/Reactions.vue'
import Horizon from '@/views/statistics/horizon/Horizon.vue'

const statistics = [
  {
    path: '/statistics/logins/user-logins',
    name: 'statistics-logins-user',
    component: UserLogins,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/statistics/logins/methods',
    name: 'statistics-login-methods',
    component: LoginMethods,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/statistics/usage/messaging',
    name: 'statistics-usage-messaging',
    component: Messaging,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/statistics/usage/reactions',
    name: 'statistics-usage-reactions',
    component:Reactions,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/statistics/horizon',
    name: 'statistics-horizon',
    component: Horizon,
    meta: {
      layout: 'content',
    },
  },
]

export default statistics

import axios from '@/plugins/axios'

// GET REQUESTS
let controller = null
export const getUsers = (page, perPage, search, searchBy, order, orderBy) => {
  if (controller) {
    controller.abort()
  }
  controller = new AbortController()
  // create options for get request
  let options = {
    params: {
      page,
      perPage,
      search,
      searchBy,
      order,
      orderBy,
    },
  }
  return axios.get('users', options)
}
export const getUserInfo = (userId) => {
  return axios.get('users/' + userId + '/edit')
}
export const getUserLogs = (page, perPage, search, dateFrom, dateTo, searchBy) => {
  const options = {
    params: {
      page,
      perPage,
      search,
      start_date: dateFrom,
      end_date: dateTo,
      searchBy,
    },
  }
  return axios.get('users-logs', options)
}
export const getUserRoles = (userId, page, perPage) => {
  const options = {
    params: {
      page,
      perPage,
    },
  }
  return axios.get('users/' + userId + '/roles', options)
}
export const getUserServices = (userId) => {
  return axios.get('users/' + userId + '/services-new')
}
export const getUserServiceLog = (userId, buyerId) => {
  const options = {
    params: {
      buyer_id: buyerId,
    },
  }
  return axios.get('users/' + userId + '/log', options)
}
export const getPremiumUsers = (userId) => {
  const options = {
    params: {
      person_id: userId,
    },
  }
  return axios.get('premium-edit-change-owner-list', options)
}
export const impersonateUser = (userId) => {
  return axios.get(`users/${userId}/impersonate`)
}



// POST REQUESTS
export const changePremiumOwner = (transactionId, userId) => {
  let options = {
    transaction_id: transactionId,
    person_id: userId,
  }
  return axios.post('premium-edit-change-owner', options)
}
export const addPremium = (userId, dateFrom, dateTo) => {
  const options = {
    person_id: userId,
    created_at: dateFrom,
    expired_at: dateTo,
  }
  return axios.post('premium-edit-activate', options)
}
export const resetPremium = (userId) => {
  const options = {
    params: {
      person_id: userId,
    },
  }
  return axios.post('premium-edit-reset', options)
}
export const saveUserInfo = (data) => {
  const options = {
    params: {
      name1: data.name1,
      name2: data.name2,
      user_name: data.user_name,
      id_code: data.id_code,
      country: data.country_id,
      password: data.password,
      remember_old_password: data.savePassword,
      disable_advertisements: data.disable_advertisements,
    },
  }
  return axios.post('users/' + data.id, options)
}
export const resetPassword = (userId) => {
  return axios.post('users/' + userId + '/resetpassword')
}
export const savePassword = (userId) => {
  return axios.post('users/' + userId + '/permanentpassword')
}
export const validateEmail=(userId)=>{
  return axios.post('users/'+userId+'/validate/email')
}
export const validatePhone=(userId)=>{
  return axios.post('users/'+userId+'/validate/phone')
}
export const invalidateEmail=(userId)=>{
  return axios.post('users/'+userId+'/invalidate/email')
}
export const invalidatePhone=(userId)=>{
  return axios.post('users/'+userId+'/invalidate/phone')
}

// DELETE REQUESTS
export const deleteUser = (userId) => {
  return axios.delete('users/' + userId)
}
export const cancelPremium = (subscriptionId) => {
  const options = {
    params: {
      transaction_id: subscriptionId,
    },
  }
  return axios.delete('premium-edit-deactivate', options)
}

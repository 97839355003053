import * as api from '../../api'
import * as types from '../mutation-types'

export default {
  state: {
    success: false,
    busy: false,
    userInfoBusy: false,
    userLastLoginBusy: false,
    rolesBusy: false,
    servicesBusy: false,
    fail: false,
    user: {},
    userLastLogin: [],
    schools: [],
    serviceSettings: [],
    logs: [],
    rolesPagination: [],
    services: [],
    serviceUsers: {},
    errors: [],
    logsError: {},
  },
  mutations: {
    [types.GET_USER_INFO](state) {
      state.userInfoBusy = true
    },
    [types.GET_USER_INFO_SUCCESS](state, response) {
      state.userInfoBusy = false
      state.user = response.data.data || {}
    },
    [types.GET_USER_INFO_FAIL](state, error) {
      state.userInfoBusy = false
      state.success = error.data.success || false
      state.fail = error
    },
    [types.GET_USER_LAST_LOGIN](state) {
      state.userLastLoginBusy = true
    },
    [types.GET_USER_LAST_LOGIN_SUCCESS](state, response) {
      state.userLastLoginBusy = false
      state.userLastLogin = response.data.data || {}
    },
    [types.GET_USER_LAST_LOGIN_FAIL](state, error) {
      state.userLastLoginBusy = false
      state.success = error.data.success || false
      state.fail = error
      //console.log('error')
    },
    [types.GET_USER_ROLES](state) {
      state.rolesBusy = true
    },
    [types.GET_USER_ROLES_SUCCESS](state, response) {
      state.rolesBusy = false
      state.rolesPagination = response.data.data || {}
      state.schools = response.data.data.data || []
    },
    [types.GET_USER_ROLES_FAIL](state, error) {
      state.rolesBusy = false
      state.success = error.data.success || false
      state.fail = error
      //console.log('error')
    },
    [types.GET_USER_SERVICES](state) {
      state.servicesBusy = true
    },
    [types.GET_USER_SERVICES_SUCCESS](state, response) {
      state.servicesBusy = false
      state.services = response.data.data || []
    },
    [types.GET_USER_SERVICES_FAIL](state, error) {
      state.servicesBusy = false
      state.success = error.data.success || false
      state.fail = error
      //console.log('error')
    },
    [types.GET_SERVICE_SETTINGS](state) {
      state.servicesBusy = true
    },
    [types.GET_SERVICE_SETTINGS_SUCCESS](state, response) {
      state.servicesBusy = false
      state.serviceSettings = response.data.data || []
    },
    [types.GET_SERVICE_SETTINGS_FAIL](state, error) {
      state.servicesBusy = false
      state.success = error.data.success || false
      state.fail = error
      //console.log('error')
    },
    [types.GET_USER_SERVICE_LOG](state) {
      state.servicesBusy = true
    },
    [types.GET_USER_SERVICE_LOG_SUCCESS](state, response) {
      state.servicesBusy = false
      state.logs = response.data.data || []
      state.logsError = response.data || {}
      if (state.servicesBusy === false) {
        state.logs.forEach((el) => el.payments.reverse())
      }
    },
    [types.GET_USER_SERVICE_LOG_FAIL](state, error) {
      state.servicesBusy = false
      state.success = false
      state.fail = error
      //console.log('error')
    },
    [types.ADD_PREMIUM](state) {
      state.servicesBusy = true
    },
    [types.ADD_PREMIUM_SUCCESS](state, response) {
      state.servicesBusy = false
    },
    [types.ADD_PREMIUM_FAIL](state, error) {
      state.servicesBusy = false
      state.fail = error
      //console.log('error')
    },
    [types.RESET_PREMIUM](state) {
      state.servicesBusy = true
    },
    [types.RESET_PREMIUM_SUCCESS](state) {
      state.servicesBusy = false
    },
    [types.RESET_PREMIUM_FAIL](state, error) {
      state.servicesBusy = false
      state.fail = error
      //console.log('error')
    },
    [types.CANCEL_PREMIUM](state) {
      state.servicesBusy = true
    },
    [types.CANCEL_PREMIUM_SUCCESS](state) {
      state.servicesBusy = false
    },
    [types.CANCEL_PREMIUM_FAIL](state, error) {
      state.servicesBusy = false
      state.fail = error
      //console.log('error')
    },
    [types.CHANGE_PREMIUM_OWNER](state) {
      state.servicesBusy = true
    },
    [types.CHANGE_PREMIUM_OWNER_SUCCESS](state) {
      state.servicesBusy = false
    },
    [types.CHANGE_PREMIUM_OWNER_FAIL](state, error) {
      state.servicesBusy = false
      state.fail = error
      //console.log('error')
    },
    [types.GET_PREMIUM_USERS](state) {
      state.servicesBusy = true
    },
    [types.GET_PREMIUM_USERS_SUCCESS](state, response) {
      state.servicesBusy = false
      state.serviceUsers = response.data.data
      // console.log(response.data.data)
    },
    [types.GET_PREMIUM_USERS_FAIL](state, error) {
      state.servicesBusy = false
      state.fail = error
      //console.log('error')
    },
    [types.SAVE_USER_INFO](state, userData) {
      state.userInfoBusy = true
      state.user = JSON.parse(JSON.stringify(userData))
    },
    [types.SAVE_USER_INFO_SUCCESS](state, response) {
      state.userInfoBusy = false
      state.errors = response.data.errors || []
      // console.log(response.data.errors)
    },
    [types.SAVE_USER_INFO_FAIL](state, error) {
      state.userInfoBusy = false
      state.success = error.data.success || false
      state.fail = error
      // state.errors = error.data.errors
      // console.log(error.errors)
    },
    [types.UPDATE_PASSWORD](state) {
      state.userInfoBusy = true
    },
    [types.UPDATE_PASSWORD_SUCCESS](state) {
      state.userInfoBusy = false
    },
    [types.UPDATE_PASSWORD_FAIL](state, error) {
      state.userInfoBusy = false
      state.success = error.data.success || false
      state.fail = error
      //console.log('error')
    },
    [types.DELETE_USER](state) {
      state.userInfoBusy = true
    },
    [types.DELETE_USER_SUCCESS](state) {
      state.userInfoBusy = false
      state.user = {}
    },
    [types.DELETE_USER_FAIL](state, error) {
      state.userInfoBusy = false
      state.success = error.data.success || false
      state.fail = error
      //console.log('error')
    },
  },
  actions: {
    getUserInfo: (store, { userId }) => {
      store.commit(types.GET_USER_INFO)
      return api.getUserInfo(userId).then(
        (response) => store.commit(types.GET_USER_INFO_SUCCESS, response),
        (error) => store.commit(types.GET_USER_INFO_FAIL, error)
      )
    },
    getUserLastLogin: (store, { userId }) => {
      store.commit(types.GET_USER_LAST_LOGIN)
      return api.getUserLastLogin(userId).then(
        (response) => store.commit(types.GET_USER_LAST_LOGIN_SUCCESS, response),
        (error) => store.commit(types.GET_USER_LAST_LOGIN_FAIL, error)
      )
    },
    getUserRoles: (store, { userId, page, perPage }) => {
      store.commit(types.GET_USER_ROLES)
      return api.getUserRoles(userId, page, perPage).then(
        (response) => store.commit(types.GET_USER_ROLES_SUCCESS, response),
        (error) => store.commit(types.GET_USER_ROLES_FAIL, error)
      )
    },
    getUserServiceLog: (store, { userId, buyerId }) => {
      store.commit(types.GET_USER_SERVICE_LOG)
      return api.getUserServiceLog(userId, buyerId).then(
        (response) => {
          store.commit(types.GET_USER_SERVICE_LOG_SUCCESS, response)
          return response},
        (error) => {store.commit(types.GET_USER_SERVICE_LOG_FAIL, error)
          return error}
      )
    },
    getUserServices: (store, userId) => {
      store.commit(types.GET_USER_SERVICES)
      return api.getUserServices(userId).then(
        (response) => store.commit(types.GET_USER_SERVICES_SUCCESS, response),
        (error) => store.commit(types.GET_USER_SERVICES_FAIL, error)
      )
    },
    getServiceSettings: (store, { userId, premiumId }) => {
      store.commit(types.GET_SERVICE_SETTINGS)
      return api.getUserServiceSettings(userId, premiumId).then(
        (response) => store.commit(types.GET_SERVICE_SETTINGS_SUCCESS, response),
        (error) => store.commit(types.GET_SERVICE_SETTINGS_FAIL, error)
      )
    },
    addPremium: (store, { userId, dateFrom, dateTo }) => {
      store.commit(types.ADD_PREMIUM)
      // console.log('iserid', userId)
      // console.log('from', dateFrom)
      // console.log('to', dateTo)
      return api.addPremium(userId, dateFrom, dateTo).then(
        (response) => store.commit(types.ADD_PREMIUM_SUCCESS, response),
        (error) => store.commit(types.ADD_PREMIUM_FAIL, error)
      )
    },
    resetPremium: (store, subscriptionId) => {
      store.commit(types.CANCEL_PREMIUM)
      return api.resetPremium(subscriptionId).then(
        (response) => store.commit(types.CANCEL_PREMIUM_SUCCESS, response),
        (error) => store.commit(types.CANCEL_PREMIUM_FAIL, error)
      )
    },
    cancelSubscription: (store, subscriptionId) => {
      store.commit(types.RESET_PREMIUM)
      return api.cancelPremium(subscriptionId).then(
        (response) => store.commit(types.RESET_PREMIUM_SUCCESS, response),
        (error) => store.commit(types.RESET_PREMIUM_FAIL, error)
      )
    },
    getPremiumUsers: (store, userId) => {
      store.commit(types.GET_PREMIUM_USERS)
      return api.getPremiumUsers(userId).then(
        (response) => store.commit(types.GET_PREMIUM_USERS_SUCCESS, response),
        (error) => store.commit(types.GET_PREMIUM_USERS_FAIL, error)
      )
    },
    changePremiumOwner: (store, { transactionId, userId }) => {
      store.commit(types.CHANGE_PREMIUM_OWNER)
      return api.changePremiumOwner(transactionId, userId).then(
        (response) => {
          if (!response.data.success) {
            return Promise.reject(response.data.error)
          }
          store.commit(types.CHANGE_PREMIUM_OWNER_SUCCESS, response)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error.message)
        }
      )
    },
    saveUser: (store, userData) => {
      store.commit(types.SAVE_USER_INFO, userData)
      return api.saveUserInfo(store.state.user).then(
        (response) => { 
          store.commit(types.SAVE_USER_INFO_SUCCESS, response)},
        (error) => store.commit(types.SAVE_USER_INFO_FAIL, error)
      )
    },
    resetPassword: (store, userId) => {
      store.commit(types.UPDATE_PASSWORD, userId)
      return api.resetPassword(userId).then(
        (response) => store.commit(types.UPDATE_PASSWORD_SUCCESS, response),
        (error) => store.commit(types.UPDATE_PASSWORD_FAIL, error)
      )
    },
    savePassword: (store, userId) => {
      store.commit(types.UPDATE_PASSWORD, userId)
      return api.savePassword(userId).then(
        (response) => store.commit(types.UPDATE_PASSWORD_SUCCESS, response),
        (error) => store.commit(types.UPDATE_PASSWORD_FAIL, error)
      )
    },
    deleteUser: (store, userId) => {
      store.commit(types.DELETE_USER, userId)
      return api.deleteUser(userId).then(
        (response) => store.commit(types.DELETE_USER_SUCCESS, response),
        (error) => store.commit(types.DELETE_USER_FAIL, error)
      )
    },
  },
  getters: {
    userInfo: (state) => {
      return state.user
    },
    userLastLogin: (state) => {
      return state.userLastLogin
    },
    userRoles: (state) => {
      return state.schools.length === 0 ? null : state.schools
    },
    rolesPagination: (state) => {
      return state.rolesPagination.length === 0 ? null : state.rolesPagination
    },
    userServices: (state) => {
      return state.services.length === 0 ? [] : state.services
    },
    // userServices: (state) => {
    //  return  state.services.length === 0 ? null : state.services
    // },
    serviceUsers: (state) => {
      return state.serviceUsers.length === 0 ? null : state.serviceUsers
    },
    userServiceLog: (state) => {
      return state.logs.length === 0 ? null : state.logs
    },
    userServiceSettings: (state) => {
      return state.serviceSettings.length === 0 ? null : state.serviceSettings
    },
    userInfoBusy: (state) => {
      return state.userInfoBusy
    },
    userLastLoginBusy: (state) => {
      return state.userLastLoginBusy
    },
    rolesBusy: (state) => {
      return state.rolesBusy
    },
    servicesBusy: (state) => {
      return state.servicesBusy
    },
    saveErrors: (state) => {
      return state.errors.length === 0 ? null : state.errors
    },
    logsError: (state) => {
      return state.logsError
    },
  },
}

<template>
  <StatisticsCard title="Development interview statistics" icon="icon-message-bubbles">
    <StatisticsCardField
      v-for="statistics in schoolDevelopmentInterviewStatistics"
      :key="statistics.name"
      :description="statistics.name"
      :warning="!statistics.signed_protocols"
      :value="getStatisticsString(statistics.signed_protocols)" />
  </StatisticsCard>
</template>
<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  name: 'DevelopmentInterviewCard',
  components: {StatisticsCardField, StatisticsCard},
  computed: {
    ...mapState('school', ['schoolDevelopmentInterviewStatistics']),
  },
  methods: {
    getStatisticsString(stat) {
      if (!stat) {
        return 'Protocols not signed'
      } else {
        return `${stat} signed protocols`
      }
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{attrs:{"title":"Headmasters"}},[_c('e-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"items":_vm.schoolHeadmasters,"item-key":"id","loading":_vm.schoolHeadmastersBusy},scopedSlots:_vm._u([{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"info_about_user"},[(item.verified_at)?_c('e-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"badge-icon"},'div',attrs,false),on),[_c('badge-icon-verified',{staticClass:"mr-3"})],1)]}}],null,true)},[_vm._v(" Identity verified via "+_vm._s(item.verified_with)+" ")]):_c('div',{staticClass:"badge-icon"},[_c('badge-icon-not-verified',{staticClass:"mr-3"})],1),_c('a',{staticClass:"table-link",on:{"click":function($event){$event.preventDefault();return _vm.handleClick(item)}}},[_vm._v(_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")])],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(item.email))]),(item.email)?_c('div',[(item.email_verified_at)?_c('e-chip',{attrs:{"prepend-icon":"icon-checkmark","variant":"success","small":""}},[_vm._v("Verified")]):_c('e-chip',{attrs:{"variant":"warning","small":""}},[_vm._v("Not verified")])],1):_vm._e()])}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(item.phone_number))]),(item.phone_number)?_c('div',[(item.phone_verified_at)?_c('e-chip',{attrs:{"prepend-icon":"icon-checkmark","variant":"success","small":""}},[_vm._v("Verified")]):_c('e-chip',{attrs:{"variant":"warning","small":""}},[_vm._v("Not verified")])],1):_vm._e()])}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active==true)?_c('div',{staticClass:"active_account"},[_vm._v("Activated")]):_c('div',{staticClass:"not_active_account"},[_vm._v("Not activated")])]}},{key:"no-data",fn:function(){return [_c('no-data-block',{attrs:{"icon":"icon-person-with-plus","title":"No headmasters added!"}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="session-item">
    <session-icon :session="session" />


    <div class="session-info">
      <div class="subhead-2 mb-1">
        {{ session.client_name || session.browser }} {{ session.platform !== 'Unknown' && session.platform !== 'unknown'? "on":" " }} {{ session.platform !== 'Unknown' && session.platform !== 'unknown' ? session.platform : "" }}
      </div>
      <div
        class="session-info-desc"
      >  
        <div class="caption-1 session-ip-address">
          {{ (session.country && session.country!=='Unknown') ? session.country : 'Not available' }} · {{ formattedDate }}
        </div>
      </div>
    </div>
    <div class="session-ip">
      <div class="subhead-2 mb-1">
       IP Address
      </div>
      <div class="caption-1 session-ip-address ">
        {{ session.ip ? session.ip :'Not available' }}
      </div>
    </div>
    <div
      v-if="current"    
    />
    <e-button
    v-else
      :class="!hovered ? 'display-button' : ''"
      variant="tertiary"
      @click="revokeAccess"
    >
      Revoke access
    </e-button>
  </div>
</template>

<script>
import SessionIcon from './SessionIcon.vue'
export default {
  components: { SessionIcon },
  props: {
    session: {
      type: Object,
      default: () => ({})
    },
    current: {
      type: Boolean,
      default: false
    },
    hovered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedDate() {
      const date = new Date(this.session.created * 1000)
        const options = {
   weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
     timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
      return date.toLocaleDateString('en-US', options)
    }
  },
  methods: {
    revokeAccess() {
      this.$emit('revokeAccess', this.session)
    }
  }
}
</script>

<style lang="scss" scoped>
.session-item{
  display: flex;
  gap: 12px;
  align-items: center;
  flex-grow: 1;
}
.session-info,
.session-ip {
  flex-grow: 1; 
}
.session-info{
  width: 200px;
}
.session-info-desc{
  display: flex;
  align-items: center;
  gap:6px;
}
.session-ip-address{
  color: $grayscale500;

}
.display-button{
  opacity:0;
}
@media (max-width: 799.98px){
  .session-info{
  width: auto;
}
 } 
</style>

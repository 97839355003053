var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main d-flex flex-column justify-space-between"},[_c('div',{staticClass:"table-header ma-3 ma-sm-5"},[_c('div',{staticClass:"money-icon"},[_c('v-icon',{class:{
          'icon-money-bill success--text': _vm.componentName === 'packages',
          'icon-d-cube warning--text': _vm.componentName === 'features',
        },attrs:{"medium":""}}),_c('span',{class:{
          'subhead-2 ml-2 success--text': _vm.componentName === 'packages',
          'subhead-2 ml-2 warning--text': _vm.componentName === 'features',
        }},[_vm._v(" "+_vm._s(_vm.componentName === 'packages' ? ((_vm.item.price) + " EUR") : ("Used in " + (_vm.item.packagesCount) + " packages")))])],1),_c('v-spacer'),(_vm.componentName === 'packages')?_c('e-button',{attrs:{"icon":"icon-pen","variant":"ghost"},on:{"click":_vm.showPackageEditModal}}):_c('e-menu',{attrs:{"min-width":"240px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('e-button',_vm._g(_vm._b({attrs:{"medium":"","variant":"ghost","icon":"icon-dots-vertical"}},'e-button',attrs,false),on))]}}])},[_c('e-menu-list',[_c('e-menu-list-item',{attrs:{"prepend-icon":"icon-pen"},on:{"click":_vm.showFeatureEditModal}},[_vm._v(" Edit feature ")]),_c('e-divider',{attrs:{"size":"7"}}),_c('e-menu-list-item',{attrs:{"variant":"danger","prependIcon":"icon-trashcan"},on:{"click":_vm.showFeatureDeleteModal}},[_vm._v(" Delete feature ")])],1)],1)],1),_c('div',{staticClass:"px-3 px-sm-5 pb-3 pb-sm-5 d-flex flex-column justify-space-between flex-grow-1"},[_c('div',{staticClass:"headline-3 graybase--text"},[_vm._v(_vm._s(_vm.item.name))]),_c('div',{staticClass:"footer pt-2"},[_c('span',{staticClass:"caption-1 grayscale500--text"},[_vm._v("Updated at "+_vm._s(_vm.setDateFormat(_vm.item.updated_at)))]),_c('v-spacer'),(_vm.componentName === 'features')?_c('span',{staticClass:"caption-1 grayscale500--text"},[_vm._v(_vm._s(_vm.item.feature_key))]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
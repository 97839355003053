<template>
  <div>
    <e-dialog-header @click:close="close">
      Add premium service
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
          <div class="callout-3 graybase--text mb-2">Number of days</div>
          <div>
            <!--  HERE -->
            <div v-if="!switchValue" class="d-flex flex-wrap">
              <div class="d-flex mb-2" v-for="(date, idx) in dates" :key="date">
                <e-button variant="ghost" :class="{ 'v-btn--active': idx === toggle }" @click="toggle = idx">{{ date }}</e-button>
                <span v-if="idx < dates.length - 1" class="button-separator">
                      <span class="button-separator__inner"/>
                      </span>
              </div>
            </div>
          </div>
          <div class="pb-6"> <e-switch label="Custom range" v-model="switchValue" @click="closeDatePickers" /></div>
          <div v-if="switchValue">
            <div class="date-block">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                offset-x
                min-width="auto"
                v-model="showDatePicker"
              >
                <template v-slot:activator="{ on, attrs }">
                  <e-text-field
                    large
                    append-icon="icon-calendar"
                    label="From"
                    class="body-2"
                    v-bind="attrs"
                    v-on="on"
                    v-model="dateFormattedFrom"
                    @blur="startDate = parseDate(dateFormattedFrom)"
                    @keyup.enter="startDate = parseDate(dateFormattedFrom)"
                    placeholder="Start date"
                    @input="setDateFrom"
                    required
                    :rules="[validators.required]"
                    @click:append="on.click"
                  />
                </template>

                <e-date-picker v-model="startDate" :min="currentDate" @input="showDatePicker = false">
                  <v-spacer></v-spacer>
                </e-date-picker>
              </v-menu>
            </div>

            <div class="date-block">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                offset-x
                min-width="auto"
                v-model="showDatePicker2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <e-text-field
                    large
                    appendIcon="icon-calendar"
                    label="Until"
                    class="body-2"
                    v-bind="attrs"
                    v-on="on"
                    v-model="dateFormattedTo"
                    @blur="finishDate = parseDate(dateFormattedTo)"
                    @keyup.enter="finishDate = parseDate(dateFormattedTo)"
                    @input="setDateTo"
                    placeholder="End date"
                    required
                    :rules="[validators.required]"
                    @click:append="on.click"
                  />
                </template>
                <e-date-picker v-model="finishDate" :min="currentDate" @input="showDatePicker2 = false">
                  <v-spacer></v-spacer>
                </e-date-picker>
              </v-menu>
            </div>
          </div>
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="addPremium">Add premium</e-button>

    </e-dialog-actions>

    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import { required } from '@core/utils/validation'
import moment from 'moment/moment'

export default {
  name: 'AddPremiumModal',
  data() {
    return {
      valid: false,
      overlay: false,
      switchValue: false,
      showDatePicker: false,
      showDatePicker2: false,
      toggle: 0,
      startDate: '',
      finishDate: '',
      dateFormattedFrom: '',
      dateFormattedTo: '',
      dates: ['14 days', '1 month', '6 months', '1 year', '2 years'],
    }
  },
  props: {
  },
  watch: {
    startDate(val) {
      this.dateFormattedFrom = this.formatDate(this.startDate)
    },
    finishDate(val) {
      this.dateFormattedTo = this.formatDate(this.finishDate)
    },
    toggle: {
      handler(val) {
        const start = {
          0: moment().format('YYYY-MM-DD'),
          1: moment().format('YYYY-MM-DD'),
          2: moment().format('YYYY-MM-DD'),
          3: moment().format('YYYY-MM-DD'),
          4: moment().format('YYYY-MM-DD'),
        }

        this.startDate = start[val]
        const end = {
          0: moment().add(14, 'day').format('YYYY-MM-DD'),
          1: moment().add(1, 'month').format('YYYY-MM-DD'),
          2: moment().add(6, 'month').format('YYYY-MM-DD'),
          3: moment().add(1, 'year').format('YYYY-MM-DD'),
          4: moment().add(2, 'year').format('YYYY-MM-DD'),
        }
        this.finishDate = end[val]
      },
      immediate: true,
    },
  },
  mounted() {
  },
  computed: {
    computedDateFormattedFrom() {
      return this.formatDate(this.startDate)
    },
    computedDateFormattedTo() {
      return this.formatDate(this.finishDate)
    },
    currentDate() {
      return moment().format('YYYY-MM-DD')
    },

    validators() {
      return {
        required,
      }
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },

    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    async setDateFrom(value) {
      if (this.validateDate(value)) {
        this.startDate = this.parseDate(value)
      }
    },
    async setDateTo(value) {
      if (this.validateDate(value)) {
        this.finishDate = this.parseDate(value)
      }
    },
    validateDate(value) {
      return value.match(/^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/)
    },
    closeDatePickers() {
      this.showDatePicker = false
      this.showDatePicker2 = false
    },

    addPremium() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      let userId = this.$route.params.id
      this.$store
        .dispatch('addPremium', { userId, dateFrom: this.startDate, dateTo: this.finishDate })
        .then(() => {

          // get services
          let userId = this.$route.params.id
          this.$store.dispatch('getUserServices', userId)
          this.$root.$emit('submit')
          this.overlay = false
        })
        .catch((error) => {
          this.$eToast.error(error)
          console.error(error)
          this.overlay = false
        })
    },
  },
}
</script>

import * as api from '../../api'

export default {
  namespaced: true,
  state: {
    paymentMethods: [],
    paymentMethodsBusy: true,
    paymentMethodsFail: false,
    paymentAddedSuccess: false,
    paymentAddedFail: false,
    paymentUpdatedSuccess: false,
    paymentUpdatedFail: false,
    errorMessages: [],
  },
  mutations: {
    updatePaymentValueSuccess(state, { name, value }) {
      state[name] = value
      state[name + 'Busy'] = false
    },
    updatePaymentValueFail(state, { name }) {
      state[name + 'Fail'] = true
    },
    updatePaymentValue(state, { name, value }) {
      state[name] = value
    },
    getPaymentMethodSuccess(state, response) {
      state.paymentMethods = response.data.data
      state.paymentMethodsBusy = false
    },
    getPaymentMethodFail(state) {
      state.paymentMethodsFail = true
    },
    addPaymentMethodSuccess(state, response) {
      state.paymentAddedSuccess = true
      state.paymentMethods.push(response.data.data)
    },
    addPaymentMethodFail(state) {
      state.paymentAddedFail = true
    },
    updatePaymentMethodSuccess(state, response) {
      if (!response.data.success) {
        state.paymentUpdatedFail = true
        state.errorMessages = response.data.error
        return
      }
      state.paymentUpdatedSuccess = true
      let data = response.data.data

      state.paymentMethods = state.paymentMethods.map((item) => {
        if (item.id == data.id) {
          return data
        }
        return item
      })
    },
    updatePaymentMethodFail(state) {
      state.paymentUpdatedFail = true
    },
    deletePaymentMethodSuccess(state, id) {
      state.paymentDeletedSuccess = true
      state.paymentMethods = state.paymentMethods.filter((e) => e.id !== id)
    },
    deletePaymentMethodFail(state) {
      state.paymentDeletedFail = true
    },
  },
  actions: {
    getPaymentMethods: (store) => {
      return api.getPaymentMethods().then(
        (response) => {
          store.commit('getPaymentMethodSuccess', response)
          return Promise.resolve(response)
        },

        (error) => {
          store.commit('getPaymentMethodFail')
          return Promise.reject(error)
        }
      )
    },
    addPaymentMethod: (store, data) => {
      return api.addPaymentMethod(data).then(
        (response) => {
          store.commit('addPaymentMethodSuccess', response)
          return Promise.resolve(response)
        },

        (error) => {
          store.commit('addPaymentMethodFail')
          return Promise.reject(error)
        }
      )
    },
    updatePaymentMethod: (store, { id, name }) => {
      return api.updatePaymentMethod(id, name).then(
        (response) => {
          store.commit('updatePaymentMethodSuccess', response)
          return Promise.resolve(response)
        },

        (error) => {
          store.commit('updatePaymentMethodFail')
          return Promise.reject(error)
        }
      )
    },
    deletePaymentMethod: (store, { id }) => {
      return api.deletePaymentMethod(id).then(
        (response) => {
          store.commit('deletePaymentMethodSuccess', id)
          return Promise.resolve(response)
        },

        (error) => {
          store.commit('deletePaymentMethodFail')
          return Promise.reject(error)
        }
      )
    },
  },
}

<template>
  <page-container title="User logs">
    <template #actions>
      <e-button large variant="primary" @click="exportLogs"> Export logs</e-button>
    </template>
    <div>
      <div class="d-flex justify-space-between flex-wrap-reverse">
        <div class="d-flex flex-grow-1 mb-3">
          <div class="d-flex flex-grow-1">
            <e-text-field v-model="search" placeholder="Search..." prepend-inner-icon="icon-search" />
          </div>
          <div class="pl-3">
            <e-select v-model="searchBy" :items="filters" class="select" />
          </div>
          <div class="pl-3">
            <v-form ref="form" v-model="valid">
              <!-- TODO: refactoring-->
              <e-dialog
                max-width="424px"
                v-model="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <e-button v-bind="attrs" v-on="on" variant="tertiary">Select date</e-button>
                </template>
                <e-dialog-header @click:close="dialog = false">
                  Select range
                </e-dialog-header>
                <e-dialog-content>
                  <div class="pb-3 pb-sm-5 callout-3 graybase--text">Number of days</div>
                  <div>
                    <div class="date-block">
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        offset-x
                        min-width="auto"
                        v-model="showDatePicker"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div class="py-3" v-bind="attrs" v-on="on">
                            <e-text-field
                              :rules="[validators.required]"
                              large
                              appendIcon="icon-calendar"
                              label="From"
                              class="body-2"
                              v-model="dateFormattedFrom"
                              @blur="startDate = parseDate(dateFormattedFrom)"
                              @keyup.enter="startDate = parseDate(dateFormattedFrom)"
                              @input="setDateFrom"
                              placeholder="Start date"
                              required
                            />
                          </div>
                        </template>
                        <e-date-picker v-model="startDate" @input="showDatePicker = false">
                          <v-spacer></v-spacer>
                        </e-date-picker>
                      </v-menu>
                    </div>

                    <div class="date-block">
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        offset-x
                        min-width="auto"
                        v-model="showDatePicker2"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div class="pt-4 pb-2" v-bind="attrs" v-on="on">
                            <e-text-field
                              large
                              :rules="[validators.required]"
                              appendIcon="icon-calendar"
                              label="Until"
                              class="body-2 py-3"
                              v-model="dateFormattedTo"
                              @blur="finishDate = parseDate(dateFormattedTo)"
                              @keyup.enter="finishDate = parseDate(dateFormattedTo)"
                              @input="setDateTo"
                              placeholder="End date"
                              required
                            />
                          </div>
                        </template>
                        <e-date-picker v-model="finishDate" @input="showDatePicker2 = false">
                          <v-spacer></v-spacer>
                        </e-date-picker>
                      </v-menu>
                    </div>
                    <div><e-button variant="tertiary" @click="clearRange">Clear range</e-button></div>
                  </div>
                </e-dialog-content>
                <e-dialog-actions>
                  <e-button variant="tertiary" @click="dialog = false">Cancel</e-button>
                  <e-button @click="handleSelection">Set range</e-button>
                </e-dialog-actions>
              </e-dialog>
            </v-form>
          </div>
        </div>
      </div>
      <no-data-block
        v-if="search === ''"
        icon="icon-search"
        title="Please use search to find user logs"
        description="To find user logs - please use search bar and type user data to find it."
      />
      <e-data-table
        v-else
        class="graybase--text"
        item-key="id"
        :headers="headers"
        :items="isLoading ? [] : logs"
        :hide-default-footer="false"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page="options.perPage"
        @click:row="handleClick"
      >
        <template v-slot:no-data>
          <no-data-block
            icon="icon-search"
            title="No results!"
            description="Nothing matched your search, please try a different combination."
          />
        </template>
       <template v-slot:item.is_error="{ item }" >
         <i v-if="!item.is_error" class="icon-success success300--text" style="font-size: 24px;"/>
          <!-- <i  class="icon-danger danger300--text" style="font-size: 24px;"/> -->
      <e-tooltip bottom v-else>
      <template v-slot:activator="{ on }">
        <i
          class="icon-danger danger300--text"
          style="font-size: 24px;"
          v-on="on"
        />
      </template>
      <span>{{ item.error_message }}</span> 
    </e-tooltip>
  </template>
      </e-data-table>
    </div>
  </page-container>
</template>

<script>
import PageContainer from '@/components/PageContainer'
import { getEnv } from '@env'
import _ from 'lodash'
import moment from 'moment-timezone'
import { required } from '@core/utils/validation'
import NoDataBlock from '@/components/NoDataBlock'

export default {
  components: { NoDataBlock, PageContainer },
  data() {
    return {
      valid: false,
      toggle: 0,
      dialog: false,
      dateFormattedFrom: moment().subtract(30, 'days').format('DD.MM.YYYY'),
      dateFormattedTo: moment().format('DD.MM.YYYY'),
      startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      finishDate: moment().format('YYYY-MM-DD'),
      showDatePicker: false,
      showDatePicker2: false,
      loading: false,
      headers: [
        { text: 'User ID', value: 'id' },
        { text: 'User name', value: 'user_name' },
        { text: 'Log date', value: 'log_date_modified' },
        { text: 'Login method', value: 'login_method' },
        { text: 'IP', value: 'ip' },
        { text: 'Web', value: 'type' },
        { text: 'Login status', value: 'is_error' },
      ],
      filters: [
        { text: 'ID', value: 'person.id' },
        { text: 'Username', value: 'person.user_name' },
        { text: 'IP', value: 'log_person.ip' },
      ],
      debouncedSearch: _.debounce(() => {
        this.getLogs()
      }, 500),
      logs: [],
    }
  },
  watch: {
    dialog() {
      this.$refs.form.resetValidation()
    },
    startDate(val) {
      this.dateFormattedFrom = this.formatDate(this.startDate)
    },
    finishDate(val) {
      this.dateFormattedTo = this.formatDate(this.finishDate)
    },
    options: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && typeof oldValue.itemsPerPage === 'number') {
          this.getLogs()
        }
      },
      deep: true,
    },
    searchBy: {
      handler() {
        this.options.page = 1
        // this.getLogs()
      },
    },
    search: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.options.page = 1
          this.loading = true
          this.debouncedSearch()
        }
      },
    },
  },
  computed: {
    computedDateFormattedFrom() {
      return this.formatDate(this.startDate)
    },
    computedDateFormattedTo() {
      return this.formatDate(this.finishDate)
    },
    urlPath() {
      return getEnv('VUE_APP_FRONTEND_API_URL')
    },
    validators() {
      return {
        required,
      }
    },
    total() {
      return this.$store.getters.userLogTable.recordsFiltered
    },
    // logs() {
    //   return this.$store.getters.userLogTable.data
    // },
    logsBusy() {
      return this.$store.state.userLogs.busy
    },
    isLoading() {
      return this.loading || this.logsBusy
    },
    search: {
      get() {
        return this.$store.getters.userLogsSearch
      },
      set(value) {
        if (value.length > 2) {
          this.loading = true
          if (this.searchBy === 'log_person.ip' && isNaN(value)) {
            this.$eToast.clear()
            this.$eToast.error('IP must be a number.')
            this.$store.commit('updateLogsSearch', value)
          } else this.$store.commit('updateLogsSearch', value)
        }
      },
    },
    searchBy: {
      get() {
        return this.$store.getters.userLogsSearchBy
      },
      set(value) {
        // if (value === 'log_person.ip' && isNaN(this.search)) {
        //   this.$eToast.clear()
        //   this.$eToast.error('IP must be a number.')
        // } else
        this.$store.commit('updateLogsSearchBy', value)
        // this.getLogs()
      },
    },
    options: {
      get() {
        return this.$store.getters.userLogsTableOptions
      },
      set(value) {
        this.$store.commit('updateLogsTableOptions', value)
      },
    },
  },
  methods: {
    async setDateFrom(value) {
      if (this.validateDate(value)) {
        this.startDate = this.parseDate(value)
      }
    },
    async setDateTo(value) {
      if (this.validateDate(value)) {
        this.finishDate = this.parseDate(value)
      }
    },
    validateDate(value) {
      return value.match(/^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/)
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    clearRange() {
      this.startDate = ''
      this.finishDate = ''
    },
    handleSelection() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.dialog = false
      this.getLogs()
    },
    handleClick(log) {
      // TODO: dynamic content for details page
      return
    },
    // checkIpCode(value){
    //      const regExp = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
    //       const validCode = regExp.test(value)
    //     return validCode
    // },
    getLogs() {
      const { page, perPage } = this.options
      // let perPage = 50
      let search = this.search
      let searchBy = this.searchBy

      let dateFrom = moment(this.startDate).format('YYYY-MM-DD')
      let dateTo = moment(this.finishDate).format('YYYY-MM-DD')

      this.$store.dispatch('getUserLogs', { page, perPage, search, dateFrom, dateTo, searchBy }).then(
        () => {
          let data = this.$store.getters.userLogTable
          //modify time
          data.data.forEach((log) => {
            log.log_date_modified = moment.tz(log.log_date, "DD.MM.YYYY H:mm", "UTC").tz('Europe/Berlin').format('DD.MM.YYYY HH:mm') //random stuff, but works
          })
          this.logs = data.data
          localStorage.setItem('logs', JSON.stringify(this.logs))
        },
        (err) => {
          this.$eToast.clear()
          this.$eToast.error(err)
        }
      )
      this.loading = false
    },
    async exportLogs() {
      const { page, perPage } = this.options
      let search = this.search
      let searchBy = this.searchBy
      let dateFrom = moment(this.startDate).format('YYYY-MM-DD')
      let dateTo = moment(this.finishDate).format('YYYY-MM-DD')

      const resp = await this.$http.get(
        `${this.urlPath}/users-logs/export?page=${page}&perPage=${perPage}&search=${search}&start_date=${dateFrom}&end_date=${dateTo}&searchBy=${searchBy}`,
        { responseType: 'blob' }
      )
      const url = window.URL.createObjectURL(new Blob([resp.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'logs.csv')
      document.body.appendChild(link)
      link.click()
    },
  },
  async created() {
    if (this.search !== ' ') {
      this.logs = JSON.parse(localStorage.getItem('logs') || '[]')
    }
  },
}
</script>

<style scoped>
.select {
  max-width: 164px;
}
</style>

import * as api from '../../api'
import * as types from '../mutation-types'

export default {
  state: {
    messagingBusy: false,
    messagingData: [],
    dates: [],
  },
  mutations: {
    [types.GET_MESSAGING_CHART_DATA](state) {
      state.messagingBusy = true
    },
    [types.GET_MESSAGING_CHART_DATA_SUCCESS](state, response) {
      state.messagingBusy = false
      let data = response.data.data
      state.dates = []
      let transformedData = []

      data.forEach(date => {
        state.dates.push(date.name)
        date.types.forEach(typ => {
          if (transformedData.find(item => item?.type === typ.name)) {
            transformedData = transformedData.map(item => {
              if (item.type === typ.name) {
                return {
                  ...item,
                  [date.name]: typ.count
                }
              }
              return item
            })
          } else {
            transformedData.push(
              {
                type: typ.name,
                [date.name]: typ.count,
              })
          }
        })
      })

      state.messagingData = transformedData.map(item => {
        let totalInPeriod = 0
        Object.values(item).forEach(it => {

          totalInPeriod += it >= 0 ? it : 0
        })
        return {
          ...item,
          totalInPeriod,
        }
      })
    },
    [types.GET_MESSAGING_CHART_DATA_FAIL](state) {
      state.messagingBusy = false
    },
  },
  actions: {
    getMessagingChartData: (store, { dateFrom, dateTo }) => {
      store.commit(types.GET_MESSAGING_CHART_DATA)
      return api.getMessagingChartData(dateFrom, dateTo).then(
        (response) => {
          store.commit(types.GET_MESSAGING_CHART_DATA_SUCCESS, response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit(types.GET_MESSAGING_CHART_DATA_FAIL, error)
          return Promise.reject(error)
        }
      )
    },
  },
  created() {},
  getters: {
    messagingData: (state) => state.messagingData,
    messagingDates: (state) => state.dates,
    messagingBusy: (state) => state.messagingBusy,
  },
}

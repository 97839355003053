<template>
  <div class="studycard">
    <div class="status">
      <div>
        <e-icon class="graybase--text">icon-rotating-arrows</e-icon>
        <span class="ml-2 graybase--text subhead-2">{{ status }} </span>
      </div>
      <e-button icon="icon-pen" variant="ghost" @click="openEditModal" />
    </div>
    <div class="details">
      <span class="graybase--text headline-3">{{ year }}</span>
      <div class="date-link">
        <span class="grayscale500--text caption-1">Updated at {{ setDateFormat(date) }}</span>
        <e-button variant="link" small @click="showDetails">View details</e-button>
      </div>
    </div>
  </div>
</template>

<script>
import EditStudyYear from '@/views/data/schools/modals/EditStudyYear.vue'
import moment from 'moment'
import StudyYearDetails from '@/views/data/schools/modals/StudyYearDetails.vue'
export default {
  props: [
    'status',
    'year',
    'date',
    'statusID',
    'id',
    'created',
    'creator',
    'modifier',
    'startDate',
    'endDate',
    'institutionId',
  ],

  components: {
    EditStudyYear,
  },
  methods: {
    openEditModal() {
      this.$eDialog({
        component: EditStudyYear,
        componentProps: {
          statusCode: this.statusID,
          statusName: this.status,
          id: this.id,
        }
      })
    },
    showDetails() {
      this.$eDialog({
        width: 424,
        component: StudyYearDetails,
        componentProps: {
          year: this.year,
          created: this.created,
          creator: this.creator,
          date: this.date,
          modifier: this.modifier,
          startDate: this.startDate,
          endDate: this.endDate,
          institutionId: this.institutionId,
          status: this.status
        }
      })

    },
    setDateFormat(value) {
      if (value != null) {
        return moment(value).format('ddd, DD MMM  HH:ss')
      }
    },
  },
}
</script>

<style scoped>
.date-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.studycard {
  height: 146px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.main-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.detail {
  display: flex;
}
.detail-description {
  width: 154px !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #161718;
}
.detail-content {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6b7280;
  flex-shrink: 999;
  overflow: hidden;
}
</style>

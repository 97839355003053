import Users from '@/views/data/users/Users.vue'
import UserDetails from '@/views/data/users/user-details/UserDetails.vue'
import Schools from '@/views/data/schools/main-page/Schools.vue'
import SchoolDetailed from '@/views/data/schools/school-details/SchoolDetailed.vue'
import Governments from '@/views/data/governments/Governments.vue'

const data = [
  {
    path: '/data/users',
    name: 'data-users',
    component: Users ,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data/users/:id/detailed',
    name: 'data-users-detailed',
    component: UserDetails,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data/schools',
    name: 'data-schools',
    component: Schools ,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data/schools/:id/detailed',
    name: 'data-schools-detailed',
    component: SchoolDetailed,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/data/governments',
    name: 'data-governments',
    component: Governments ,
    meta: {
      layout: 'content',
    },
  },
]

export default data

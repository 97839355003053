<template>
<div>
    <e-dialog-content>  
      <div class="revoke-all-modal">
        <div class="headline-3">
          End sessions
        </div>
        <div class="revoke-all-modal-desc">
          This account will be logged out from {{sessions.length}} sessions. Are you sure you want to continue?
        </div>
      </div>
    </e-dialog-content>
    <e-dialog-actions
      :flexible-button="false"
      class="pb-8 actions"
    >
      <e-button
        variant="tertiary"
        @click="close"
      >
      Cancel
      </e-button>
      <e-button
        variant="danger"
        @click="revokeAllSessions"
      >
        Yes, end sessions
      </e-button>
    </e-dialog-actions>
</div>
</template>

<script setup>
import { api } from '@/api'
import SessionIcon from './SessionIcon.vue'
export default {
  components: { SessionIcon },
  props: {
    sessions: {
      type: Array,
      default: () => ([])
    }
  },
    async created() {
    this.user = { ...this.$store.getters.userInfo }
  },
  methods: {
    close() {
     this.$root.$emit('submit')
    },
  revokeAllSessions() {
    this.$store.dispatch('revokeAllSessions', {user_id: this.user.id})
    this.$store.dispatch('getSessionList', {user_id: this.user.id})
    this.$root.$emit('submit')
},
  }
}
</script>

<style lang="scss" scoped>
.actions{
 padding: 0 32px 32px !important;
}
.v-application .justify-end {
  justify-content: center !important;
}
.revoke-all-modal{
display: flex;
flex-direction: column;
align-items: center;
padding:16px;
}
.revoke-all-modal-desc{
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
color: $grayscale600;
}
  </style>

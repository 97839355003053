import Vue from "vue"

export const chartColors = ['#0274EF', '#4CA668','#E06A00', '#E43F39', '#9294C2', '#F77E0B', '#599E19', '#038DF2', '#EBBB12', '#AD0C01', '#FD4165', '#7C8FA0', '#A17F96', '#76A1C1', '#94BAC3', '#A8D5C0', '#ECE8CE', '#A19C98', '#FEA9A7', '#FAD1AD', '#9DDCB0', '#A3CFFE', '#A3CFFE']
export function trackGA4clickEvent(eventName) {
  dataLayer.push({
    event: `GA:${eventName}`,
  })
}

export function logout() {
  localStorage.removeItem('accessTokenEkool')
  const vm = Vue.prototype
  vm.$auth.logout({
    returnTo: window.location.origin,
  })
}
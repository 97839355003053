<template>
  <StatisticsCard title="Formative assessment statistics" icon="icon-file-folders">
    <StatisticsCardField
      v-for="statistics in schoolFormativeAssessmentStatistics"
      :key="statistics.name"
      :description="statistics.name"
      :warning="!statistics.signed_cards"
      :value="getStatisticsString(statistics.signed_cards)" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
import StatisticsCard from '@/components/StatisticsCard.vue'
export default {
  components: {StatisticsCard, StatisticsCardField},
  computed: {
    ...mapState('school', ['schoolFormativeAssessmentStatistics']),
  },
  methods: {
    getStatisticsString(stat) {
      if (!stat) {
        return 'Cards not signed'
      } else {
        return `${stat} cards signed`
      }
    },
  },
}
</script>

import { get } from 'lodash'
import * as api from '../../api'

export default {
  state: {
    sessions: [],
    sessionsBusy: false,
    sessionsFailed: null

  },
  mutations: {
    getSessions(state) {
      state.sessionsBusy = true
    },
    SET_SESSIONS(state, response) {
      state.sessions = response.data.data
      state.sessionsBusy = false
    },
    getSessionsFailed(state,error){
      state.sessionsBusy = false
      state.sessionsFailed=error

    }
  },
  actions: {
  getSessionList(store, {user_id}) {
    store.commit('getSessions')
    return api.getSessionList(user_id).then(
      (response) => {
        store.commit('SET_SESSIONS', response)
        return Promise.resolve(response)
      },
      (error) => {
        store.commit('getSessionsFailed',error)
        return Promise.reject(error)
      }
    )
    },
    revokeSession(store, {user_id, session_id,session_type}) {
      return api.revokeSession(user_id, session_id,session_type).then(
        (response) => {
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      },
      revokeAllSessions(store, {user_id}) {
        return api.revokeAllSessions(user_id).then(
          (response) => {
            return Promise.resolve(response)
          },
          (error) => {
            return Promise.reject(error)
          }
        )
        },
  },
  getters: {
    sessionList: (state) => state.sessions,
    sessionsBusy: (state) => state.sessionsBusy,
    sessionsFailed: (state) => state.sessionsFailed
  },
}
<template>
  <div>
    <e-dialog-header @click:close="close">
      Export data
    </e-dialog-header>
    <e-dialog-content>
      <label class="callout-3 graybase--text">Select data to export</label>
      <v-container fluid>
        <div class="pb-4 pt-3"><e-checkbox label="User contacts" value="students-contacts " v-model="selected" /></div>
        <div class="pb-4"><e-checkbox label="Parents contacts" value="parents-contacts" v-model="selected" /></div>
        <div class="pb-4"
        ><e-checkbox label="Administration contacts" value="administrations-contacts" v-model="selected"
        /></div>
      </v-container>
      <e-hint title="All exports are in .csv format" dismissible description="" />
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="exportData">Export</e-button>
    </e-dialog-actions>
  </div>
</template>

<script>
import { getEnv } from '@env'
export default {
  data() {
    return {
      selected: [],
    }
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    exportData() {
      this.selected.forEach(async (path) => {
        const resp = await this.$http.get(`${this.urlPath}/schools/${this.schoolId}/${path}`, {
          responseType: 'blob',
        })
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${path}.csv`)
        document.body.appendChild(link)
        link.click()
      })
      this.close()
    },
  },
  computed: {
    schoolId() {
      return this.$route.params.id
    },
    urlPath() {
      return getEnv('VUE_APP_FRONTEND_API_URL')
    },
  },
}
</script>

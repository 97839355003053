<template>
  <div>
    <e-dialog-header @click:close="close">
      Add payment
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid"
      >
        <e-text-field
          :error-messages="schoolPaymentAddedFail ? errorMessages.payer : ''"
          required
          :rules="[validators.required]"
          class="pb-8 pt-5"
          :value="null"
          label="Payer"
          placeholder="e.g. Julian"
          v-model="payer"
        />
        <e-select
          class="pb-8"
          :value="null"
          label="Payment method"
          :placeholder="paymentMethods.length ? paymentMethods[0].name : ''"
          v-model="paymentMethod"
          :items="paymentMethods"
          item-text="name"
          item-value="id"
          return-object
        />

        <e-text-field
          :error-messages="schoolPaymentAddedFail == true ? errorMessages.fee : ''"
          required
          :rules="[validators.required]"
          class="pb-3"
          :value="null"
          label="Price"
          placeholder="e.g 30"
          prependInnerIcon="icon-money-bill"
          v-model="paymentFee"
        />
        <e-hint title="Price is expressed in Euros (EUR)" dismissible class="pb-3" />

        <e-text-field
          :error-messages="schoolPaymentAddedFail == true ? errorMessages.customer_id : ''"
          required
          :rules="[validators.required, validators.onlyDigits]"
          class="pb-8 pt-8"
          :value="null"
          label="Customer ID"
          placeholder="e.g. 123456789"
          v-model="customerID"
        />

        <loading-overlay :value="overlay" />
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="savePayment">Add new payment</e-button>
    </e-dialog-actions>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from '@core/utils/validation'
export default {
  data() {
    return {
      valid: false,
      overlay: false,
      addPayment: false,

      paymentMethod: null,
      payer: null,
      paymentFee: null,
      customerID: null,
      paymentId: null,
    }
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },

    async savePayment() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      let id = parseInt(this.$route.params.id)
      let data = {
        payer: this.payer,
        fee: this.paymentFee,
        customer_id: this.customerID,
        payment_method_id: this.paymentMethod.id,
        institution_id: id,
      }
      try {
        await this.$store.dispatch('school/saveNewPayment', { data, id: this.paymentId })
        this.$eToast.success('Payment method was successfully added')
        this.close()
      } catch (e) {
        this.$eToast.error(e)
      } finally {
        this.overlay = false
      }
    },
  },
  computed: {
    ...mapState('payments', ['paymentMethods']),
    ...mapState('school', [
      'schoolPayments',
      'schoolPaymentsBusy',
      'errorMessages',
      'schoolPaymentAddedFail',
      'schoolPaymentAddedSuccess',
    ]),
    validators() {
      return {
        required,
        onlyDigits: (value) => {
          return !isNaN(value) || 'Customer ID must be a number'
        },
      }
    },
  },
  created() {
    this.$store.dispatch('payments/getPaymentMethods')
  },
}
</script>

import axios from '@/plugins/axios'

// GET REQUESTS
export const getLocalGovernments = () => {
  return axios.get('local-governments')
}



// POST REQUESTS
export const addGovernment = (data) => {
  return axios.post('local-governments', data)
}
export const updateGovernment = (id, payload) => {
  return axios.post('local-governments/' + id, payload)
}



// DELETE REQUESTS
export const deleteGovernment = (id) => {
  return axios.delete('local-governments/' + id)
}

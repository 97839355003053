<template>
  <div>
    <page-container flat>
      <div>
        <div class="headline-3 graybase--text pa-3 pa-sm-5">Sessions 
          <div class="title_desc mt-1">Devices logged into this user’s account
            </div>
            </div>
            <div v-if="sessionsBusy">
              <e-circular-loading class="py-10" :value="sessionsBusy" />
            </div>
            <div
        v-else-if="allSessions.length ==0 && !sessionsBusy"
        class="app-card"
      >
        <div class="session-list">
          <div class="session-list-title_no_sessions">
            <div class="no_active_sessions">
           {{sessionsFailed ? 'Unable to fetch session list.':'The user has no active sessions on any device.'}} 
            </div>
          </div>
        </div>
      </div>
   <div class="app-card" v-else>
  <div class="session-list-wrapper">
    <div class="session-list">
      <div class="session-list-title">
        <div class="subhead-2">
          {{ allSessions.length }} {{ allSessions.length > 1 ? 'sessions' : 'session' }}
        </div>
        <e-button
          v-if="allSessions.length > 1"
          variant="tertiary"
          @click="revokeAllSessionsModalShow"
        >
          Revoke all
        </e-button>
      </div>
      <div class="section-divider" />
      <div
        v-for="(session, index) in limitedSessions"
        :key="session.id"
        class="session-list-title"
        :style="{ borderBottom: index === limitedSessions.length - 1 ? 'none' : ($vuetify.theme.dark ? '1px solid rgba(55, 65, 81, 1)' : '1px solid rgba(243, 244, 246, 1)') }"
        :class="hoveredSession === session.id ? 'session-hovered' : ''"
        @mouseover="setHovered(session.id, true)"
        @mouseleave="setHovered(session.id, false)"
      >
        <session-item
          :session="session"
          :hovered="hoveredSession === session.id"
          @revokeAccess="revokeAccess"
        />
      </div>
    </div>
    <div v-if="allSessions.length > sessionLimit" class="gradient-overlay" ></div>
    <e-chip
      v-if="allSessions.length > sessionLimit"
      class="view-more-chip"
      large
      variant="actionable"
      @click="toggleViewMore"
    >
      {{ showAllSessions ? 'View Less' : 'View More' }}
    </e-chip>
  </div>
</div>
      
      </div> </page-container
    >
    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import PageContainer from '@/components/PageContainer'
import SessionItem from './SessionItem.vue'
import RevokeSessionModal from './RevokeSessionModal.vue'
import RevokeAllSessionsModal from './RevokeAllSessionsModal.vue'
export default {
  components: { PageContainer,SessionItem,RevokeSessionModal,RevokeAllSessionsModal },
  data() {
    return {
      value: null,
      user: {},
      overlay: false,
      hoveredSession: null,
      revokeAll: false,
      revokeSession: false,
      revokingSession: {},
       showAllSessions: false, 
    sessionLimit: 10, 
    }
  },
  computed: {
    sessionsFailed() {
      return this.$store.getters.sessionsFailed
    },
    sessionsBusy(){
      return this.$store.getters.sessionsBusy
    },
      allSessions() {
      return this.$store.getters.sessionList
    },
    limitedSessions() {
    return this.showAllSessions ? this.allSessions : this.allSessions.slice(0, this.sessionLimit);
  },
  },
  methods: {
    setHovered(sessionId, isHovered){
  this.hoveredSession = isHovered ? sessionId : null
},
revokeAccess(session) {
  this.$eDialog({
        width: 484,
        component: RevokeSessionModal,
        componentProps: {
          session: session,
        }
      })
},
revokeAllSessionsModalShow() {
  this.$eDialog({
    width: 484,
    component: RevokeAllSessionsModal,
    componentProps: {
      sessions: this.allSessions,
    },
  })
},
toggleViewMore() {
    this.showAllSessions = !this.showAllSessions;
  }

  },
  async created() {
    this.user = { ...this.$store.getters.userInfo }
    this.$store.dispatch('getSessionList', {user_id: this.user.id})
  },
}
</script>

<style lang="scss" scoped>
@import "~@k12tech/ekool-vue-framework/src/scss/styles";
.session-list-wrapper {
  position: relative;
}

.gradient-overlay {
  position: absolute;
  bottom:0;
  left: 0;
  width: 100%;
  height: 200px; 
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
  z-index: 1;
}
.view-more-chip {
  display: flex;
  justify-content: center;
  position: absolute;
   bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: $whitebase;
  z-index: 2;
}
.no_active_sessions{
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 12px;
text-align: center;
color: $grayscale500;
}
.session-list-title .no_active_sessions{
  justify-content: center !important;
}
.session-list-title_no_sessions{
  display: flex;
  justify-content: center;
  padding:36px 28px 36px 28px;
  align-items: center;
}
.select-style {
  max-width: 343px;
}
.title_desc{
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 12px;
text-align: left;
color:$grayscale500;
}
.app-card {
  border-radius: 8px;
  border: 1px solid $grayscale200;
  background: $whitebase;
  margin:0px 20px 20px 20px;
   position: relative;

}

.session-list {
  display: flex;
  flex-direction: column;
   position: relative;
}

.session-list-title{
  display: flex;
  justify-content: space-between;
  padding:36px 28px 36px 28px;
  align-items: center;
}
.section-divider{
  border: 1px solid $grayscale100;
}
.session-hovered{
  background: $grayscale50;
}
</style>

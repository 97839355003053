<template>
  <div>
    <div v-if="featureList.length > 1" class="row">
      <div v-for="feature in featureList" :key="feature.id" class="col-12 col-sm-6 col-md-4">
        <list-item :item="feature" :componentName="componentName" mdName="editFeature"></list-item>
      </div>
    </div>
    <e-circular-loading class="py-10" :value="featureListBusy" />
    <no-data-block
      v-if="featureList.length === 0 && !featureListBusy"
      icon="icon-d-cube"
      title="No features created!"
      desctiption="Click create new feature to create your first feature."
    />
  </div>
</template>

<script>
import ListItem from './ListItem.vue'
import { mapState } from 'vuex'
import NoDataBlock from '@/components/NoDataBlock'
export default {
  components: {NoDataBlock, ListItem },
  computed:{
    ...mapState('schoolPackages', [
		  'featureList',
		  'featureListBusy',
		]),},
  data() {
    return {
      componentName: 'features',
    }
  },
  //   async created() {
  //     this.$store.dispatch('schoolPackages/getFeatureList')
  // }
}
</script>

<style scoped>
</style>

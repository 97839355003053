<template>
  <page-container> <h3>Horizon is opened in new tab</h3> </page-container>
</template>

<script>
import PageContainer from '@/components/PageContainer'
import { getEnv } from '@env'
export default {
  components: {
    PageContainer,
  },
  async created() {
    this.$store.dispatch('getHorizonToken').then(() => {
      this.$cookies.set('token', this.$store.state.token.token, 0, '/', 'http://ekool.eu/', true, 'Lax'),
        window.open(getEnv('VUE_APP_FRONTEND_API_URL') + '/horizon')
    })
  },
}
</script>

<template>
  <StatisticsCard title="Development card statistics" icon="icon-file-folders">
    <StatisticsCardField description="Card count" :value="schoolDevelopmentCardStatistics.card_count" />
    <StatisticsCardField description="Card section count" :value="schoolDevelopmentCardStatistics.card_section_count" />
    <StatisticsCardField description="Entry count" :value="schoolDevelopmentCardStatistics.entry_count" />
    <StatisticsCardField description="Template count" :value="schoolDevelopmentCardStatistics.template_count" />
    <StatisticsCardField description="Eliis card count" :value="schoolDevelopmentCardStatistics.eliis_card_count" />
    <StatisticsCardField description="Attachment count" :value="schoolDevelopmentCardStatistics.attachment_count" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  name: 'DevelopmentCard',
  components: {StatisticsCardField, StatisticsCard},
  computed: {
    ...mapState('school', ['schoolDevelopmentCardStatistics', 'schoolConsentManagement']),
  },
}
</script>


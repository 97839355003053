<template>
  <div class="h-full">
    <div class="h-full d-flex flex-column justify-center align-center">
      <e-hint variant="warning" class="mb-3 mb-sm-5" title="Page Not Found" description="We couldn't find the page you are looking for"></e-hint>
      <e-button to="/" class="mb-4"> Back to home </e-button>
    </div>
  </div>
</template>

<script>

export default {
  setup() {
    return {
    }
  },
}
</script>

<template>
  <div class="statistics-card">
    <div class="statistics-card-title">
      <e-icon class="graybase--text">{{ icon }}</e-icon>
      <span class="graybase--text subhead-2">{{ title }} </span>
    </div>
    <e-divider class="grayscale100" />
    <div class="statistics-details">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
  }
}
</script>

<style>
.statistics-card {
  height: 100%;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.statistics-card-title {
  height: 64px;
  padding: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
}
.statistics-details {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 12px;
  padding: 20px;
}
.statistics-detail {
  display: flex;
  justify-content: space-between;
}
.statistics-detail-description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6b7280;
}
.statistics-detail-value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #161718;
}
.statistics-detail-value-warning {
  color: #ff7900;
}
</style>

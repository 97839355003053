export default [
  {
    subheader: 'premium',
  },
  {
    title: 'local_governments',
    icon: 'icon-institution',
    to: 'data-governments',
  },
  {
    title: 'packages_features',
    icon: 'icon-d-cube',
    to: 'premium-packages',
  },
  {
    title: 'payment_methods',
    icon: 'icon-money-bill',
    to: 'premium-payment-methods',
  },
]

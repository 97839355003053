<template>
  <StatisticsCard title="Discipline statistics" icon="icon-file-folders">
    <StatisticsCardField description="School docs" :value="schoolDisciplineStatistics.school_docs" />
    <StatisticsCardField description="School notes" :value="schoolDisciplineStatistics.school_notes" />
    <StatisticsCardField description="Student count" :value="schoolDisciplineStatistics.student_count" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  components: {StatisticsCardField, StatisticsCard},
  computed: {
    ...mapState('school', ['schoolDisciplineStatistics']),
  },
}
</script>

// axios
import axios from 'axios'
import Vue from 'vue'
import { getEnv } from '@/env'
import { logout } from '../utils.js'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: getEnv('VUE_APP_FRONTEND_API_URL'),
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

if (process.env.APP_DEBUG === true) {
  axiosIns.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
}
axiosIns.defaults.withCredentials = true
axiosIns.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessTokenEkool')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  (error) => Promise.reject(error)
)
axiosIns.interceptors.response.use(function (response) {
  // return blob tables
  if (response.config.responseType === "blob") {
    return response;
  }
  // reject response if custom validation is failed
  if (!response.data?.success && typeof response.data?.success === 'boolean') {
    const message = Array.isArray(response.data.error) ? response.data.error.join(' ') : response.data.error
    return Promise.reject(message)
  }
  return response;
}, function (error) {
  if(error.response.message == 'Unauthorized' || error.response.data.message == 'Unauthorized' || error.message == 'Unauthorized') {
    logout()
  }  
  return Promise.reject(error.response ? (error.response.message || error.response.data.message) : error.message);
});

Vue.prototype.$http = axiosIns

export default axiosIns

<template>
<div>
    <e-dialog-content>
      <div class="d-flex flex-column">  
        <div class="end-session-title">
          <session-icon :session="session" />
          <div class="subhead-2">
            End session?
          </div>
        </div>
        <div class="current-session-info">
          <div class="current-session-info-item">
            <div class="subhead-2 current-info">
              Device
            </div>
            <div class="body-3">
                   {{ session.client_name || session.browser }} {{ session.platform !== 'Unknown' && session.platform !== 'unknown'? "on":" " }} {{ session.platform !== 'Unknown' && session.platform !== 'unknown' ? session.platform : "" }}
            </div>
          </div>
          <div class="section-divider mt-5 mb-5" />
          <div class="current-session-info-item">
            <div class="subhead-2 current-info">
             IP Address
            </div>
            <div class="body-3">
             {{ session.ip ? session.ip :'Not available' }}
            </div>
          </div>
             <div class="section-divider mt-5 mb-5" />
          <div class="current-session-info-item">
            <div class="subhead-2 current-info">
              Last location
            </div>
            <div class="body-3">
              {{ (session.country && session.country!=='Unknown') ? session.country : 'Not available' }}
            </div>
          </div>
          <div class="section-divider mt-5 mb-5" />
          <div class="current-session-info-item">
            <div class="subhead-2 current-info ">
              Original Sign In
            </div>  
            <div class="body-3">
              {{ formattedDate }}
            </div>
          </div>
          <div class="section-divider mt-5 mb-4" />
        </div>
      </div>
    </e-dialog-content>
    <e-dialog-actions
      :flexible-button="false"
      class="pb-8 actions"
    >
      <e-button
        variant="tertiary"
        @click="close"
      >
        Cancel
      </e-button>
      <e-button
        variant="danger"
        @click="revokeSession(session)"
      >
        Yes, end session
      </e-button>
    </e-dialog-actions>
</div>
</template>

<script >
import { api } from '@/api'
import SessionIcon from './SessionIcon.vue'
 export default {
  components: { SessionIcon },
  props: {
    session: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    formattedDate() {
      const date = new Date(this.session.created * 1000);
       const options = { 
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
      return date.toLocaleDateString('en-US', options)    
    }
  },
     async created() {
    this.user = { ...this.$store.getters.userInfo }
  },
   methods: {
    close() {
     this.$root.$emit('submit')
    },  
    revokeSession (session){
     this.$store.dispatch('revokeSession', {user_id: this.user.id, session_id: session.id,session_type: session.session_type})
     this.$store.dispatch('getSessionList', {user_id: this.user.id})
     this.$root.$emit('submit')

}
  }
 }
</script>

<style lang="scss" scoped>
.end-session-title{
  display:flex;
  align-items: center;
  gap:12px;
  padding: 16px 16px 32px 16px;
  
}
.actions{
  justify-content: right !important;
 padding: 0 32px 32px !important;
}
.current-session-info{
  display:flex;
  flex-direction: column;
  padding-right: 16px;
  padding-left: 16px;
}
.current-session-info-item{
  display: flex;
}
.current-info{
  width:210px;
}
.section-divider{
border: 1px solid $grayscale200;
}
</style>

<template>
  <div>
    <e-button
      variant="ghost"
      class="app-bar-search-toggler search-button"
      @click="shallShowFullSearchLocal = true"
      icon="icon-search"
    >
      <!-- <span class="" /> -->
    </e-button>

    <!-- This is clever hack to hide scrolling 😉 -->
    <v-dialog :value="shallShowFullSearchLocal" hide-overlay persistent />
    <v-expand-transition>
      <v-autocomplete
        v-show="shallShowFullSearchLocal"
        ref="refAutocomplete"
        :search-input.sync="searchQueryLocal"
        :items="searchQueryLocal ? data : []"
        :hide-no-data="loading"
        no-data-text=""
        item-text="title"
        item-value="title"
        class="app-bar-autocomplete-box"
        return-object
        :color="$vuetify.theme.dark ? 'white' : null"
        rounded
        attach
        :loading="loading"
        hide-details
        :filter="filter"
        auto-select-first
        :menu-props="{
          maxHeight: 500,
          transition: 'slide-y-transition',
          contentClass: 'list-style',
        }"
        @input="valueSelected"
        @keyup.esc="shallShowFullSearchLocal = false"
        @blur="shallShowFullSearchLocal = false"
      >
        <!-- Slot: Selection -->
        <template #selection />

        <template #prepend-inner>
<!--          <v-icon size="22" class="mr-1">-->
<!--            {{ icons.mdiMagnify }}-->
<!--          </v-icon>-->
          <e-icon class="mr-1 graybase--text">icon-search</e-icon>
        </template>

        <template #progress>
          <e-circular-loading :size="24" :value="loading" />
        </template>

<!--         Slot: Append-->
        <template #append>
<!--                  <v-icon v-show="!loading" @click="shallShowFullSearchLocal = false">-->
<!--                    {{ icons.mdiClose }}-->
<!--                  </v-icon>-->
          <div></div>
        </template>

        <template #no-data>
          <div class="ma-4 grayscale500--text caption-1">
            No results found. Please try another combination!
          </div>
        </template>

        <!-- Slot: Item -->
        <template #item="{ item }">
          <div class="w-full">
            <!-- Section: Pages -->
            <div class="d-flex align-content-end grayscale600--text">
              <v-icon size="20px" class="mr-3">
                {{ item.icon }}
              </v-icon>
              <span class="text-sm">{{ item.title }}</span>
            </div>
          </div>
        </template>
      </v-autocomplete>
    </v-expand-transition>
  </div>
</template>

<script>
import { mdiMagnify, mdiClose } from '@mdi/js'
import { useVModel, useMagicKeys, whenever } from '@vueuse/core'
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import store from '@/store'

export default {
  props: {
    shallShowFullSearch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
    filter: {
      type: Function,
      default: null,
    },
    searchQuery: {
      type: [String, null],
      default: '',
    },
  },
  setup(props, { emit }) {
    const shallShowFullSearchLocal = useVModel(props, 'shallShowFullSearch', emit)
    const searchQueryLocal = useVModel(props, 'searchQuery', emit)

    const refAutocomplete = ref(null)

    watch(shallShowFullSearchLocal, (value) => {
      if (!value) searchQueryLocal.value = ''
      store.commit('app/TOGGLE_CONTENT_OVERLAY', value)

      // ? We create our own autofucs logic because `autofocus` prop was creating issue on `esc` key
      if (value) {
        setTimeout(() => {
          refAutocomplete.value.focus()
        }, 150)
      }
    })

    const { router } = useRouter()
    const valueSelected = (value) => {
      if (value.to) {
        router.push(value.to.includes('governments') ? '/data/governments' : value.to).catch(() => {})
        // router.push(value.to).catch(() => {})
      }
      shallShowFullSearchLocal.value = false
      searchQueryLocal.value = ''
    }

    // Hotkey
    // eslint-disable-next-line camelcase
    const { ctrl_shift_slash } = useMagicKeys()
    whenever(ctrl_shift_slash, () => {
      shallShowFullSearchLocal.value = true
    })

    return {
      searchQueryLocal,
      refAutocomplete,
      shallShowFullSearchLocal,
      valueSelected,

      icons: {
        mdiMagnify,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme(app-bar-autocomplete-box) using ($material) {
  div[role='combobox'] {
    background-color: map-deep-get($material, 'cards');
  }
}

.search-button {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
}

.app-bar-autocomplete-box {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 14px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  .v-input__control {
    height: 100%;
  }

  div[role='combobox'] {
    height: 100%;
    z-index: 10;
    padding: 0 20px !important;

    .v-input__prepend-inner,
    .v-input__append-inner {
      align-self: center;
      margin-top: -2px;
      svg {
        font-size: 24px;
        height: 24px;
        width: 24px;
      }
    }

    .v-input__prepend-inner {
      @include ltr() {
        margin-left: 4px;
      }
      @include rtl() {
        margin-right: 4px;
      }
    }
  }
  // Content - Result
  .v-menu__content {
    @include style-scroll-bar();
    max-width: unset;
    width: 100%;
    border-radius: 12px !important;
    top: calc(64px + 0.625rem) !important;

    .v-subheader {
      //text-transform: uppercase;
      //letter-spacing: 0.21px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      //grayscale500
      color: #6B7280;
    }

    //@include theme(v-subheader) using ($material) {
    //  color: map-deep-get($material, 'text', 'disabled');
    //}

    .v-list-item {
      //color: $grayscale600 !important;
      //.v-icon {
      //  color: $grayscale600;
      //}
      > [id^='list-item'] {
        width: 100%;
      }
      &--active {
        .v-icon {
          color: #489FFE !important;
        }
      }
    }
  }

  @at-root .v-app-bar.app-bar-shinked & {
    div[role='combobox'] {
      padding: 0 !important;
    }

    // Content - Result
    .v-menu__content {
      max-width: unset;
      width: calc(100% + 20px * 2);
      @include ltr() {
        margin-left: -20px;
      }
      @include rtl() {
        margin-right: -20px;
      }
    }
  }
}

// ————————————————————————————————————
//* ——— Horizontal Nav
// ————————————————————————————————————

.content-layout.horizontal-nav {
  .app-system-bar {
    // Assigning 7 z-index so that search result can be seen on top of navigation menu
    z-index: 7;

    .v-text-field {
      margin-top: 0;
      padding-top: 0;
    }

    // Remove 3rem padding from both side.
    // ? 3rem is applied to navbar-content-container
    .app-bar-autocomplete-box {
      max-width: calc(100% - 3rem * 2);
      @include ltr() {
        margin-left: 3rem;
      }
      @include rtl() {
        margin-right: 3rem;
      }
    }

    // ? In Full content contet have padding of 1.5rem
    &:not(.app-system-bar-boxed) {
      .app-bar-autocomplete-box {
        max-width: calc(100% - 1.5rem * 2);
        @include ltr() {
          margin-left: 1.5rem;
        }
        @include rtl() {
          margin-right: 1.5rem;
        }
      }
    }
  }
}
</style>

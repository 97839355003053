<template>
  <div class="pa-3 pa-sm-5">
    <div
      v-if="title || $slots.actions"
      class="d-flex justify-space-between align-center pb-3"
    >
      <div class="subhead-1 graybase--text">{{ title }}</div>
      <slot name="actions"/>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InfoCardSection',
  props: {
    title: {
      type: String,
      default: '',
    }
  }
}
</script>

import * as api from '../../api'
import * as types from '../mutation-types'

export default {
  state: {
    success: false,
    schoolsBusy: false,
    fail: false,
    options: {
      page: 1,
      perPage: 50,
    },
    search: '',
    searchBy: '',
    filter: '',
    draw: 0,
    schools: [],
    response: {},
    activeSchoolName: '',
    schoolCreateFail: false,
    schoolCreateSuccess: false,
    schoolCreateBusy: false,
    createSchoolErrorMessages: [],
  },
  mutations: {
    [types.GET_SCHOOLS](state) {
      state.schoolsBusy = true
    },
    [types.GET_SCHOOLS_SUCCESS](state, response) {
      state.schoolsBusy = false
      state.response = response || {}
      // state.schools = response.data.data.data
      state.draw++
    },
    [types.GET_SCHOOLS_FAIL](state, error) {
      state.schoolsBusy = false
      state.success = false
      state.fail = true
      state.response = error
      //console.log('error')
    },
    updateSchoolsTableOptions(state, options) {
      state.options = options
    },
    updateSchoolSearch(state, search) {
      state.search = search
    },
    updateSchoolSearchBy(state, searchBy) {
      state.searchBy = searchBy
    },
    updateSchoolPerPage(state, perPage) {
      state.perPage = perPage
    },
    updateSchoolFilter(state, filter) {
      state.filter = filter
    },
    updateActiveSchoolName(state, value) {
      state.activeSchoolName = value
    },
    updateSchoolCreateBusy(state, value) {
      state.schoolCreateBusy = value
    },
    createSchoolSuccess(state) {
      state.schoolCreateSuccess = true
      state.schoolCreateBusy = false

      state.schoolCreateFail = false
      state.createSchoolErrorMessages = []
    },
    createSchoolFail(state, response) {
      state.schoolCreateFail = true
      state.schoolCreateSuccess = false
      state.schoolCreateBusy = false
      state.createSchoolErrorMessages = response
    },
    updateSchoolCreateSuccess(state, value) {
      state.schoolCreateSuccess = value
    },
    updateSchoolCreateFail(state, value) {
      state.schoolCreateFail = value
      state.createSchoolErrorMessages = []
    },
  },
  actions: {
    getSchools: (store, payload) => {
      store.commit(types.GET_SCHOOLS)
      return api.getSchools(payload).then(
        (response) => {
          if (response.data.error) {
            store.commit(types.GET_SCHOOLS_FAIL, response.data.error)
            throw new Error(response.data.error)
          }
          store.commit(types.GET_SCHOOLS_SUCCESS, response)
        },
        (error) => store.commit(types.GET_SCHOOLS_FAIL, error)
      )
    },
    createSchool: (
      store,
      {
        schoolName,
        schoolLanguage,
        schoolUrl,
        schoolInsitutionId,
        schoolCountryId,
        schoolCounty,
        schoolPostalIndex,
        schoolStreet,
        schoolPersonId,
        schoolCity,
      }
    ) => {
      store.commit('updateSchoolCreateBusy', true)
      return api
        .createSchool(
          schoolName,
          schoolLanguage,
          schoolUrl,
          schoolInsitutionId,
          schoolCountryId,
          schoolCounty,
          schoolPostalIndex,
          schoolStreet,
          schoolPersonId,
          schoolCity
        )
        .then((response) => store.commit('createSchoolSuccess', response))
        .catch((error) => {
          store.commit('createSchoolFail', error.response.data)
        })
    },
  },
  getters: {
    schoolsTable: (state) => {
      let filteredResponse = {}
      if (typeof state.response.data !== 'undefined' && state.response.data?.data?.length !== 0) {
        filteredResponse['draw'] = state.draw
        filteredResponse['recordsFiltered'] = state.response.data.data.total
        filteredResponse['recordsTotal'] = state.response.data.data.total
        filteredResponse['data'] = state.response.data.data.data
      } else {
        filteredResponse['draw'] = state.draw
        filteredResponse['recordsFiltered'] = 0
        filteredResponse['recordsTotal'] = 0
        filteredResponse['data'] = []
      }

      return filteredResponse
    },
    schoolsTableOptions: (state) => {
      return state.options
    },
    schoolsSearch: (state) => {
      return state.search
    },
    schoolsSearchBy: (state) => {
      return state.searchBy
    },
    schoolsPerPage: (state) => {
      return state.perPage
    },
    schoolsFilter: (state) => {
      return state.filter
    },
    activeSchoolName: (state) => {
      return state.activeSchoolName
    },
    createSchoolErrorMessages: (state) => {
      return state.createSchoolErrorMessages
    },
    schoolCreateSuccess: (state) => {
      return state.schoolCreateSuccess
    },
    schoolCreateFail: (state) => {
      return state.schoolCreateFail
    },
    schoolCreateBusy: (state) => {
      return state.schoolCreateBusy
    },
  },
}

<template>
  <div>
    <div class="d-flex justify-space-between flex-wrap-reverse">
      <div class="d-flex flex-grow-1 mb-3">
        <div class="d-flex flex-grow-1">
          <e-text-field v-model="search" placeholder="Search..." prepend-inner-icon="icon-search" />
        </div>
        <div class="pl-3">
          <e-select v-model="selectedFilter" :items="filters" class="select" />
        </div>
      </div>
    </div>

    <div>
      <e-data-table
        :headers="headers"
        :items="localGovernments"
        :items-per-page="-1"
        :hide-default-footer="false"
        :loading="localGovernmentsBusy"
        item-key="id"
        :search="search"
        :custom-filter="searchGovernment"
        class="graybase--text"
      >
        <template v-slot:[`item.menu`]="{ item }">
          <e-menu>
            <template v-slot:activator="{ on, attrs }">
              <e-button icon="icon-dots-vertical" variant="ghost" v-bind="attrs" v-on="on" />
            </template>
            <e-menu-list class="menu">
              <e-menu-list-item
                prepend-icon="icon-pen-underline"
                @click="showEditModal(item)"
              >
                Edit local government
              </e-menu-list-item>
              <e-divider />
              <e-menu-list-item
                variant="danger"
                prepend-icon="icon-trashcan"
                @click="showDeleteModal(item)"
              >
                Delete local government
              </e-menu-list-item>
            </e-menu-list>
          </e-menu>
        </template>

        <template v-slot:no-data>
          <no-data-block
            icon="icon-search"
            title="No results!"
            description="Nothing matched your search, please try a different combination."
          />
        </template>
        <template v-slot:no-results>
          <no-data-block
            icon="icon-search"
            title="No results!"
            description="Nothing matched your search, please try a different combination."
          />
        </template>
      </e-data-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NoDataBlock from '@/components/NoDataBlock'
import EditGovernmentModal from '@/views/data/governments/modals/EditGovernmentModal.vue'
export default {
  components: {
    EditGovernmentModal,
    NoDataBlock,
  },
  data() {
    return {
      editModalValue: false,
      deleteModalValue: false,
      currentGovernment: {},

      Governments: [],
      governmentID: null,
      governmentName: '',
      search: '',
      selectedFilter: 'id',
      filters: [
        { text: 'eKool ID', value: 'id' },
        { text: 'Name', value: 'name' },
      ],
      headers: [
        { text: 'eKool ID', align: 'start', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: '# of schools', value: 'school_count' },
        { text: 'Population', value: 'population' },
        // { text: '# of schools', value: 'number' },
        // { text: 'Population', value: 'population', width: '184px' },
        { text: '', value: 'menu', width: '54px' },
      ],
      debouncedSearch: _.debounce(() => {
        this.searchGovernment()
      }, 500),
    }
  },
  computed: {
    ...mapState('governments', [
      'localGovernments',
      'localGovernmentsBusy',
      'errorMessages',
    ]),
  },

  created() {
    if (this.localGovernments.length === 0) {
      this.$store.dispatch('governments/getLocalGovernments')
    }
  },
  methods: {
    showEditModal(government) {
      this.$eDialog({
        component: EditGovernmentModal,
        componentProps: {
          governmentData: government
        }
      })
    },
    showDeleteModal(government) {
      this.$eDialog({
        preset: 'dialog',
        presetProps: {
          title: 'Are you sure you want to continue?',
          description: `You are about to delete ${government.name} from the local governments list, this action is irreversible.`,
          closeButtonLabel: 'Cancel',
          deleteButtonLabel: 'Yes, delete',
        },
        presetEvents: {
          'click:delete': async () => {
            return await this.deleteGovernment(government.id)
          }
        }
      })
    },
    async deleteGovernment(id) {
      try {
        await this.$store.dispatch('governments/deleteGovernment', { id: id })
        this.$eToast.success('Local government was successfully removed')
        return true
      } catch (e) {
        this.$eToast.error(e)
        return false
      }
    },


    searchGovernment(value, search, item) {
      if (item[this.selectedFilter].toString().toLowerCase().includes(search.toLowerCase())) {
        return true
      }
      return false
    },
  },
}
</script>

<style scoped>
.select {
  width: 164px;
}
</style>

<template>
  <div class="main d-flex flex-column justify-space-between">
    <div class="table-header ma-3 ma-sm-5">
      <div class="money-icon">
        <v-icon
          medium
          :class="{
            'icon-money-bill success--text': componentName === 'packages',
            'icon-d-cube warning--text': componentName === 'features',
          }"
        />
        <span
          :class="{
            'subhead-2 ml-2 success--text': componentName === 'packages',
            'subhead-2 ml-2 warning--text': componentName === 'features',
          }"
        >
          {{ componentName === 'packages' ? `${item.price} EUR` : `Used in ${item.packagesCount} packages` }}</span
        >
      </div>
      <v-spacer></v-spacer>
        <e-button
          v-if="componentName === 'packages'"
          icon="icon-pen"
          @click="showPackageEditModal"
          variant="ghost"
        />
      <e-menu v-else min-width="240px">
        <template v-slot:activator="{ on, attrs }">
          <e-button medium="" v-bind="attrs" v-on="on" variant="ghost" icon="icon-dots-vertical"></e-button>
        </template>
        <e-menu-list>
          <e-menu-list-item prepend-icon="icon-pen" @click="showFeatureEditModal">
            Edit feature
          </e-menu-list-item>
          <e-divider size="7" />

          <!-- delete feature -->
          <e-menu-list-item variant="danger" prependIcon="icon-trashcan" @click="showFeatureDeleteModal">
            Delete feature
          </e-menu-list-item>
        </e-menu-list>
      </e-menu>
    </div>
    <div class="px-3 px-sm-5 pb-3 pb-sm-5 d-flex flex-column justify-space-between flex-grow-1">
      <div class="headline-3 graybase--text">{{ item.name }}</div>

      <div class="footer pt-2">
        <span class="caption-1 grayscale500--text">Updated at {{ setDateFormat(item.updated_at) }}</span>
        <v-spacer></v-spacer>
        <span v-if="componentName === 'features'" class="caption-1 grayscale500--text">{{ item.feature_key }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PackageEdit from './modals/PackageEdit.vue'
import FeatureEdit from './modals/FeatureEdit.vue'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  components: { PackageEdit, FeatureEdit },
  props: ['item', 'componentName', 'mdName'],
  data() {
    return {
      dialog2: false,
      activePackageFeatures: [],
      overlay: false,
    }
  },
  computed: {
    ...mapState('schoolPackages', ['packageFeaturesList', 'featureList', 'packageFeaturesBusy']),
  },

  methods: {
    showPackageEditModal() {
      this.$eDialog({
        width: 424,
        component: PackageEdit,
        componentProps: {
          item: this.item,
        }
      })
    },
    showFeatureEditModal() {
      this.$eDialog({
        width: 424,
        component: FeatureEdit,
        componentProps: {
          item: this.item,
        }
      })
    },
    setDateFormat(value) {
      return moment(value).add(2, 'h').format('ddd, DD MMM YYYY HH:mm')
    },
    showFeatureDeleteModal() {
      this.$eDialog({
        preset: 'dialog',
        presetProps: {
          title: 'Are you sure you want to continue?',
          description: `You are about to delete ${this.item.name} from the features list, this action is irreversible.`,
          closeButtonLabel: 'Cancel',
          deleteButtonLabel: 'Delete',
        },
        presetEvents: {
          'click:delete': async () => {
            return await this.deletePackageFeature(this.item)
          }
        }
      })
    },
    deletePackageFeature(feature) {
      // setTimeout(() => {
      //   this.overlay = true
      // }, 300)
      return this.$store.dispatch('schoolPackages/deletePackageFeature', { id: feature.id }).then(
        () => {
          this.$eToast.success('Feature was successfully removed')
          return true
        },
        (err) => {
          this.$eToast.error('Can not remove feature')
          return false
        }
      )
    },
  },
}
</script>

<style scoped>
.main {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  /*min-width: 353px;*/
  height: 100%;
}
.table-header {
  display: flex;
}
.money-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  display: flex;
}
</style>

<template>
  <page-container title="Users">
    <!--  Table header:search,select,export buttons -->
    <div class="d-flex justify-space-between flex-wrap-reverse">
      <div class="d-flex flex-grow-1 mb-3">
        <div class="d-flex flex-grow-1">
          <e-text-field v-model="search" placeholder="Search..." prepend-inner-icon="icon-search" />
        </div>
        <div class="pl-3">
          <e-select v-model="searchBy" :items="filters" :placeholder="filters[0].text" class="select" />
        </div>
      </div>
    </div>
    <no-data-block
      v-if="search === ''"
      icon="icon-search"
      title="Please use search to find users"
      description="To find a user - please use a search bar and type user data to find it."
    />
    <!--     Table     -->
    <div v-else>
      <e-data-table
        class="clickable"
        item-key="id"
        :headers="headers"
        :items="search === '' || null || isLoading ? [] : users"
        :hide-default-footer="false"
        :loading="isLoading"
        :options.sync="options"
        :items-per-page="options.perPage"
        :server-items-length="total"
        @click:row="handleClick"
      >
      <!-- ekool id -->
      <div slot-scope="{ item }" class="d-flex align-center" slot="item.id">
           <e-tooltip right v-if="item.verified_at">
            <template #activator="{ on, attrs }">
              <div class="badge-icon" v-bind="attrs"
                v-on="on">
              <badge-icon-verified class="mr-3" />
              </div>
            </template>
        Identity verified via {{item.verified_with}} 
          </e-tooltip>
             <badge-icon-not-verified v-else  class="mr-3" />
            <div class="mr-2">
            {{ item.id }}
          </div>
          <div>   
          </div>
        </div>
        <!-- username -->
         <div slot-scope="{ item }" class="d-flex align-center" slot="item.user_name">
           <div class="mr-2">
            {{ item.email}}
          </div> 
        </div>
        <!-- email -->
          <div slot-scope="{ item }" class="d-flex align-center" slot="item.email">
           <div class="mr-2">
            {{ item.email}}
          </div> 
          <div v-if="item.email">
             <e-chip v-if="item.email_verified_at" prepend-icon="icon-checkmark" variant="success" small>Verified</e-chip>
          <e-chip v-else variant="warning" small>Not verified</e-chip>
          </div>
        </div>
          <!-- phone number -->
          <div slot-scope="{ item }" class="d-flex align-center" slot="item.phone_number">
           <div class="mr-2">
            {{ item.phone_number}}
          </div> 
           <div v-if="item.phone_number">
           <e-chip v-if="item.phone_verified_at" prepend-icon="icon-checkmark" variant="success" small>Verified</e-chip>
          <e-chip v-else variant="warning" small>Not verified</e-chip>
           </div>
        </div>
             <!-- account status -->
          <div slot-scope="{ item }" class="d-flex align-center" slot="item.active">
           <div class="mr-2">
            <div v-if="item.active==true" class="active_account">Activated</div>
            <div v-else class="not_active_account">Not activated</div>
       
          </div>   
          <div>   
          </div>
        </div>

        <template v-slot:no-data>
          <no-data-block
            v-show="search !== '' || null"
            icon="icon-search"
            title="No results!"
            description="Nothing matched your search, please try a different combination."
          />
        </template>
        <template v-slot:no-results>
          <no-data-block
            v-show="search !== '' || null"
            icon="icon-search"
            title="No results!"
            description="Nothing matched your search, please try a different combination."
          />
        </template>
      </e-data-table>
    </div>
  </page-container>
</template>

<script>
import _ from 'lodash'
import NoDataBlock from '@/components/NoDataBlock'
import PageContainer from '@/components/PageContainer'
import BadgeIconNotVerified from '@/components/BadgeIconNotVerified.vue'
import BadgeIconVerified from '@/components/BadgeIconVerified.vue'
export default {
  components: { PageContainer, NoDataBlock,BadgeIconNotVerified,BadgeIconVerified },
  props: ['labelName'],
  data() {
    return {
      loading: false,
      headers: [
        { text: 'Account status', value: 'active' },
        { text: 'eKool ID', align: 'start', value: 'id'}, 
        { text: 'ID code', value: 'id_code' },
        { text: 'First name', value: 'name1' },
        { text: 'Last name', value: 'name2' },
        { text: 'User name', value: 'user_name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone number', value: 'phone_number' },
      ],
      filters: [
        { text: 'All', value: '' },
        { text: 'eKool ID', align: 'start', value: 'person.id' },
        { text: 'First name', value: 'person.name1' },
        { text: 'Last name', value: 'person.name2' },
        { text: 'Full name', value: 'firstnamelastname' },
        { text: 'User name', value: 'person.user_name' },
        { text: 'ID code', value: 'person.id_code' },
        { text: 'Email', value: 'email.name' },
        { text: 'Phone number', value: 'phone.phone_number' },
      ],
      debouncedSearch: _.debounce(() => {
        this.getUsers()
      }, 1000),
      users: [],
    }
  },
  watch: {
    options: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && typeof oldValue.itemsPerPage === 'number') {
          this.getUsers()
        }
      },
    },
    // search: {
    //   handler(newValue, oldValue) {
    //     if (newValue !== oldValue) {
    //       this.options.page = 1
    //       this.loading = true
    //       // this.debouncedSearch()
    //       this.getUsers()
    //     }
    //   },
    // },
    // searchBy: {
    //   handler() {
    //     this.options.page = 1
    //     this.getUsers()
    //   },
    // },
  },

  computed: {
    total() {
      return this.$store.getters.usersTable.recordsFiltered
    },
    isLoading() {
      return this.loading || this.usersBusy
    },
    usersBusy() {
      return this.$store.state.users.busy
    },
    search: {
      get() {
        return this.$store.getters.usersSearch
      },
      set(value) {
        this.options.page = 1
        if (value.length > 2) {
          this.loading = true
          if (this.searchBy === 'person.id_code' && this.notNumber(value)) {
            this.$eToast.clear()
            this.$eToast.error('ID code must be a number!')
            this.$store.commit('updateSearch', value)
            this.debouncedSearch()
          } else {
            this.$store.commit('updateSearch', value)
            this.debouncedSearch()
          }
        } else this.$store.commit('updateSearch', value)
      },
    },
    searchBy: {
      get() {
        return this.$store.getters.usersSearchBy
      },
      set(value) {
        // if (this.notNumber(this.search) && value === 'person.id_code') {
        //   this.$eToast.clear()
        //   this.$eToast.error('ID code must be a number!')
        // } else {
        this.$store.commit('updateSearchBy', value)
        if (this.search.length > 2) {
          this.getUsers()
        }
        // }
      },
    },
    options: {
      get() {
        return this.$store.getters.usersTableOptions
      },
      set(value) {
        this.$store.commit('updateUsersTableOptions', value)
      },
    },
  },
  methods: {
    handleClick(user) {
      // [API:TODO 1] make a call to the API to get user details and then get this data on user-details-page and fill user's info card with this data
      // this.$store.dispatch('getUserInfo', { userId: user.id})
      this.$router.push({ name: 'data-users-detailed', params: { id: user.id } })
    },
    notNumber(value) {
      return Number.isNaN(Number(value))
    },
    // [API:INFO] STEP 2
    getUsers() {
      // gather payload for dispatch
      // const perPage = this.perPage
      // const page = 1

      const { page, perPage } = this.options
      const search = this.search
      const searchBy = this.searchBy

      const order = undefined
      const orderBy = undefined
      // Make dispatch to store and call action 'getUsers' which will make
      this.$store.dispatch('getUsers', { page, perPage, search, searchBy, order, orderBy }).then(
        () => {
          // [API:INFO] STEP 6
          // call store's getter to get list of users that we got by making a call to the API
          const data = this.$store.getters.usersTable
          // set users array to our users variable for data-table's items
          this.users = data.data
          localStorage.setItem('users', JSON.stringify(this.users))
        },
        (err) => {
          // console.error(err)
          this.$eToast.clear()
          this.$eToast.error(err)
        }
      )
      this.loading = false
    },
  },
  async created() {
    // [API:INFO] STEP 1
    // Call the method that makes a call to store when open the "users" page
    // this.getUsers()
    if (this.search !== ' ') {
      this.users = JSON.parse(localStorage.getItem('users') || '[]')
    }
  },
}
</script>

<style scoped>
.select {
  width: 164px;
}
.not_active_account{
color:rgba(228, 63, 57, 1)
}
.active_account{
color:rgba(76, 166, 104, 1)
}
.badge-icon{
  display: flex;
}
</style>

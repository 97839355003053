import axios from '@/plugins/axios'

// GET REQUESTS
export const getCountries = () => {
  return axios.get('countries')
}



// POST REQUESTS
export const getHorizonToken = () => {
  return axios.post('horizon-token')
}

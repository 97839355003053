<template>
  <div>
    <e-dialog-header @click:close="close">
      Edit package
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
        <div class="mt-4">
          <e-text-field
            large
            class="body-2 mb-8"
            label="Name"
            v-model="tempObjectName"
            required
            :rules="[validators.required]"
          />
          <e-text-field
            large
            class="body-2 mb-3"
            label="Price"
            v-model="tempObjectPrice"
            prependInnerIcon="icon-money-bill"
            required
            :rules="[validators.required]"
          />
          <e-hint dismissible title="Price is expressed in Euros (EUR)" />
        </div>
        <div class="enable-package mb-3 mt-7">
          <span class="callout-3 graybase--text">Enable package features</span>
          <v-spacer></v-spacer>
          <e-button @click="showAddFeatureModal" variant="link">Create new feature</e-button>
        </div>
        <ul class="pa-0 ma-0">
          <li class="pa-0 ma-0" v-for="feature in featureList" :key="feature.id">
            <e-switch class="pa-0 ma-0 red--text" :label="feature.name" :value="feature.id" v-model="checkedFeatures" />
          </li>
        </ul>
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="savePackageFeatures">Save edits package</e-button>
    </e-dialog-actions>
    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from '@core/utils/validation'
import FeatureAdd from './FeatureAdd.vue'
export default {
  props: [ 'item'],
  components: { FeatureAdd },
  data() {
    return {
      valid: false,
      newPackageName: '',
      newPrice: null,
      checkedFeatures: [],
      activePackageFeatures: [],
      previousPackageFeatures: [],
      tempObject: {},
      tempObjectName: '',
      tempObjectPrice: '',
      overlay: false,
    }
  },
  computed: {
    ...mapState('schoolPackages', ['packageFeaturesList', 'featureList']),
    validators() {
      return {
        required,
      }
    },
  },
  watch: {
    checkedFeatures() {
      this.setActivepacakgeFeatures(this.checkedFeatures)
      this.$emit('activePackageFeatures', this.checkedFeatures)
    },
    packageFeaturesList() {
      this.checkedFeatures = []
      if (this.packageFeaturesList.length > 0) {
        this.packageFeaturesList.forEach((feature) => {
          this.checkedFeatures.push(feature.school_package_feature_id)
        })
      }
      this.setPreviousPackageFeatures(this.checkedFeatures)
      this.$emit('previousPackageFeatures', this.checkedFeatures)
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    showAddFeatureModal() {
      this.$eDialog({
        width: 424,
        component: FeatureAdd,
      })
    },
    async createPackage() {
      let data = {
        name: this.newPackageName,
        price: this.newPrice,
      }
      await this.$store.dispatch('schoolPackages/createPackage', data)
    },
    setActivepacakgeFeatures(activeFeatures) {
      this.activePackageFeatures = activeFeatures
    },
    setPreviousPackageFeatures(previousFeatures) {
      this.previousPackageFeatures = previousFeatures
    },
    editPackageFeatures(schoolPackage) {
      this.overlay = false
      this.activePackageId = schoolPackage.id
      this.activePackageName = schoolPackage.name
      this.$store.dispatch('schoolPackages/getPackageFeaturesList', { id: this.activePackageId })
      // this.tempObject = Object.assign({}, this.item)
      this.tempObjectName = this.item.name
      this.tempObjectPrice = this.item.price
    },
    async savePackageFeatures() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      let diff = this.previousPackageFeatures.filter((id) => !this.activePackageFeatures.includes(id))
      let store = this.activePackageFeatures.filter((id) => !this.previousPackageFeatures.includes(id))
      let removedPackageFeatures = []
      diff.forEach((id) => {
        let packageFeature = this.packageFeaturesList.filter((feature) => feature.school_package_feature_id == id)
        if (packageFeature.length > 0) {
          removedPackageFeatures.push(packageFeature[0].id)
        }
      })
      let uniqueRemovedFeatures = [...new Set(removedPackageFeatures)]
      const data = {
        store,
        delete: uniqueRemovedFeatures,
      }
      try {
        await this.$store.dispatch('schoolPackages/updatePackageFeatureList', {
          packageId: this.activePackageId,
          featureList: this.activePackageFeatures,
          data,
        })
        await this.$store.dispatch('schoolPackages/updatePackage', {
          name: this.tempObjectName,
          price: this.tempObjectPrice,
          id: this.activePackageId,
        })
        this.$eToast.success('A package has been changed!')
        this.close()
      } catch (e) {
        this.$eToast.error(e)
      } finally {
        this.overlay = false
      }
    },
  },
  async created() {
    // this.$store.dispatch('schoolPackages/getFeatureList')
    if (this.item) {
      this.$store
        .dispatch('schoolPackages/getPackageFeaturesList', { id: this.item.id })
        .then((response) => {
          this.activePackageFeatures = this.packageFeaturesList
          this.editPackageFeatures(this.item)
        })
    }
  },
}
</script>

<style scoped>
.enable-package {
  display: flex;
  align-items: center;
}
li {
  list-style-type: none;
}
</style>

<template>
  <div>
    <e-dialog-header @click:close="close">
      Add  package
    </e-dialog-header>
    <e-dialog-content>
      <div class="callout-3 graybase--text pb-6">Select package(s) to add</div>
      <e-radio-group v-model="radioGroup" class="pt-0">
        <e-radio
          v-for="packages in packageList"
          :key="packages.id"
          :label="packages.name"
          :value="packages"
          detail="features"
        ></e-radio>
      </e-radio-group>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="showAddPackageSecondStepModal" :disabled="!radioGroup">Next</e-button>
    </e-dialog-actions>
  </div>
</template>

<script>
import { getEnv } from '@env'
import { mapState } from 'vuex'
import AddPackageSecondStepModal from '@/views/data/schools/modals/AddPackageSecondStepModal.vue'
export default {
  name: 'AddPackageFirstStepModal',
  props: {
    schoolId: {
      type: [Number, String],
      default: '',
    }
  },
  data() {
    return {
      radioGroup: null,
    }
  },
  computed: {
    ...mapState('schoolPackages', ['packageList']),
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async showAddPackageSecondStepModal() {
      const isSubmitted = await this.$eDialog({
        width: 424,
        component: AddPackageSecondStepModal,
        componentProps: {
          packageData: this.radioGroup,
          schoolId: this.schoolId,
        }
      })
      if (isSubmitted) {
        this.close()
      }
    }
  },
}
</script>

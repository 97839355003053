<template>
  <div class="border overflow-x-hidden elevation-1 border-radius" :class="flat ? '' : 'pa-3 pa-sm-5'">
    <div v-if="title || $slots.actions" class="d-flex pb-3 pb-sm-5">
      <div class="headline-3 graybase--text">{{ title }}</div>
      <v-spacer v-if="$slots.actions"/>
      <slot name="actions" />
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContainer',
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

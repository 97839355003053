<template>
  <chart-page-template>
    <template v-slot:title> Reactions </template>
    <template v-slot:range>
      <v-btn-toggle v-model="toggle" mandatory class="grayscale50">
        <div v-for="(button, idx) in dateButtons" :class="idx < dateButtons.length - 1 ? 'mr-2' : ''">
          <e-button class="toggle-btn" variant="ghost" @click="buttonDatePick(button.dateFrom, button.dateTo)">{{
            button.name
          }}</e-button>
        </div>
        <div class="ml-2" v-if="customDateFrom && customDateTo">
          <e-button class="toggle-btn" variant="ghost" @click="buttonDatePick(customDateFrom, customDateTo)"
            >From {{ transformedDateFrom }} to {{ transformedDateTo }}</e-button
          >
        </div>
      </v-btn-toggle>
    </template>
    <template v-slot:range-picker>
      <v-form ref="form" v-model="valid">
        <e-dialog
          max-width="424px"
          v-model="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <e-button v-bind="attrs" v-on="on" variant="tertiary" icon="icon-calendar" large></e-button>
          </template>
          <e-dialog-header @click:close="dialog = false">Select range</e-dialog-header>
          <e-dialog-content>
            <div class="pb-3 pb-sm-5 callout-3 graybase--text">Number of days</div>
            <div>
              <div class="date-block">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  min-width="auto"
                  v-model="showDatePicker"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="py-3" v-bind="attrs" v-on="on">
                      <e-text-field
                        :rules="[validators.required]"
                        large
                        appendIcon="icon-calendar"
                        label="From"
                        class="body-2"
                        v-model="dateFormattedFrom"
                        @blur="customDateFrom = parseDate(dateFormattedFrom)"
                        @keyup.enter="customDateFrom = parseDate(dateFormattedFrom)"
                        @input="setDateFrom"
                        placeholder="Start date"
                        required
                      />
                    </div>
                  </template>
                  <e-date-picker v-model="customDateFrom" @input="showDatePicker = false">
                    <v-spacer></v-spacer>
                  </e-date-picker>
                </v-menu>
              </div>

              <div class="date-block">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  min-width="auto"
                  v-model="showDatePicker2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="pt-4 pb-2" v-bind="attrs" v-on="on">
                      <e-text-field
                        large
                        :rules="[validators.required]"
                        appendIcon="icon-calendar"
                        label="Until"
                        class="body-2 py-3"
                        v-model="dateFormattedTo"
                        @blur="customDateTo = parseDate(dateFormattedTo)"
                        @keyup.enter="customDateTo = parseDate(dateFormattedTo)"
                        @input="setDateTo"
                        placeholder="End date"
                        required
                      />
                    </div>
                  </template>
                  <e-date-picker v-model="customDateTo" @input="showDatePicker2 = false">
                    <v-spacer></v-spacer>
                  </e-date-picker>
                </v-menu>
              </div>
              <div><e-button variant="tertiary" @click="clearRange">Clear range</e-button></div>
            </div>
          </e-dialog-content>
          <e-dialog-actions>
            <e-button variant="tertiary" @click="dialog = false">Cancel</e-button>
            <e-button @click="handleSelection">Set range</e-button>
          </e-dialog-actions>
        </e-dialog>
      </v-form>
    </template>
    <template v-slot:default>
      <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </template>
    <template v-slot:main-table>
      <e-data-table
        :headers="mainTableHeaders"
        :items="mainTableData"
        :items-per-page="mainTableData.length"
        item-key="id"
        class="text-no-wrap"
      >
        <template slot="body.append">
          <tr>
            <td class="font-weight-bold">Total</td>
            <td v-for="(date, idx) in dates" :key="idx" class="font-weight-bold">
              {{ totalRow[date] }}
            </td>
          </tr>
        </template>
      </e-data-table>
    </template>
    <template v-slot:total-table>
      <e-data-table
        :headers="totalTableHeader"
        :items="mainTableData"
        :items-per-page="mainTableData.length"
        item-key="id"
      >
        <template slot="body.append">
          <tr>
            <td class="font-weight-bold">{{ totalRow.totalInPeriod }}</td>
          </tr>
        </template>
      </e-data-table>
    </template>
  </chart-page-template>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'
import moment from 'moment'
import { required } from '@core/utils/validation'
import { mapGetters } from 'vuex'
import ChartPageTemplate from '@/components/ChartPageTemplate'
import { chartColors } from '@/utils'

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement)

export default {
  name: 'ReactionsChart',
  components: {
    ChartPageTemplate,
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [
        {
          beforeInit(chart) {
            // Get reference to the original fit function
            const originalFit = chart.legend.fit
            // Override the fit function
            chart.legend.fit = function fit() {
              // Call original function and bind scope in order to use `this` correctly inside it
              originalFit.bind(chart.legend)()
              // Change the height as suggested in another answers
              this.height += 20
            }
          },
        },
      ],
    },
  },
  watch: {
    dialog() {
      this.$refs.form.resetValidation()
    },
    customDateFrom(val) {
      this.dateFormattedFrom = this.formatDate(this.customDateFrom)
    },
    customDateTo(val) {
      this.dateFormattedTo = this.formatDate(this.customDateTo)
    },
  },
  data() {
    return {
      customDateFrom: '',
      dateFormattedFrom: '',
      customDateTo: '',
      dateFormattedTo: '',
      valid: false,
      toggle: 0,
      dialog: false,
      showDatePicker: false,
      showDatePicker2: false,
      dateButtons: [
        {
          dateFrom: moment().subtract(8, 'days'),
          dateTo: moment().subtract(1, 'days'),
          name: '1w',
        },
        {
          dateFrom: moment().subtract(31, 'days'),
          dateTo: moment().subtract(1, 'days'),
          name: '1m',
        },
        {
          dateFrom: moment().subtract(1, 'month').add(1, 'day').date(1),
          dateTo: moment().subtract(1, 'month').endOf('month'),
          name: moment().subtract(1, 'month').date(1).format('MMMM'),
        },
        {
          dateFrom: moment().subtract(2, 'month').date(1),
          dateTo: moment().subtract(2, 'month').endOf('month'),
          name: moment().subtract(2, 'month').date(1).format('MMMM'),
        },
      ],
      dateFrom: moment().subtract(8, 'days'),
      dateTo: moment().subtract(1, 'days'),
    }
  },
  computed: {
    computedDateFormattedFrom() {
      return this.formatDate(this.customDateFrom)
    },
    computedDateFormattedTo() {
      return this.formatDate(this.customDateTo)
    },
    validators() {
      return {
        required,
      }
    },
    transformedDateFrom() {
      return moment(this.customDateFrom).format('MMMM Do YYYY')
    },
    transformedDateTo() {
      return moment(this.customDateTo).format('MMMM Do YYYY')
    },
    ...mapGetters([
      //chart loading
      'reactionsBusy',
      //all table data
      'reactionsData',
      'reactionsDates',
    ]),

    // TABLE DATA
    mainTableHeaders() {
      const dateHeaders = this.dates.map((d) => {
        return {
          text: moment(d).format('DD MMM'),
          value: `${d}`,
          sortable: false,
        }
      })
      return [
        {
          text: 'Type',
          value: 'type',
          sortable: false,
        },
        ...dateHeaders,
      ]
    },
    totalTableHeader() {
      return [
        {
          text: this.$vuetify.breakpoint.xsOnly ? 'Totals' : 'Totals in period',
          value: `totalInPeriod`,
          sortable: false,
        },
      ]
    },
    totalRow() {
      let total = {}
      this.dates.forEach((date) => {
        let totalPerDate = 0
        this.mainTableData.forEach((i) => {
          totalPerDate += i[date] >= 0 ? i[date] : 0
        })
        total = {
          ...total,
          [date]: totalPerDate,
        }
      })
      let totalInPeriod = 0
      Object.values(total).forEach((value) => {
        totalInPeriod += value >= 0 ? value : 0
      })
      total.totalInPeriod = totalInPeriod
      return total
    },
    mainTableData() {
      return this.reactionsData
    },
    dates() {
      return this.reactionsDates
    },
    datasets() {
      return this.mainTableData.map((item, idx) => {
        const color = idx > chartColors.length ? chartColors[idx - chartColors.length] : chartColors[idx]
        return {
          label: item.type,
          borderColor: color,
          backgroundColor: color,
          borderJoinStyle: 'round',
          data: this.dates.map((d) => item[d]),
        }
      })
    },
    chartData() {
      return {
        labels: this.dates.map((d) => moment(d).format('DD MMM')),
        datasets: this.datasets,
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            align: 'start',
            labels: {
              color: '#000000',
              font: {
                size: 14,
                family: 'Inter',
              },
              boxWidth: 4,
              boxHeight: 4,
              usePointStyle: true,
            },
          },
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,

            external: function (context) {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip')

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div')
                tooltipEl.id = 'chartjs-tooltip'
                tooltipEl.innerHTML = '<table></table>'
                document.body.appendChild(tooltipEl)
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0
                return
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform')
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign)
              } else {
                tooltipEl.classList.add('no-transform')
              }

              function getBody(bodyItem) {
                return bodyItem.lines
              }

              // Set Text
              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || []
                const bodyLines = tooltipModel.body.map(getBody)

                let innerHtml = '<thead>'

                titleLines.forEach(function (title) {
                  innerHtml += '<tr><th style="font-weight: normal">' + title + '</th></tr>'
                })
                innerHtml += '</thead><div style="margin: 8px -8px;" class="border-bottom"></div><tbody>'

                bodyLines.forEach(function (body, i) {
                  const colors = tooltipModel.labelColors[i]
                  let style = 'background:' + colors.backgroundColor
                  style += '; border-color:' + colors.borderColor
                  // style += '; border-width: 2px';
                  const span = '<span style="' + style + '"></span>'
                  innerHtml += '<tr><td>' + span + body + '</td></tr>'
                })
                innerHtml += '</tbody>'

                let tableRoot = tooltipEl.querySelector('table')
                tableRoot.innerHTML = innerHtml
                tableRoot.style.borderCollapse = 'collapse'
              }

              const position = context.chart.canvas.getBoundingClientRect()
              // const bodyFont = ChartJS.helpers.toFont(tooltipModel.options.bodyFont);

              // Display, position, and set styles for font
              tooltipEl.classList.add('border-radius')
              tooltipEl.classList.add('border')
              tooltipEl.style.opacity = 1
              tooltipEl.style.overflow = 'hidden'
              tooltipEl.style.position = 'absolute'
              tooltipEl.style.backgroundColor = '#ffffff'
              tooltipEl.style.fontSize = '14px'
              tooltipEl.style.boxShadow = '0px 8px 16px rgba(3, 52, 104, 0.1)'
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
              // tooltipEl.style.font = bodyFont.string;
              tooltipEl.style.padding = '8px'
              tooltipEl.style.pointerEvents = 'none'
            },
          },
        },
      }
    },
  },
  methods: {
    async setDateFrom(value) {
      if (this.validateDate(value)) {
        this.customDateFrom = this.parseDate(value)
      }
    },
    async setDateTo(value) {
      if (this.validateDate(value)) {
        this.customDateTo = this.parseDate(value)
      }
    },
    validateDate(value) {
      return value.match(/^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/)
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    clearRange() {
      this.customDateFrom = ''
      this.customDateTo = ''
      if (this.toggle === 4) {
        this.toggle = 0
      }
    },
    handleSelection() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.dialog = false
      this.toggle = this.dateButtons.length
      this.buttonDatePick(this.customDateFrom, this.customDateTo)
    },
    getChartData() {
      const dateTo = moment(this.dateTo).format('YYYY-MM-DD')
      const dateFrom = moment(this.dateFrom).format('YYYY-MM-DD')
      this.$store.dispatch('getReactionsChartData', { dateFrom, dateTo })
    },
    buttonDatePick(dateFrom, dateTo) {
      this.dateFrom = moment(dateFrom).format('YYYY-MM-DD')
      this.dateTo = moment(dateTo).format('YYYY-MM-DD')
      this.getChartData()
    },
  },
  created() {
    this.getChartData()
  },
}
</script>

<template>
  <StatisticsCard title="Workplan statistics" icon="icon-file-folders">
    <StatisticsCardField description="Workplan count" :value="schoolWorkPlanStatistics.workplan_count" />
    <StatisticsCardField description="Count per teacher" :value="schoolWorkPlanStatistics.count_per_teacher" />
    <StatisticsCardField description="Count per journal" :value="schoolWorkPlanStatistics.count_per_journal" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
import StatisticsCard from '@/components/StatisticsCard.vue'
export default {
  components: {StatisticsCard, StatisticsCardField},
  computed: {
    ...mapState('school', ['schoolWorkPlanStatistics']),
  },
}
</script>

<template>
  <div>
    <e-dialog-header @click:close="close">
      {{ `Add ${packageData.name}`}}
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
        <div class="callout-3 graybase--text">Number of days</div>
        <div>
          <v-btn-toggle v-model="toggle" class="pt-3 d-flex flex-wrap" v-if="!switchValue">
            <div v-for="(date, idx) in dates" :key="date" class="d-flex mb-3 justify-center align-center">
              <e-button variant="ghost" class="">{{ date }}</e-button>
                <div v-if="idx !== dates.length - 1" class="toggle-separator mx-3"></div>

            </div>
          </v-btn-toggle>
        </div>
        <div class="pt-1">
          <e-switch label="Custom range" v-model="switchValue" @click="closeDatePickers" />
        </div>
        <div v-if="switchValue">
          <div class="pb-8 pt-7">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              offset-x
              min-width="auto"
              v-model="showDatePicker"
            >
              <template v-slot:activator="{ on, attrs }">
                <e-text-field
                  v-model="dateFormattedFrom"
                  @blur="startDate = parseDate(dateFormattedFrom)"
                  @keyup.enter="startDate = parseDate(dateFormattedFrom)"
                  @input="setDateFrom"
                  placeholder="Start date"
                  label="From"
                  append-icon="icon-calendar"
                  required
                  :rules="[validators.required]"
                  v-bind="attrs"
                  v-on="on"
                  @click:append="on.click"
                />
              </template>
              <e-date-picker v-model="startDate" @input="showDatePicker = false">
                <v-spacer></v-spacer>
              </e-date-picker>
            </v-menu>
          </div>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            offset-x
            min-width="auto"
            v-model="showDatePicker2"
          >
            <template v-slot:activator="{ on, attrs }">
              <e-text-field
                v-model="dateFormattedTo"
                @blur="endDate = parseDate(dateFormattedTo)"
                @keyup.enter="endDate = parseDate(dateFormattedTo)"
                @input="setDateTo"
                placeholder="End date"
                label="Until"
                append-icon="icon-calendar"
                required
                :rules="[validators.required]"
                v-bind="attrs"
                v-on="on"
                @click:append="on.click"
              />
            </template>
            <e-date-picker v-model="endDate" @input="showDatePicker2 = false">
              <v-spacer></v-spacer>
            </e-date-picker>
          </v-menu>
        </div>
        <loading-overlay :value="overlay" />
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Go back</e-button>
      <e-button @click="addPackage">Add package</e-button>
    </e-dialog-actions>
  </div>
</template>

<script>
import { required } from '@core/utils/validation'
import moment from 'moment/moment'
export default {
  props: {
    packageData: {
      type: Object,
      default: () => ({})
    },
    schoolId: {
      type: [Number, String],
      default: '',
    }
  },
  data() {
    return {
      valid: false,
      overlay: false,
      switchValue: false,
      toggle: 0,
      disabledDates: [],
      dates: ['14 days', '1 month', '6 months', '1 year', '2 years'],
      startDate: '',
      endDate: '',
      dateFormattedFrom: '',
      dateFormattedTo: '',
      showDatePicker: false,
      showDatePicker2: false,
      datePickerOptions: {
        format: 'D-MMM',
      },
    }
  },

  watch: {
    startDate(val) {
      this.dateFormattedFrom = this.formatDate(this.startDate)
    },
    endDate(val) {
      this.dateFormattedTo = this.formatDate(this.endDate)
    },
    toggle: {
      handler(val) {
        const end = {
          0: moment().add(14, 'day').format('YYYY-MM-DD'),
          1: moment().add(1, 'month').format('YYYY-MM-DD'),
          2: moment().add(6, 'month').format('YYYY-MM-DD'),
          3: moment().add(1, 'year').format('YYYY-MM-DD'),
          4: moment().add(2, 'year').format('YYYY-MM-DD'),
        }
        this.endDate = end[val]
        const start = {
          0: moment().format('YYYY-MM-DD'),
          1: moment().format('YYYY-MM-DD'),
          2: moment().format('YYYY-MM-DD'),
          3: moment().format('YYYY-MM-DD'),
          4: moment().format('YYYY-MM-DD'),
        }

        this.startDate = start[val]
      },
      immediate: true,
    },
  },
  computed: {
    validators() {
      return {
        required,
      }
    },
    computedDateFormattedFrom() {
      return this.formatDate(this.startDate)
    },
    computedDateFormattedTo() {
      return this.formatDate(this.endDate)
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },

    async addPackage() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      try {
        const data = {
          school_package_id: this.packageData.id,
          start_date: this.startDate,
          end_date: this.endDate,
        }
        await this.$store.dispatch('school/updateSchoolPackageList', {
          schoolId: this.schoolId,
          packageData: data,
          packageName: this.packageData.name,
        })
        this.$eToast.success('School package was successfully added')
        this.$root.$emit('submit', true)
      } catch (e) {
        this.$eToast.error('Something went wrong')
      } finally {
        this.overlay = false
      }
    },

    closeDatePickers() {
      this.showDatePicker = false
      this.showDatePicker2 = false
    },

    async setDateFrom(value) {
      if (this.validateDate(value)) {
        this.startDate = this.parseDate(value)
      }
    },
    async setDateTo(value) {
      if (this.validateDate(value)) {
        this.endDate = this.parseDate(value)
      }
    },
    validateDate(value) {
      return value.match(/^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/)
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>

<template>
  <div>
    <e-dialog-header @click:close="close">
      Edit user details
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
          <div class="text-field">
            <e-text-field
              large
              class="body-2"
              label="First name"
              maxlength="25"
              v-model="user.name1"
              required
              :rules="[validators.required]"
            />
          </div>
          <div class="text-field">
            <e-text-field
              large
              class="body-2"
              label="Last name"
              maxlength="25"
              v-model="user.name2"
              required
              :rules="[validators.required]"
            />
          </div>
          <div class="text-field">
            <e-text-field
              large
              class="body-2"
              label="Personal code"
              v-model="user.id_code"
              required
              :rules="[validators.required]"
            />
          </div>

          <div class="text-field">
            <e-select
              id="country_id"
              large
              label="Country"
              item-text="name"
              item-value="id"
              :items="countries"
              v-model="user.country_id"
            >
              <template v-slot:[`item.name`]="{ item }">
                {{ item.name }}
              </template>
            </e-select>
          </div>
          <div class="">
            <e-text-field
              large
              class="body-2"
              label="Username"
              :rules="user.user_name == '' ? [validators.maxLength,validators.emailRules] : []"
              v-model="user.user_name"
              placeholder="Set your username"
            />
          </div>
          <div class="mt-3 pb-2">
            <div class="callout-3">Password</div>
          </div>
         <!-- MD Details Edit / Set password -->
          <e-button v-if="!user.temporary_password_is_set" large block variant="tertiary" @click="showResetPasswordModal"> Reset password </e-button>

          <template v-if="user.temporary_password_is_set">
            <e-button large class="mb-1" variant="tertiary" block @click="resetPassword"
              >Reset to old password</e-button
            >
            <e-button large variant="ghost" block @click="savePassword">Make temporary password permanent</e-button>
          </template>
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="save">Save edit</e-button>

    </e-dialog-actions>

    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import { required } from '@core/utils/validation'
import ResetPasswordModal from '@/views/data/users/user-details/modals/ResetPasswordModal.vue'

export default {
  name: 'EditUserInfoModal',
  data() {
    return {
      valid: false,
      overlay: false,
      user: this.userData,
      // emailRules: [
      //   (v) =>
      //     /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      //       v
      //     )|| 'Username must be an email',
      // ],
    }
  },
  props: {
    userData: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
  },
  computed: {
    validators() {
      return {
        required,
        maxLength: (value) => {
          return this.user?.user_name?.length <= 120 || 'Username cannot succeed 120 characters'
        },
        emailRules: (value) =>{
        return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/.test(value) ||'Username must be an email.'
        },

      }
    },
    countries() {
      return this.$store.getters.countries
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async showResetPasswordModal () {
      const isPasswordChanged = await this.$eDialog({
        width: 424,
        component: ResetPasswordModal,
        componentProps: {
          userData: this.user,
        }
      })
      if (isPasswordChanged) {
        this.$root.$emit('submit', true)
      }
    },

    async save() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      try {
        let userData = this.user
        await this.$store.dispatch('saveUser', userData)
        this.$eToast.success('A user has been changed!')
        this.$root.$emit('submit', true)
      } catch (e) {
       this.$eToast.error('Username must be an email.')
      } finally {
        this.overlay = false
      }
    },

    async savePassword() {
      let userId = this.$route.params.id
      if (this.user.password === this.user.password2) {
        await this.$store.dispatch('savePassword', userId)
        this.$root.$emit('submit', true)
      }
    },

    async resetPassword() {
      let userId = this.$route.params.id
      await this.$store.dispatch('resetPassword', userId)
      this.$root.$emit('submit', true)
    },
  },
}
</script>
<style lang="scss" scoped>
</style>

<template>
  <page-container title="Active packages">
    <template #actions>
      <e-button @click="showAddPackageFirstStepModal"> Add Package </e-button>
    </template>
    <div class="row gutter-2">
      <div
        v-for="schoolPackage in schoolPackageList.slice().reverse()"
        :key="schoolPackage.id"
        class="col-12 col-sm-6 overflow-hidden pa-2"
      >
        <package-card
          :title="schoolPackage.school_package_name"
          :startDate="schoolPackage.start_date"
          :endDate="schoolPackage.end_date"
          :id="schoolPackage.id"
          :schoolPackageId="schoolPackage.school_package_id"
        />
      </div>
    </div>
    <e-circular-loading class="py-10" :value="schoolPackageListBusy" />
    <no-data-block
      v-if="!schoolPackageListBusy && !schoolPackageList.length"
      icon="icon-money-bill"
      title="No packages added!"
      description="Click add package to enable a package for this school."
    />
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import PackageCard from './PackageCard.vue'
import NoDataBlock from '@/components/NoDataBlock'
import PageContainer from '@/components/PageContainer'
import AddPackageFirstStepModal from '@/views/data/schools/modals/AddPackageFirstStepModal.vue'

export default {
  components: {
    PageContainer,
    NoDataBlock,
    PackageCard,
  },
  computed: {
    ...mapState('school', ['schoolPackageList', 'schoolPackageListBusy', 'updateSchoolPackageListSuccess']),
    ...mapState('schoolPackages', ['packageList', 'packageListBusy']),
    // ...mapState('schoolPackages', ['packageFeaturesList', 'featureList', 'packageFeaturesBusy']),
  },
  created() {
    this.schoolId = this.$route.params.id
    this.$store.dispatch('school/getSchoolPackageList', { schoolId: this.schoolId })
    this.$store.dispatch('schoolPackages/getPackageList')
  },
  methods: {
    showAddPackageFirstStepModal() {
      this.$eDialog({
        width: 424,
        component: AddPackageFirstStepModal,
        componentProps: {
          schoolId: this.schoolId,
          // packageList: this.packageList,

        }
      })

    },
  },
}
</script>

<template>
  <page-container title="Payment & payers">
    <template #actions>
      <e-button large variant="primary" @click="showAddPaymentModal"> Add payment </e-button>
    </template>
    <div>
      <div class="row gutter-2">
        <div v-for="payment in schoolPayments" :key="payment.id" class="col-12 pa-2 col-sm-6 overflow-hidden">
          <payment-card
            :paymentName="payment.payment_method"
            :paymentMethodId="payment.payment_method_id"
            :payer="payment.payer"
            :price="payment.fee"
            :customerId="payment.customer_id"
            :id="payment.id"
          />
        </div>
      </div>
    </div>
    <e-circular-loading class="py-10" :value="schoolPaymentsBusy" />
    <no-data-block
      v-if="!schoolPaymentsBusy && schoolPayments.length === 0"
      icon="icon-money-bill"
      title="No payments added!"
      description="Click add payment to enable payments for this school."
    />
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import PaymentCard from './PaymentCard.vue'
import AddPayment from '@/views/data/schools/modals/AddPayment.vue'
import NoDataBlock from '@/components/NoDataBlock'
import PageContainer from '@/components/PageContainer'

export default {
  components: {
    PageContainer,
    NoDataBlock,
    PaymentCard,
    AddPayment,
  },
  computed: {
    ...mapState('school', ['schoolPayments', 'schoolPaymentsBusy', 'errorMessages']),
  },
  methods: {
    showAddPaymentModal() {
      this.$eDialog({
        component: AddPayment,
      })
    }
  },
  async created() {
    let schoolId = this.$route.params.id
    this.$store.dispatch('school/getSchoolPayments', { id: schoolId })
  },
}
</script>

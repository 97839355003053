<template>
  <div class="d-flex flex-wrap flex-md-nowrap">
    <school-info-card class="flex-grow-1 flex-md-grow-0"></school-info-card>
    <div class="flex ml-md-5 mt-8 mt-md-0">
      <div class="overflow-x-hidden pb-6">
        <e-tabs grow small :tabs="tabs" :value="tab" @change="newTab => tab = newTab" />
      </div>
      <administrators v-if="tab === 'administrators'" />
      <headmasters v-if="tab === 'headmasters'" />
      <payments v-if="tab === 'payments'" />
      <study-year-history v-if="tab === 'years'" />
      <statistics v-if="tab === 'statistics'" />
      <packages v-if="tab === 'packages'" />
      <grade-logs v-if="tab === 'logs'" />
    </div>
  </div>
</template>

<script>
import SchoolInfoCard from './SchoolInfoCard.vue'
import Administrators from './administrators/Administrators.vue'
import Headmasters from './headmasters/Headmasters.vue'
import Payments from './payments/Payments.vue'
import StudyYearHistory from './study-year-history/StudyYearHistory.vue'
import Statistics from './statistics/Statistics.vue'
import Packages from './packages/Packages.vue'
import GradeLogs from './GradeLogs.vue'
export default {
  components: {
    SchoolInfoCard,
    Administrators,
    Headmasters,
    Payments,
    StudyYearHistory,
    Statistics,
    Packages,
    GradeLogs,
  },
  data() {
    return {
      tab: 'administrators',
      tabs: [
        { id: 'administrators', name: 'Administration' },
        { id: 'headmasters', name: 'Headmasters' },
        { id: 'payments', name: 'Payments' },
        { id: 'years', name: 'Study year history' },
        { id: 'statistics', name: 'Statistics' },
        { id: 'packages', name: 'Packages' },
        { id: 'logs', name: 'Grade logs' }
      ],
      list: undefined,
    }
  },
  methods: {
    loadSchoolDetails() {
      let schoolId = this.$route.params.id
      const actionList = ['school/getSchoolMainData', 'school/getSchoolActiveRoles', 'school/getSchoolAdministrators']
      actionList.forEach((action) => {
        this.$store.dispatch(action, { id: schoolId })
      })
    },
  },
  watch: {
    '$route.params': {
      handler() {
        this.loadSchoolDetails()
      },
    },
  },
  created() {
    this.loadSchoolDetails()
  },
}
</script>

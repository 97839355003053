import Logs from '@/views/logs/Logs.vue'
const logs = [
  {
    path: '/logs',
    name: 'logs',
    component:Logs ,
    meta: {
      layout: 'content',
    },
  },
]

export default logs

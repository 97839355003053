import * as api from '../../api'
import * as types from '../mutation-types'

export default {
  state: {
    loginMethodChartBusy: false,
    tableData: [],
    dates: [],
    loginMethodsData: [],
  },
  mutations: {
    [types.GET_USER_LOGIN_METHODS_CHART_DATA](state) {
      state.loginMethodChartBusy = true
    },
    [types.GET_USER_LOGIN_METHODS_CHART_DATA_SUCCESS](state, response) {
      state.loginMethodChartBusy = false
      let data = response.data.data
      state.dates = []
      let transformedData = []
      data.forEach((date) => {
        state.dates.push(date.name)
        date.types.forEach((typ) => {
          if (transformedData.find((overallItem) => overallItem?.type === typ.name)) {
            transformedData = transformedData.map((item) => {
              if (item.type === typ.name) {
                return {
                  ...item,
                  [date.name]: typ.count_all,
                }
              }
              return item
            })
          } else {
            transformedData.push({
              type: typ.name,
              [date.name]: typ.count_all,
            })
          }
        })
      })

      state.loginMethodsData = transformedData.map((item) => {
        let totalInPeriod = 0
        Object.values(item).forEach((it) => {
          totalInPeriod += it >= 0 ? it : 0
        })
        return {
          ...item,
          totalInPeriod,
        }
      })
    },
    [types.GET_USER_LOGIN_METHODS_CHART_DATA_FAIL](state) {
      state.loginMethodChartBusy = false
    },
  },
  actions: {
    getLoginMethodChartData: (store, { dateFrom, dateTo }) => {
      store.commit(types.GET_USER_LOGIN_METHODS_CHART_DATA)
      return api.getLoginMethodsChartData(dateFrom, dateTo).then(
        (response) => store.commit(types.GET_USER_LOGIN_METHODS_CHART_DATA_SUCCESS, response),
        (error) => store.commit(types.GET_USER_LOGIN_METHODS_CHART_DATA_FAIL, error)
      )
    },
  },
  getters: {
    loginMethodsDates: (state) => state.dates,
    loginMethodsData: (state) => state.loginMethodsData,
    loginMethodChartBusy: (state) => state.loginMethodChartBusy,
  },
}

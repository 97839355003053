<template>
  <v-list-item
    v-if="canViewVerticalNavMenuLink(item)"
    v-bind="linkProps"
    :class="{ 'primary300 whitebase--text': isActive }"
    class="vertical-nav-menu-link"
    active-class="primary300 whitebase--text"
  >
    <v-list-item-icon>
      <e-icon :class="{ 'alternate-icon-small': !item.icon }" class="grayscale600--text">
        {{ item.icon || alternateIcon }}
      </e-icon>
    </v-list-item-icon>

    <v-list-item-title :class="{ 'white--text': isActive }">
      {{ t(item.title) }}
    </v-list-item-title>
    <v-list-item-action v-if="item.badge" class="flex-shrink-0">
      <v-badge :color="item.badgeColor" inline :content="item.badge" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import useVerticalNavLink from '@core/layouts/composable/vertical-nav/useVerticalNavLink'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps } = useVerticalNavLink(props.item)
    const { t } = useUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      alternateIcon: themeConfig.menu.groupChildIcon,

      // i18n
      t,

      // ACL
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  border-radius: 6px;
  overflow: hidden;
  &.v-list-item--active {
    @include elevationTransition();
  }
}
.theme--light.vertical-nav-menu-items .v-list-item.v-list-item--active:not(.v-list-group__header){
  color:white !important;
}
.v-list .v-list-item--active:not(.v-list-group__header) .v-icon {
  color:white !important;
}
</style>

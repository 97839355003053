<template>
  <page-container title="Roles" class="test1">
    <roles-table></roles-table>
    <!--    <div class="section-style">-->
    <!--      <parents-table></parents-table>-->
    <!--    </div>-->
  </page-container>
</template>

<script>
// import ParentsTable from "./ParentsTable.vue";
import RolesTable from './RolesTable.vue'
import PageContainer from '@/components/PageContainer'
export default {
  components: {
    PageContainer,
    // ParentsTable,
    RolesTable,
  },
}
</script>

<style scoped>
.test1 {
  height: auto !important;
}
</style>

<template>
  <div>
    <div class="d-flex pb-3 pb-sm-5">
      <h3 class="graybase--text">
        <slot name="title"/>
      </h3>
      <v-spacer />
      <div class="d-flex">
        <slot name="actions"/>
      </div>
    </div>
    <div class="d-flex justify-space-between flex-wrap-reverse pb-3 pb-sm-5 align-center">
      <div class="my-1">
        <div class="grayscale50 pa-1 border-radius">
          <slot name="range" />
        </div>
      </div>
      <div class="flex d-flex justify-end my-1">
        <slot name="range-picker"/>
      </div>
    </div>
    <slot />
    <div class="pt-8">
      <div class="d-flex">
        <div class="main-table pr-2">
          <slot name="main-table"/>
        </div>

        <div class="total-table">
          <slot name="total-table"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartPageTemplate'
}
</script>

<style lang="scss" scoped>
.main-table {
  width: calc(100% - 140px);
}
.total-table {
  width: 140px;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .main-table {
    width: calc(100% - 60px);
  }
  .total-table {
    width: 60px;
  }
}
</style>

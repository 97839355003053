<template>
  <page-container title="Administrators">
    <e-data-table
      :headers="headers"
      :items="!schoolAdministratorsBusy ? schoolAdministrators : []"
      :loading="schoolAdministratorsBusy"
      item-key="id"
      class="clickable"
    >
      <template v-slot:[`item.firstname`]="{ item }">
        <div class="info_about_user">
         <e-tooltip top v-if="item.verified_at">
            <template #activator="{ on, attrs }">
                <div class="badge-icon" v-bind="attrs"
                v-on="on">
              <badge-icon-verified class="mr-3" />
              </div>
            </template>
          Identity verified via {{item.verified_with}} 
          </e-tooltip>
             <div v-else class="badge-icon">
             <badge-icon-not-verified  class="mr-3" />
             </div>
        <a @click.prevent="handleClick(item)" class="table-link">{{ item.firstname }} {{ item.lastname }} </a>
        </div>
      </template>
        <div slot-scope="{ item }" class="d-flex align-center" slot="item.email">
  <div class="mr-2">{{ item.email }}</div>
   <div v-if="item.email">
  <e-chip prepend-icon="icon-checkmark" variant="success" small v-if="item.email_verified_at">Verified</e-chip>
   <e-chip variant="warning" small v-else>Not verified</e-chip>
   </div>    
        </div>
 <div slot-scope="{ item }" class="d-flex align-center" slot="item.phone_number">
  <div class="mr-2">{{ item.phone_number }}</div>
  <div v-if="item.phone_number">
  <e-chip prepend-icon="icon-checkmark" variant="success" small v-if="item.phone_verified_at">Verified</e-chip>
  <e-chip variant="warning" small v-else>Not verified</e-chip>
  </div>          
 </div>
 <template v-slot:[`item.active`]="{ item }">
            <div v-if="item.active==true" class="active_account">Activated</div>
            <div v-else class="not_active_account">Not activated</div>      
</template>
      <template v-slot:no-data>
        <no-data-block icon="icon-person-with-plus" title="No administrators added!" />
      </template>
    </e-data-table>
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import NoDataBlock from '@/components/NoDataBlock'
import PageContainer from '@/components/PageContainer'
import BadgeIconNotVerified from '@/components/BadgeIconNotVerified.vue'
import BadgeIconVerified from '@/components/BadgeIconVerified.vue'
export default {
  components: { PageContainer, NoDataBlock,BadgeIconNotVerified,BadgeIconVerified },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'firstname',
        },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone_number' },
        { text: 'Account status', value: 'active' },
        { text: 'Person ID', value: 'person_id' },
      ],
    }
  },
  computed: {
    ...mapState('school', ['schoolAdministrators', 'schoolAdministratorsBusy']),
  },
  methods: {
    handleClick(administrator) {
      this.$router.push('/data/users/' + administrator.person_id + '/detailed')
    },
  },
}
</script>

<style lang="scss">
.not_active_account{
color:rgba(228, 63, 57, 1)
}
.active_account{
color:rgba(76, 166, 104, 1)
}
.badge-icon{
  display: flex;
 align-items: flex-start;
 margin-top: 2px;
}
.info_about_user{
  display:flex
}
</style>
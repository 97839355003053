<template>
  <!-- <transition name="slide-fade"> -->
  <page-container v-show="!userBusy" class="user-info-card" flat>
    <!-- Header -->
    <info-card-section>
      <div class="d-flex align-center">
        <div class="verify_avatar">
        <e-avatar :letters="userInitials" />
             <e-tooltip top v-if="user.verified_at">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs"
                v-on="on" class="verify_badge" >
                 <badge-icon-verified />
              </div>
            </template>
          Verified account
          </e-tooltip>
        </div>
        <div class="ml-4 overflow-hidden">
          <div class="callout-2">
            {{ (listData1[0] || '') + ' ' + (listData1[1] || '') }}
          </div>
          <div class="body-3 grayscale500--text">
            <copy-span>{{ user.id }}</copy-span>
          </div>
        </div>
        <v-spacer />
        <div class="pl-2">
          <e-tooltip bottom>
            <template #activator="{ on, attrs }">
              <e-button
                icon="icon-arrow-door-right"
                medium
                variant="ghost"
                v-bind="attrs"
                v-on="on"
                @click="impersonateUser"
              ></e-button>
            </template>
            Impersonate user
          </e-tooltip>
        </div>
      </div>
    </info-card-section>
    <e-divider class="grayscale200" />
    <!-- verification details -->
    <info-card-section>
      <div class="verify_section_item mb-3">
      <div class="body-3 graybase--text li-left">Identity verified via</div>
      <div class="body-3 grayscale500--text">
        <div v-if="user.verified_with">
        {{user.verified_with}}
        </div>
        <div v-else>Not verified</div>
        </div>
      </div>
      <div class="verify_section_item">
      <div class="body-3 graybase--text li-left">Account status</div>
      <div class="body-3 grayscale500--text">
        <div v-if="user.active" class="active_account">
        Activated
        </div>
        <div v-else class="not_active_account">Not activated</div>
      </div>
      </div>
    </info-card-section>
     <e-divider class="grayscale200" />
    <!-- Details  -->
    <info-card-section title="Details">
      <template #actions>
          <e-button icon="icon-pen" medium variant="ghost" @click="showEditUserInfoModal" />
      </template>
      <div class="d-flex body-3">
        <!--        <div v-for="(item, idx) in listLabel1">-->
        <!--          <div class="d-flex overflow-x-hidden">-->
        <!--            <div class="li-left">{{item}}</div>-->
        <!--            <div class="text-truncate">{{listData1[idx]}}</div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div>
          <ul class="graybase--text pl-0" v-for="item in listLabel1" :key="item">
            <li class="li-left" v-if="important === item">
              {{ item }}
              <e-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">icon-warning-hint-filled</v-icon>
                </template>
                <span>Issued by the country</span>
              </e-tooltip>
            </li>
            <li v-else class="li-left">{{ item }}</li>
          </ul>
        </div>
        <div>
          <ul class="grayscale500--text pl-0" v-for="(item, index) in listData1" :key="item + '_' + index">
            <li v-if="item === '' || !item" class="warning--text">Not set </li>
            <li v-else-if="item === true">Set</li>
            <li v-else-if="index > 5" class="grayscale500--text"
              ><copy-span>{{ setDateFormat(item) }}</copy-span>
            </li>
            <li v-else
              ><copy-span>{{ item }}</copy-span></li
            >
          </ul>
        </div>
      </div>
       <div class="email_phone_verification">  
        <div class="email_phone_verification_item mb-4">
         <div class="graybase--text body-3 li-left">
              {{user.email_verified_at ? ' Email verified' : ' Email not verified' }}
              </div>
                    <e-chip
        size="large"
        variant="actionable"
        v-if="!user.email_verified_at" @click="verifyEmail"
         prepend-icon="icon-pen"
      >
        Verify
      </e-chip>
                    <e-chip
        size="large"
        variant="actionable"
        v-else @click="unVerifyEmail"
         prepend-icon="icon-pen"
      >
        Un-verify
      </e-chip>
              <!-- <e-button v-if="!user.email_verified_at" @click="verifyEmail" small variant="primary">Verify</e-button>
              <e-button v-else @click="unVerifyEmail" small variant="primary">Un-verify</e-button> -->
              </div>
              <div class="email_phone_verification_item">
            <div class="graybase--text body-3 li-left">
              {{user.phone_verified_at ? ' Phone verified' : ' Phone not verified' }} 
              </div>
                             <e-chip
                              prepend-icon="icon-pen"
        size="large"
        variant="actionable"
        v-if="!user.phone_verified_at" @click="verifyPhone"
      >
        Verify
      </e-chip>
                    <e-chip
                    prepend-icon="icon-pen"
        size="large"
        variant="actionable"
        v-else @click="unVerifyPhone"
      >
        Un-verify
      </e-chip>                 
              </div>
       </div>
    </info-card-section>

    <!-- Left info about user _ list -->
    <e-divider class="grayscale200" />

    <info-card-section title="Contact data">
      <div class="body-3">
        <!-- data -->
        <div>
          <!-- phone -->
          <div class="phone_section">
  <div class="li-left">Phone number</div>
  <div v-if="listPhone">
    <ul class="grayscale500--text pl-0">
      <li class="contact_info_details">
        {{ listPhone || 'Not set' }} 
      </li>
       <e-chip prepend-icon="icon-checkmark" variant="success" small v-if="user.phone_verified_at" class="mb-3">Verified</e-chip>
        <e-chip variant="warning" small v-else class="mb-3">Not verified</e-chip>
    </ul>
  </div>
  <div v-else>
    <div class="warning--text mb-3">Not set</div>
  </div>
</div>
          <!-- emails -->
        <div class="phone_section">
<div class="li-left">Additional emails</div>
  <div v-if="listEmails">
    <ul class="grayscale500--text pl-0">
      <li class="contact_info_details">
        {{ listEmails || 'Not set' }} 
      </li>
      <e-chip prepend-icon="icon-checkmark" variant="success" small v-if="user.email_verified_at">Verified</e-chip>
        <e-chip variant="warning"  small v-else>Not verified</e-chip> 
    </ul>
  </div>
  <div v-else>
    <div class="warning--text mb-3">Not set</div>
  </div>
</div>

        </div>
      </div>
    </info-card-section>
    <e-divider class="grayscale200" />

    <info-card-section>
      <e-button block large variant="destroy" @click="showDeleteUserModal"> Delete user </e-button>
    </info-card-section>
  </page-container>
  <!-- </transition> -->
</template>

<script>
import moment from 'moment'
import CopySpan from '@/components/CopySpan'
import PageContainer from '@/components/PageContainer'
import { required } from '@core/utils/validation'
import InfoCardSection from '@/components/InfoCardSection'
import EditUserInfoModal from '@/views/data/users/user-details/modals/EditUserInfoModal.vue'
import BadgeIconNotVerified from '@/components/BadgeIconNotVerified.vue'
import BadgeIconVerified from '@/components/BadgeIconVerified.vue'
export default {
  components: { InfoCardSection, PageContainer, CopySpan,BadgeIconNotVerified,BadgeIconVerified  },
  data() {
    return {
      password: true,
      important: 'Personal code',
      listLabel1: [
        'First Name',
        'Last Name',
        'Personal code',
        'Country',
        'Username',
        'Password',
        'Created on',
        'Last desktop login',
        'Last app login',
      ],
      listLabel2: ['Phone number', '', 'Additional emails'],
      user: {},
      listData1: [],
      listPhone: '',
      listEmails: '',
      countryName: '',
      userInitials: '',
      match: true,
      overlay: false,
    }
  },
  computed: {
    userBusy() {
      return this.$store.getters.userInfoBusy
    },
    impersonateUserFail: {
      get() {
        return this.$store.getters.impersonateUserFail
      },
      set(value) {
        this.$store.commit('updateImpersonateUserFail', value)
      },
    },
  },
  watch: {
    '$route.params': {
      handler() {
        let page = 1
        let perPage = 50
        let userId = this.$route.params.id
        this.getUserInfo()
        this.$store.dispatch('getUserRoles', { userId, page, perPage })
        this.$store.dispatch('getUserServices', userId)
      },
    },
  },
  methods: {
    verifyEmail() {
    this.$eDialog({
    preset: 'dialog',
    presetProps: {
      title: 'Are you sure you want to make changes?',
      closeButtonLabel: 'Cancel',
      submitButtonLabel: 'Yes, change',
    },
    presetEvents: {
      'click:submit': async () => {
        const userId = this.$route.params.id;
        await this.$store.dispatch('validateEmail', { userId }); 
        await this.getUserInfo(); 
      },
    }
  });
    },
    unVerifyEmail() {
          this.$eDialog({
    preset: 'dialog',
    presetProps: {
      title: 'Are you sure you want to make changes?',
      closeButtonLabel: 'Cancel',
      submitButtonLabel: 'Yes, change',
    },
    presetEvents: {
      'click:submit': async () => {
        const userId = this.$route.params.id;
          await this.$store.dispatch('invalidateEmail', { userId }); 
          await this.getUserInfo(); 
      },
    }
  });
    },
    verifyPhone() {
       this.$eDialog({
    preset: 'dialog',
    presetProps: {
      title: 'Are you sure you want to make changes?',
      closeButtonLabel: 'Cancel',
      submitButtonLabel: 'Yes, change',
    },
    presetEvents: {
      'click:submit': async () => {
        const userId = this.$route.params.id;
          await this.$store.dispatch('validatePhone', { userId }); 
          await this.getUserInfo();     
      },
    }
  });
    },
    unVerifyPhone() {
             this.$eDialog({
    preset: 'dialog',
    presetProps: {
      title: 'Are you sure you want to make changes?',
      closeButtonLabel: 'Cancel',
      submitButtonLabel: 'Yes, change',
    },
    presetEvents: {
      'click:submit': async () => {
        const userId = this.$route.params.id;
          await this.$store.dispatch('invalidatePhone', { userId }); 
          await this.getUserInfo();     
      },
    }
  });
    },

//        showChangePhoneVerificationModal() {
//   const originalPhoneVerificationStatus = this.phoneVerificationStatus;
//   this.$eDialog({
//     preset: 'dialog',
//     presetProps: {
//       title: 'Are you sure you want to make changes?',
//       closeButtonLabel: 'Cancel',
//       submitButtonLabel: 'Yes, change',
//     },
//     presetEvents: {
//       'click:submit': async () => {
//         const userId = this.$route.params.id;
//         try {
//           if (this.phoneVerificationStatus) {
//             await this.$store.dispatch('invalidatePhone', { userId });
//           } else {
//             await this.$store.dispatch('validatePhone', { userId });
//           }
//           await this.getUserInfo(); 
//         } catch (error) {
//           console.error('Failed to update phone verification status:', error);
//         }
//       },
//       'click:close': () => {
//         this.phoneVerificationStatus = originalPhoneVerificationStatus;
//         this.$forceUpdate();
//       },
//     }
//   });
// },
//     showChangeEmailVerificationModal() {
//   const originalEmailVerificationStatus = this.emailVerificationStatus;
//   this.$eDialog({
//     preset: 'dialog',
//     presetProps: {
//       title: 'Are you sure you want to make changes?',
//       closeButtonLabel: 'Cancel',
//       submitButtonLabel: 'Yes, change',
//     },
//     presetEvents: {
//       'click:submit': async () => {
//         const userId = this.$route.params.id;
//         try {
//           if (this.emailVerificationStatus) {
//             await this.$store.dispatch('invalidateEmail', { userId });
//           } else {
//             await this.$store.dispatch('validateEmail', { userId });
//           }
//           await this.getUserInfo();
//         } catch (error) {
//           console.error('Failed to update email verification status:', error);
//         }
//       },
//       'click:close': () => {
//         this.emailVerificationStatus = originalEmailVerificationStatus;
//         this.$forceUpdate();
//       },
//     }
//   });
// },
    showDeleteUserModal() {
      this.$eDialog({
        preset: 'dialog',
        presetProps: {
          title: 'Are you sure you want to continue?',
          description: `You are about to delete ${this.user.name1} ${this.user.name2} from the user list, this action is irreversible.`,
          closeButtonLabel: 'Cancel',
          deleteButtonLabel: 'Delete',
        },
        presetEvents: {
          'click:delete': async () => {
            return await this.deleteUser()
          }
        }
      })
    },
    async showEditUserInfoModal() {
      const isDataChanged = await this.$eDialog({
        width: 424,
        component: EditUserInfoModal,
        componentProps: {
          userData: this.user,
        }
      })
      if (isDataChanged) {
        this.getUserInfo()
      }
    },
    impersonateUser() {
      let userId = this.$route.params.id
      this.$store.dispatch('impersonateUser', { userId })
    },
    setDateFormat(value) {
      return moment(value, 'DD/MM/YYYY HH:mm').format('DD.MM.Y HH:ss')
    },
    async getUserInfo() {
      let userId = this.$route.params.id
      await this.$store.dispatch('getUserInfo', { userId })
      const data = this.$store.getters.userInfo
      this.user = { ...data }
      this.setCountryName()
      this.listData1 = [
        this.user.name1,
        this.user.name2,
        this.user.id_code,
        this.countryName,
        this.user.user_name,
        this.user.password_set,
        this.user.created,
        this.user.last_desktop_login,
        this.user.last_mobile_application_login,
      ]
      this.userInitials = (this.listData1[0][0] + this.listData1[1][0]).toUpperCase()
     this.listPhone = this.user.phone_number || '';
      this.listEmails =this.user.email || ''
    },

    async deleteUser() {
      try {
        let userId = this.$route.params.id
        await this.$store.dispatch('deleteUser', userId)
        return true
      } catch (e) {
        return false
      }
    },
    setCountryName() {
      let result = this.$store.getters.countries.find((country) => country.id === this.user.country_id)
      this.countryName = result?.name || ''
    },
  },
  async created() {
    this.getUserInfo()
    this.$store.dispatch('getCountries')
  },
}
</script>

<style lang="scss" scoped>
.email_phone_verification_item{
  display: flex;
  align-items: center;
}
.contact_info_details{
  padding-bottom: 0 !important;
}
.phone_section{
  display: flex;
}
.not_active_account{
color:rgba(228, 63, 57, 1)
}
.active_account{
color:rgba(76, 166, 104, 1)
}
li {
  list-style-type: none;
  padding-bottom: 12px;
}
.li-left {
  width: 154px;
  min-width: 154px;
}
.password-block {
  display: flex;
  gap: 16px;
}
.add-phone {
  margin-bottom: 46px;
}

.user-info-card {
  height: fit-content;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .user-info-card {
    min-width: fit-content;
    //min-width: 342px;
    //max-width: 342px;
  }
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.verify_section_item{
  display: flex;
}
.verify_badge{
  position: absolute;
      bottom: 0;
      transform: translate(150%, 50%);
}
.verify_avatar{
  position: relative;
}
</style>

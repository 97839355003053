<template>
  <page-container title="Payment methods">
    <template #actions>
      <e-button large variant="primary" @click="showAddPaymentModal"> Add method </e-button>
<!--      <add-payment-method />-->
    </template>
    <e-circular-loading class="py-10" :value="paymentMethodsBusy" />
    <div class="row gutter-2">
      <div
        class="col-12 pa-2"
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.id"
      >
        <payment-method-card
          :title="paymentMethod.name"
          :date="paymentMethod.created_at"
          :dateUpdate="paymentMethod.updated_at"
          :id="paymentMethod.id"
        />
      </div>
      <no-data-block
        v-if="!paymentMethodsBusy && paymentMethods.length === 0"
        icon="icon-money-bill"
        title="No payment methods added!"
        description="Click add method to create your payment method."
      />
    </div>
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import PaymentMethodCard from './PaymentMethodCard.vue'
import AddPaymentMethod from './modals/AddPaymentModal.vue'
import NoDataBlock from '@/components/NoDataBlock'
import PageContainer from '@/components/PageContainer'
import addPaymentModal from '@/views/premium/paymentMethods/modals/AddPaymentModal.vue'
export default {
  components: {
    PageContainer,
    NoDataBlock,
    PaymentMethodCard,
    AddPaymentMethod,
  },
  created() {
    this.$store.dispatch('payments/getPaymentMethods')
  },
  computed: {
    ...mapState('payments', ['paymentMethods', 'paymentMethodsBusy', 'paymentMethodsFail', 'errorMessages']),
  },
  methods: {
    showAddPaymentModal() {
      this.$eDialog({
        component: addPaymentModal,
      })
    }
  }
}
</script>

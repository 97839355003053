<template>
  <div>
    <e-dialog-header @click:close="close">
      Add feature
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
        <div class="mt-6">
          <e-text-field
            large
            class="body-2 mb-10"
            label="Name"
            v-model="newFeatureName"
            placeholder="e.g. Alarmbell"
            required
            :rules="[validators.required]"
          />
          <e-text-field
            large
            class="body-2 mb-3"
            label="Key"
            v-model="newFeatureKey"
            placeholder="e.g. alarmbell-feature"
            required
            :rules="[validators.required]"
          />
        </div>
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="createPackageFeature">Create new feature</e-button>
    </e-dialog-actions>

    <loading-overlay :value="overlay"/>
  </div>
</template>

<script>
import { required } from '@core/utils/validation'
export default {
  data() {
    return {
      valid: false,
      newFeatureName: '',
      newFeatureKey: null,
      open: false,
      open2: false,
      overlay: false,
    }
  },
  computed: {
    validators() {
      return {
        required,
      }
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async createPackageFeature() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      try {
        let data = {
          name: this.newFeatureName,
          feature_key: this.newFeatureKey,
        }
        await this.$store.dispatch('schoolPackages/createPackageFeature', data)
        this.$eToast.success('A feature has been added!')
        this.close()
      } catch (e) {
        this.$eToast.error(e)
      } finally {
        this.overlay = false
      }

    },
  },
}
</script>

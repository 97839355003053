<template>
  <div>
    <e-dialog-header @click:close="$root.$emit('submit')">
      Study year details
    </e-dialog-header>
    <e-dialog-content>
      <div class="main-details">
        <div class="detail">
          <span class="detail-description graybase--text">Name</span>
          <span class="detail-content">{{ year || 'N/A' }}</span>
        </div>
        <div class="detail">
          <span class="detail-description graybase--text">Created</span>
          <span class="detail-content">{{ created || 'N/A' }}</span>
        </div>
        <div class="detail">
          <span class="detail-description graybase--text">Creator</span>
          <span class="detail-content">{{ creator || 'N/A' }}</span>
        </div>
        <div class="detail">
          <span class="detail-description graybase--text">Modified</span>
          <span class="detail-content">{{ date || 'N/A' }}</span>
        </div>
        <div class="detail">
          <span class="detail-description graybase--text">Modifier</span>
          <span class="detail-content">{{ modifier || 'N/A' }}</span>
        </div>
        <div class="detail">
          <span class="detail-description graybase--text">Start date</span>
          <span class="detail-content">{{ startDate || 'N/A' }}</span>
        </div>
        <div class="detail">
          <span class="detail-description graybase--text">End date</span>
          <span class="detail-content">{{ endDate || 'N/A' }}</span>
        </div>
        <div class="detail">
          <span class="detail-description graybase--text">Institution ID</span>
          <span class="detail-content">{{ institutionId || 'N/A' }}</span>
        </div>
        <div class="detail">
          <span class="detail-description graybase--text">Study year status</span>
          <span class="detail-content">{{ status || 'N/A' }}</span>
        </div>
      </div>

    </e-dialog-content>
  </div>
</template>

<script>
export default {
  name: 'StudyYearDetails',
  props: ['year', 'created', 'creator', 'date', 'modifier', 'startDate', 'endDate', 'institutionId', 'status'],
  data() {
    return {
    }
  },
}
</script>

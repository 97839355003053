<template>
  <div>
    <e-dialog-header @click:close="close">
      Add package
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
          <div class="mt-4">
            <e-text-field
              large
              class="body-2 mb-8"
              label="Name"
              v-model="newPackageName"
              placeholder="e.g.Premium"
              required
              :rules="[validators.required]"
            />
            <e-text-field
              large
              class="body-2 mb-3"
              label="Price"
              v-model="newPrice"
              prependInnerIcon="icon-money-bill"
              placeholder="e.g. 32"
              required
              :rules="[validators.required, validators.onlyDigits]"
            />
            <e-hint dismissible title="Price is expressed in Euros (EUR)" />
          </div>
          <div class="enable-package mb-3 mt-7">
            <span class="callout-3 graybase--text">Enable package features</span>
            <v-spacer></v-spacer>
            <e-button variant="link" @click="showAddFeatureModal">Create new feature</e-button>
          </div>
          <ul class="pa-0 ma-0">
            <li class="pa-0 ma-0" v-for="feature in featureList" :key="feature.id">
              <e-switch class="pa-0 ma-0 red--text" :label="feature.name" :value="feature.id" v-model="checkedFeatures" />
            </li>
          </ul>
      </v-form>

    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="createPackage">Create new package</e-button>

    </e-dialog-actions>

    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from '@core/utils/validation'
import FeatureAdd from './FeatureAdd.vue'
export default {
  components: { FeatureAdd },
  data() {
    return {
      valid: false,
      newPackageName: '',
      newPrice: null,
      open: false,
      checkedFeatures: [],
      activePackageFeatures: [],
      overlay: false,
    }
  },
  computed: {
    ...mapState('schoolPackages', ['featureList', 'packageList', 'errorMessage']),
    validators() {
      return {
        required,
        onlyDigits: (value) => {
          var regex = /^\d+([\.\,]\d{0,2})?$/g
          if (!regex.test(value)) {
            return 'The price must be a number'
          } else return true
        },
      }
    },
  },
  watch: {
    checkedFeatures() {
      this.setActivePackageFeatures(this.checkedFeatures)
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    showAddFeatureModal() {
        this.$eDialog({
          width: 424,
          component: FeatureAdd,
        })
    },
    setActivePackageFeatures(activeFeatures) {
      this.activePackageFeatures = activeFeatures
    },
    async createPackage() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      let data = {
        name: this.newPackageName,
        price: this.newPrice,
      }
      await this.$store.dispatch('schoolPackages/createPackage', data).then(
        (response) => {
          this.overlay = false
          this.savePackageFeatures(response.id)
        },
        (err) => {
          this.overlay = false
          this.$eToast.error(err)
          // console.error(err)
        }
      )
    },
    async savePackageFeatures(id) {
      this.overlay = true
      let store = this.activePackageFeatures
      const data = {
        store,
        delete: [],
      }

      await this.$store
        .dispatch('schoolPackages/updatePackageFeatureList', {
          packageId: id,
          featureList: this.activePackageFeatures,
          data,
        })
        .then(
          () => {
            this.$eToast.success('A new package was added!')
            this.overlay = false
            this.close()
          },
          (err) => {
            this.$eToast.error('Package features was not added!')
            this.overlay = false
            console.error(err)
          }
        )
    },
  },
}
</script>

<style scoped>
.enable-package {
  display: flex;
  align-items: center;
}
li {
  list-style-type: none;
}
</style>

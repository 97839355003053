export default [
  {
    subheader: 'logs_sub',
  },
  {
    title: 'User logs',
    icon: 'icon-hard-drive',
    to: 'logs',
  },
]

<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot />

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="navbar-content-container" style="height: 52px" :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="me-3" @click="toggleVerticalNavMenuActive">
            {{ icons.mdiMenu }}
          </v-icon>
          <app-bar-search
            :loading="searchBusy"
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="searchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="
              handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)
            "
          ></app-bar-search>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'
import _ from 'lodash'
// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'
import { mdiMenu, mdiHeartOutline } from '@mdi/js'
import { getUsers, getSchools, getLocalGovernments } from '@/api'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,
  },
  data() {
    return {
      navMenuItems,
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
      maxItemsInGroup: 5,
      shallShowFullSearch: false,
      appBarSearchQuery: '',
      searchBusy: false,
      debouncedSearch: _.debounce(() => {
        this.fetchResults()
      }, 1000),
      baseSearchData: [],
    }
  },
  watch: {
    appBarSearchQuery: {
      handler() {
        this.baseSearchData = []
        if (this.appBarSearchQuery) {
          this.searchBusy = true
          this.debouncedSearch()
        }
      },
    },
  },
  methods: {
    async fetchResults() {
      const usersRes = getUsers(1, 5, this.appBarSearchQuery)
      const schoolsRes = getSchools(1, 5, this.appBarSearchQuery)
      const governmentsRes = getLocalGovernments()

      await Promise.all([usersRes, schoolsRes, governmentsRes]).then((value) => {

        const users = value[0]?.data?.data?.data || []
        const schools = value[1]?.data?.data?.data || []
        const governments = value[2]?.data?.data || []

        const usersTransformed = users.map((u) => {
          return {
            title: `${u.name1} ${u.name2} · User ID: ${u.id}`,
            to: `/data/users/${u.id}/detailed`,
            icon: 'icon-person',
          }
        })
        const schoolsTransformed = schools.map((s) => {
          return {
            title: `${s.name} · School ID: ${s.id}`,
            to: `/data/schools/${s.id}/detailed`,
            icon: 'icon-school-building',
          }
        })
        const governmentsTransformed = governments
          .filter(
            (gov) =>
              gov.id.toString().includes(this.appBarSearchQuery) ||
              gov.name.toLowerCase().includes(this.appBarSearchQuery.toLowerCase())
          )
          .map((g) => {
            return {
              title: `${g.name} · Ekool ID: ${g.id}`,
              to: `/data/governments/${g.id}/detailed`,
              icon: 'icon-institution',
            }
          })

        if (usersTransformed.length) {
          usersTransformed.unshift({ subheader: 'users' })
          if (schoolsTransformed.length || governmentsTransformed.length) {
            usersTransformed.push({ divider: true })
          }
        }

        if (schoolsTransformed.length) {
          schoolsTransformed.unshift({ subheader: 'schools' })

          if (governmentsTransformed.length) {
            schoolsTransformed.push({ divider: true })
          }
        }
        if (governmentsTransformed.length) {
          governmentsTransformed.unshift({ subheader: 'local_governments' })
        }

        this.baseSearchData = [...usersTransformed, ...schoolsTransformed, ...governmentsTransformed]
        this.searchBusy = false
      })
    },
    searchFilterFunc(item, queryText, itemText) {
      return true
    },
    handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive) {
      this.baseSearchData = []
      if (this.$vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    },
  },
  computed: {
    searchData() {
      return _.flatten(
        this.baseSearchData.map((v) => {
          if (v.subheader) {
            return {
              header: this.$t(v.subheader),
            }
          }
          // if (v.children) {
          //   return v.children.map((vChild) => {
          //     return {
          //       ...vChild,
          //       title: `${this.$t(v.title)} / ${this.$t(vChild.title)}`
          //     }
          //   })
          // }
          if (v.title) {
            return {
              ...v,
            }
          }
          return v
        })
      )
    },
  },
}

// export default {
//   setup() {
//     const $vuetify = getVuetify()
//     const { t } = useUtils()
//     // Search

//
//     // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
//
//     return {
//       navMenuItems,
//       handleShallShowFullSearchUpdate,
//
//       // Search
//       appBarSearchQuery,
//       shallShowFullSearch,
//       appBarSearchData: data,
//       searchFilterFunc,
//
//       icons: {
//         mdiMenu,
//         mdiHeartOutline,
//       },
//     }
//   },
// }
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>

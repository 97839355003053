<template>
  <page-container>
    <user-logins-chart />
  </page-container>
</template>

<script>
import UserLoginsChart from '@/views/statistics/logins/user-logins/UserLoginsChart'
import PageContainer from '@/components/PageContainer'
export default {
  components: {
    PageContainer,
    UserLoginsChart,
  },
}
</script>

<template>
  <page-container title="Packages & features">
    <div class="mb-sm-5 ma-0 d-flex align-center flex-column flex-sm-row justify-center justify-sm-space-between">
      <div class="mb-sm-0">
        <div class="grayscale50 pa-1 border-radius">
          <v-btn-toggle v-model="toggle" mandatory class="grayscale50 align-center">
            <div class="mr-2">
              <e-button class="toggle-btn" variant="ghost">Packages</e-button>
            </div>
            <div>
              <e-button class="toggle-btn" variant="ghost">Features</e-button>
            </div>
          </v-btn-toggle>
        </div>
      </div>
      <div>
        <e-button large variant="primary" @click="selectedComponent === 'packages' ? showAddPackageModal() : showAddFeatureModal()"> {{selectedComponent === 'packages' ? 'Create new package' : 'Create new features' }}</e-button>
      </div>
    </div>
    <component :is="selectedComponent"></component>
  </page-container>
</template>

<script>
import Packages from './Packages.vue'
import Features from './Features.vue'
import PackageAdd from './modals/PackageAdd.vue'
import FeatureAdd from './modals/FeatureAdd.vue'
import PageContainer from '@/components/PageContainer'
export default {
  components: { PageContainer, Packages, Features, PackageAdd, FeatureAdd },
  data() {
    return {
      selectedComponent: 'packages',
      title: '',
      mdName: '',
      toggle: 0,
    }
  },
  watch: {
    toggle: {
      handler(val) {
        const components = {
          0: 'packages',
          1: 'features',
        }
        this.selectedComponent = components[val]
      },
    },
  },
  methods: {
    showAddPackageModal() {
      this.$eDialog({
        width: 424,
        component: PackageAdd,
      })
    },
    showAddFeatureModal() {
      this.$eDialog({
        width: 424,
        component: FeatureAdd,
      })
    }
  },
  async created() {
    await this.$store.dispatch('schoolPackages/getPackageList')
    await this.$store.dispatch('schoolPackages/getFeatureList')
    await this.$store.dispatch('schoolPackages/getFeaturesForEachPackage')
  },
}
</script>
<style scoped>
.v-btn-toggle {
  height: 36px !important;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 36px !important;
}
.v-application .pa-1 {
  padding: 4px !important;
}
</style>

<template>
  <page-container>
    <reactions-chart />
  </page-container>
</template>

<script>
import PageContainer from '@/components/PageContainer'
import ReactionsChart from '@/views/statistics/usage/reactions/ReactionsChart'
export default {
  components: {
    ReactionsChart,
    PageContainer,
  },
}
</script>

<template>
  <StatisticsCard title="RIKS notifications" icon="icon-exclamation">
    <StatisticsCardField description="Count" :value="schoolRiksStatistics['count']" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  components: {StatisticsCardField, StatisticsCard},
  data() {
    return {
      cardInfo: {
        id: 1,
        icon: 'icon-exclamation',
        title: 'RIKS notifications',
      },
    }
  },
  computed: {
    ...mapState('school', ['schoolRiksStatistics']),
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{attrs:{"title":"Services"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('e-button',{attrs:{"large":"","variant":"primary"},on:{"click":_vm.openServiceLog}},[_vm._v("Logs")])]},proxy:true}])},[_c('e-data-table',{attrs:{"items":_vm.services,"itemsPerPage":5,"itemKey":"id","headers":_vm.headers,"loading":_vm.servicesBusy},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name))]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return _vm._l((item.members),function(member,index){return _c('a',{key:member.id,staticClass:"table-link",on:{"click":function($event){$event.preventDefault();return _vm.editUser(member.id)}}},[_vm._v(_vm._s(member.name)+" "),(index != item.members.length - 1)?_c('span',{staticClass:"no-txt"},[_vm._v(", ")]):_vm._e()])})}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPaymentTypeText(item)))]}},{key:"item.logs",fn:function(ref){
var item = ref.item;
return [(item.logs)?_c('div',[_vm._v("-")]):_c('div',[_c('e-menu',{attrs:{"min-width":"240px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('e-button',_vm._g(_vm._b({attrs:{"small":"","icon":"icon-dots-horizontal","variant":"ghost"}},'e-button',attrs,false),on))]}}],null,true)},[_c('e-menu-list',[_c('e-menu-list-item',{staticClass:"mt-1",attrs:{"prepend-icon":"icon-pie-chart"},on:{"click":function($event){$event.preventDefault();return _vm.openServiceLogItem(item.owner_id)}}},[_vm._v(" View logs ")]),_c('e-menu-list-item',{directives:[{name:"show",rawName:"v-show",value:(item.method=='android' || (item.method=='web'&& item.stripe_customer_id)),expression:"item.method=='android' || (item.method=='web'&& item.stripe_customer_id)"}],attrs:{"prepend-icon":"icon-pen-underline"},on:{"click":function($event){return _vm.openManageSubscription(item)}}},[_vm._v(" Manage subscription ")])],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('no-data-block',{attrs:{"icon":"icon-d-cube","title":"No premium added!","description":"Click add premium to enable premium packages for this user."}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <page-container>
    <login-methods-chart />
  </page-container>
</template>

<script>
import PageContainer from '@/components/PageContainer'
import LoginMethodsChart from '@/views/statistics/logins/login-methods/LoginMethodsChart'
export default {
  components: {
    LoginMethodsChart,
    PageContainer,
  },
}
</script>

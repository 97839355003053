import * as api from '../../api'

export default {
  namespaced: true,
  state: {
    gradeLogsBusy: false,
    gradeLogsSuccess: false,
    gradeLogsFile: {},
    errorMessage: '',
    gradeLogsFail: false,
    fileEmpty: false,
  },
  mutations: {
    getGradeLogsSuccess(state, response) {
      state.gradeLogsBusy = false
      if (response.data.length <= 1) {
        state.fileEmpty = true
        return
      }
      if (response.data.success === false) {
        state.errorMessage = response.data.error
        state.gradeLogsFail = true
        return
      }
      state.gradeLogsSuccess = true
      state.gradeLogsFile = response.data
    },
    getGradeLogsFail(state, response) {
      state.gradeLogsBusy = false
    },
    resetLogData(state) {
      state.gradeLogsBusy = true
      state.gradeLogsSuccess = false
      state.gradeLogsFile = {}
      state.errorMessage = ''
      state.gradeLogsFail = false
      state.fileEmpty = false
    },
  },
  actions: {
    getGradeLogs: (store, { schoolID, studentIDCode, journalName, dateFrom, dateTo }) => {
      store.commit('resetLogData')
      return api.getGradeLogs(schoolID, studentIDCode, journalName, dateFrom, dateTo).then(
        (response) => {
          store.commit('getGradeLogsSuccess', response)
          return Promise.resolve(response)
        },
        (e) => {
          store.commit('getGradeLogsFail', e)
          return Promise.reject(e)
        }
      )
    },
  },
}

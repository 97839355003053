// import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import Vue from 'vue'
// import Vue from 'vue/dist/vue.js'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueEkoolPlugin from '@k12tech/ekool-vue-framework/src/main'
import '@/styles/styles.scss'
import VueCookies from 'vue-cookies'

import LoadingOverlay from '@/components/LoadingOverlay.vue'

import { Auth0Plugin } from '@/auth'
import { getEnv } from '@/env.js'

import VueGtm from '@gtm-support/vue2-gtm'
import { trackGA4clickEvent } from './utils.js'

const domain = getEnv('VUE_APP_FRONTEND_AUTH_DOMAIN')
const clientId = getEnv('VUE_APP_FRONTEND_AUTH_CLIENT_ID')
const audience = getEnv('VUE_APP_FRONTEND_AUTH_AUDIENCE')

// Vue.config.productionTip = false

Vue.config.debug = getEnv('VUE_APP_FRONTEND_ENVIRONMENT') === 'development'
Vue.config.silent = getEnv('VUE_APP_FRONTEND_ENVIRONMENT') !== 'development'
Vue.config.productionTip = getEnv('VUE_APP_FRONTEND_ENVIRONMENT') !== 'development'

const context = {
  vuetify,
  router,
  store,
  i18n,
  VueCookies,
}
Vue.mixin({
  methods: { trackGA4clickEvent },
})
Vue.use(VueGtm, {
  id: getEnv('VUE_APP_FRONTEND_GTM_TAG_ID') && getEnv('VUE_APP_FRONTEND_GTM_TAG_ID') !== '${VUE_APP_FRONTEND_GTM_TAG_ID}' ? getEnv('VUE_APP_FRONTEND_GTM_TAG_ID') : 'GTM-XXXXXX', // 'GTM-XXXXXX' used in case of no VUE_APP_FRONTEND_GTM_TAG_ID provided, so the app doesn't crash
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  VueCookies,
  // organization,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
  },
})
Vue.use(VueEkoolPlugin, { context })

Vue.component('LoadingOverlay', LoadingOverlay)
Vue.use(require('vue-cookies'))
new Vue({
  ...context,
  render: (h) => h(App),
}).$mount('#app')

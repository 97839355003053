<template>
  <div class="no-data-block">
    <div class="align-center">
      <e-icon class="icon-button whitebase--text mt-2 center" size="24">{{ icon }}</e-icon>
    </div>
    <div class="subhead-1 graybase--text pt-3 pt-sm-5 pb-2 center">{{ title }}</div>
    <div v-if="description" class="body-3 grayscale500--text desc-container mb-6">{{description}}</div>
  </div>
</template>

<script>
export default {
  name: 'NoDataBlock',
  props: ['icon', 'title', 'description']
}
</script>

<style scoped>
.no-data-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-button {
  padding: 15px;
  background: #489ffe;
  border-radius: 16px;
}
</style>

<template>
  <div>
    <e-dialog-header @click:close="close">
      Edit payment method
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
        <e-text-field class="pt-5" v-model="paymentName" label="Name" required :rules="[validators.required]" />
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="updatePaymentMethod">Save edit</e-button>
    </e-dialog-actions>

    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from '@core/utils/validation'
export default {
  props: ['id', 'activeMethodName'],
  data() {
    return {
      valid: false,
      paymentName: this.activeMethodName,
      overlay: false,
    }
  },
  computed: {
    ...mapState('payments', ['paymentMethods', 'paymentUpdatedSuccess', 'paymentUpdatedFail', 'errorMessages']),
    validators() {
      return {
        required,
      }
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async updatePaymentMethod() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      try {
        await this.$store.dispatch('payments/updatePaymentMethod', { id: this.id, name: this.paymentName })

        this.$eToast.success('Payment method was successfully edited')
        this.close()
      } catch (e) {
        this.$eToast.error(e)
      } finally {
        this.overlay = false
      }
    },
  },
}
</script>

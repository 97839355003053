import axios from '@/plugins/axios'

// GET REQUESTS
export const getPackageList = () => {
  return axios.get('school-packages')
}
export const getFeatureList = () => {
  return axios.get(`school-package-features`)
}
export const getPackageFeaturesList = (id) => {
  return axios.get(`school-packages/${id}/features`)
}
export const getSchoolPackageList = (schoolId) => {
  return axios.get(`schools/${schoolId}/packages`)
}



// POST REQUESTS
export const createPackage = (name, price) => {
  const data = {
    name,
    price,
  }
  return axios.post('school-packages', data)
}
export const updatePackage = (name, price, packageId) => {
  const data = {
    name,
    price,
  }
  return axios.post(`school-packages/${packageId}`, data)
}
export const createPackageFeature = (name, key) => {
  const data = {
    name,
    feature_key: key,
  }
  return axios.post(`school-package-features`, data)
}
export const updatePackageFeature = (name, key, id) => {
  const data = {
    name,
    feature_key: key,
  }
  return axios.post(`school-package-features/${id}`, data)
}
export const updatePackageFeatureList = (packageId, featureList, data) => {
  return axios.post(`school-packages/${packageId}/features`, data)
}
export const updateSchoolPackageList = (schoolId, packageData) => {
  return axios.post(`schools/${schoolId}/packages`, packageData)
}



// DELETE REQUESTS
export const deletePackage = (id) => {
  return axios.delete(`school-packages/${id}`)
}
export const deletePackageFeature = (id) => {
  return axios.delete(`school-package-features/${id}`)
}
export const deletePackageFeatureList = (packageId, featureId) => {
  return axios.delete(`school-packages/${packageId}/features/${featureId}`)
}
export const deleteSchoolPackage = (schoolId, packageId) => {
  return axios.delete(`schools/${schoolId}/packages/${packageId}`)
}

import { invalid } from 'moment'
import * as api from '../../api'
import * as types from '../mutation-types'
import { getEnv } from '@/env'

export default {
  state: {
    success: false,
    busy: false,
    fail: false,
    options: { page: 1, perPage: 50 },
    search: '',
    searchBy: '',
    filter: '',
    draw: 0,
    users: [],
    response: {},
    impersonateUserFail: false,
    setUserUrl: getEnv('VUE_APP_FRONTEND_LOGIN_URL'),
    setUserTabType: '_blank',
  },
  mutations: {
    [types.GET_USERS](state) {
      // enable loader
      state.busy = true
    },
    // [API:INFO] STEP 5
    // mutation that sets response to the state
    [types.GET_USERS_SUCCESS](state, response) {
      // disable loader
      state.busy = false
      // set data to the state
      state.response = response || {}
      state.draw++
    },
    [types.GET_USERS_FAIL](state, error) {
      state.busy = false
      // console.log('error', error)
      // state.success = error.data.success || false
      // state.fail = error
      state.success = false
      state.fail = true
      state.response = error
    },
    updateSearch(state, search) {
      // Stateless
      state.search = search
    },
    updateSearchBy(state, searchBy) {
      state.searchBy = searchBy
    },
    updateUsersTableOptions(state, options) {
      state.options = options
    },
    updatePerPage(state, perPage) {
      state.perPage = perPage
    },
    updateFilter(state, filter) {
      state.filter = filter
    },
    impersonateUserSuccess(state, response) {
      if (response.data.success) {
        const postfix = '/set-user'
        window.open(state.setUserUrl + postfix, state.setUserTabType)
        // window.open(getEnv('VUE_APP_FRONTEND_LOGIN_URL') + postfix, '_blank')
      }
    },
    impersonateUserFail(state) {
      state.impersonateUserFail = true
    },
    updateImpersonateUserFail(state, value) {
      state.impersonateUserFail = value
    },
    validateEmailSuccess(state){
      state.success = true
    },
    validateEmailFail(state){
      state.fail = true
    },
    invalidateEmailSuccess(state){
      state.success = true
    },
    invalidateEmailFail(state){
      state.fail = true
    },
    validatePhoneSuccess(state){
      state.success = true
    },
    validatePhoneFail(state){
      state.fail = true
    },
    invalidatePhoneSuccess(state){
      state.success = true
    },
    invalidatePhoneFail(state){
      state.fail = true
    }
  },
  actions: {
    // [API:INFO] STEP 3
    // This action calls the method that creates a get request to the API
    getUsers: (store, { page, perPage, search, searchBy, order, orderBy }) => {
      // commits a specific mutation to the store which sets a busy value (it means that something in progress) so you can show loadings according to this value
      store.commit(types.GET_USERS)
      // commits a specific mutation to the store which sets a busy value (it means that something in progress) so you can show loadings according to this value
      return api.getUsers(page, perPage, search, searchBy, order, orderBy).then(
        // receive backend response and commit it to the store
        (response) => {
          if (response.data.error) {
            store.commit(types.GET_USERS_FAIL, response.data.error)
            throw new Error(response.data.error)
          }
          store.commit(types.GET_USERS_SUCCESS, response)
        },
        // or handle error if something happened
        (error) => {
          if (error.name === 'CanceledError') {
            return
          }
          store.commit(types.GET_USERS_FAIL, error)
        }
      )
    },
    validateEmail:(store, { userId }) => {
      return api.validateEmail(userId).then(
        (response) => store.commit('validateEmailSuccess', response),
        (error) => store.commit('validateEmailFail', error)
      )
    },
    validatePhone:(store, { userId }) => {
      return api.validatePhone(userId).then(
        (response) => store.commit('validatePhoneSuccess', response),
        (error) => store.commit('validatePhoneFail', error)
      )
    },
    invalidateEmail:(store, { userId }) => {
      return api.invalidateEmail(userId).then(
        (response) => store.commit('invalidateEmailSuccess', response),
        (error) => store.commit('invalidateEmailFail', error)
      )
    },
    invalidatePhone:(store, { userId }) => {
      return api.invalidatePhone(userId).then(
        (response) => store.commit('invalidatePhoneSuccess', response),
        (error) => store.commit('invalidatePhoneFail', error)
      )
    },
    impersonateUser: (store, { userId }) => {
      return api.impersonateUser(userId).then(
        (response) => store.commit('impersonateUserSuccess', response),
        (error) => store.commit('impersonateUserFail', error)
      )
    },
  },
  getters: {
    usersTable: (state) => {
      let filteredResponse = {}
      if (
        typeof state.response.data !== 'undefined' &&
        !state.response.data.error &&
        state.response.data.data.length !== 0
      ) {
        filteredResponse['draw'] = state.draw
        filteredResponse['recordsFiltered'] = state.response.data.data.total
        filteredResponse['recordsTotal'] = state.response.data.data.total
        filteredResponse['data'] = state.response.data.data.data
        //TODO
        // $.each(state.response.data.data.data, function () {
        // 	this['contacts'] = $.merge(this['email'], this['phone_number'])
        // })
      } else {
        filteredResponse['draw'] = state.draw
        filteredResponse['recordsFiltered'] = 0
        filteredResponse['recordsTotal'] = 0
        filteredResponse['data'] = []
      }

      return filteredResponse
    },
    usersSearch: (state) => {
      return state.search
    },
    usersSearchBy: (state) => {
      return state.searchBy
    },
    usersTableOptions: (state) => {
      return state.options
    },
    usersPerPage: (state) => {
      return state.perPage
    },
    usersFilter: (state) => {
      return state.filter
    },
    impersonateUserFail: (state) => {
      return state.impersonateUserFail
    },
  },
}

<template>
  <div>
    <base-table>
      <!-- :addName="addName" -->
    </base-table>
  </div>
</template>

<script>
import BaseTable from './UsersTable.vue'

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      // addName:"user",
      // headers: [
      //   { text: 'eKool ID', align: 'start', value: 'id' },
      //   { text: 'First name', value: 'firstName' },
      //   { text: 'Last name', value: 'lastName' },
      //   { text: 'User name', value: 'userName' },
      //   { text: 'ID code', value: 'idCode' },
      // ],
      // users: [
      //   {
      //     id: 765434,
      //     firstName: 'Soham',
      //     lastName: 'Lane',
      //     userName: 'kneadgenes',
      //     idCode: 59345,
      //   },
      //   {
      //     id: 5422343,
      //     firstName: 'bob',
      //     lastName: 'Petkevicha',
      //     userName: 'gadds',
      //     idCode: 43223,
      //   },
      //   {
      //     id: 43,
      //     firstName: 'Ivars',
      //     lastName: 'Vala',
      //     userName: 'gadds',
      //     idCode: 43223,
      //   },
      //   {
      //     id: 111,
      //     firstName: 'Veronika',
      //     lastName: 'Goal',
      //     userName: 'gadds',
      //     idCode: 43223,
      //   },
      //   {
      //     id: 53,
      //     firstName: 'Nadja',
      //     lastName: 'Rate',
      //     userName: 'gadds',
      //     idCode: 43223,
      //   },
      //   {
      //     id: 6546,
      //     firstName: 'Katja',
      //     lastName: 'Ratop',
      //     userName: 'gadds',
      //     idCode: 43223,
      //   },
      //   {
      //     id: 5341532,
      //     firstName: 'Ivan',
      //     lastName: 'Port',
      //     userName: 'gadds',
      //     idCode: 43223,
      //   },
      // ],
    }
  },
}
</script>

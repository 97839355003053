<template>
  <page-container class="school-info-card" :class="{'school-info-card--loading': loading }" flat>
    <!--  TODO: fix name for direct route  -->
    <template v-if="loading">
      <div>
        <e-circular-loading />
      </div>
    </template>
    <template v-else>
      <info-card-section>
        <div class="graybase--text callout-2 pb-1">
          <copy-span>{{ schoolMainInfo.name }}</copy-span>
        </div>
        <div class="grayscale500--text body-3">
          School ID <copy-span>{{ this.$route.params.id }}</copy-span></div
        >
      </info-card-section>

      <e-divider class="grayscale200" />

      <info-card-section>
        <div class="d-flex">
          <div class="frame">
            <span class="pa-2 caption-1 graybase--text">Student count</span>
            <span class="callout-1 graybase--text pl-2 pb-2 pt-1">{{ schoolActiveRoles.student_count }}</span>
          </div>
          <div class="frame mx-3">
            <span class="pa-2 caption-1 graybase--text">Parent count</span>
            <span class="callout-1 graybase--text pl-2 pb-2 pt-1">{{ schoolActiveRoles.parent_count }}</span>
          </div>
          <div class="frame">
            <span class="pa-2 caption-1 graybase--text">Teacher count</span>
            <span class="callout-1 graybase--text pl-2 pb-2 pt-1">{{ schoolActiveRoles.teacher_count }}</span>
          </div>
        </div>
      </info-card-section>

      <e-divider class="grayscale200" />

      <info-card-section title="Main details">
        <template #actions>
          <e-button icon="icon-pen" medium variant="ghost" @click="showEditModal" />
        </template>

        <div class="main-details">
          <div class="detail">
            <span class="detail-description graybase--text">Group</span>
            <copy-span class="detail-content">{{ schoolMainInfo.school_group || '-' }}</copy-span>
          </div>
          <div class="detail">
            <span class="detail-description graybase--text">Country</span>
            <copy-span class="detail-content">{{ schoolMainInfo.country || '-' }}</copy-span>
          </div>
          <div class="detail">
            <span class="detail-description graybase--text">Address</span>
            <copy-span class="detail-content">{{ schoolMainInfo.address || '-' }}</copy-span>
          </div>
          <div class="detail">
            <span class="detail-description graybase--text">Ehis ID</span>
            <copy-span class="detail-content">{{ schoolMainInfo.ehis_id || '-' }}</copy-span>
          </div>
          <div class="detail">
            <span class="detail-description graybase--text">Local government</span>
            <copy-span class="detail-content">{{ schoolMainInfo.local_government || '-' }}</copy-span>
          </div>
          <div class="detail">
            <span class="detail-description graybase--text">Card provider</span>
            <copy-span class="detail-content">{{ schoolMainInfo.school_card_provider || '-' }}</copy-span>
          </div>
          <div class="detail">
            <span class="detail-description graybase--text">School type</span>
            <copy-span class="detail-content">{{ schoolMainInfo.school_type || '-' }}</copy-span>
          </div>
          <div class="detail">
            <span class="detail-description graybase--text">Unprocessed requests</span>
            <copy-span class="detail-content">{{ schoolMainInfo.unprocessed_requests || '-' }}</copy-span>
          </div>
          <div class="detail">
            <span class="detail-description graybase--text">Upload size</span>
            <copy-span class="detail-content">{{ schoolMainInfo.upload_size || '-' }}</copy-span>
          </div>
        </div>
      </info-card-section>

      <e-divider class="grayscale200" />
      <info-card-section title="Modules">
        <e-switch class="mb-2" v-model="interview" @change="changeDevModule" label="Development interviews" />
        <e-switch class="mb-2" v-model="courseRegistrationModule"  @change="changeModule(isModulePublished('CourseRegistrationModule'),'CourseRegistrationModule')" label="Courses" :key="courses"/>
        <e-switch class="mb-2" v-model="formativeAssessmentModule" @change="changeModule(isModulePublished('FormativeAssessmentModule'),'FormativeAssessmentModule')" label="Formative assessment" :key="formative"/>
        <e-switch v-model="ehisModule"  @change="changeModule(isModulePublished('EhisModule'),'EhisModule')" label="EHIS" :key="ehis"/>
      </info-card-section>
        <e-divider class="grayscale200" />
      <info-card-section>
        <e-switch :value="schoolMainInfo.published" :input-value="schoolMainInfo.published" @change="changePublished" label="Published" />
      </info-card-section>
      <e-divider class="grayscale200" />

      <info-card-section title="Export data">
        <div class="d-flex">
          <e-button large variant="tertiary" class="flex-grow-1" @click="showExportModal">Information</e-button>
          <e-button large variant="tertiary" class="ml-4 flex-grow-1" @click="MS365export"> MS365 export</e-button>
        </div>
      </info-card-section>
    </template>

  </page-container>
</template>

<script>
import { getEnv } from '@env'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import ExportData from '../modals/ExportData.vue'
import CopySpan from '@/components/CopySpan'
import PageContainer from '@/components/PageContainer'
import InfoCardSection from '@/components/InfoCardSection'
import EditSchoolInfoModal from '@/views/data/schools/modals/EditSchoolInfoModal.vue'
import { get } from '@vueuse/core'
export default {
  components: {
    InfoCardSection,
    PageContainer,
    CopySpan,
    ExportData,
  },
  data() {
    return {
      loadedModules:false,
      formative: 0,
      courses: 100,
      ehis: 200,
      schoolId: null,
      tempObject: {},
      institutions: [
        { key: 1, name: 'Elementary school' },
        { key: 2, name: 'High school' },
        { key: 3, name: 'Primary school' },
        { key: 8, name: 'Vocational school' },
        { key: 9, name: 'Hobby school' },
        { key: 10, name: 'Local government' },
        { key: 18, name: 'User group' },
        { key: 19, name: 'Publisher' },
        { key: 20, name: 'Advertising Agency' },
      ],
    }
  },
  computed: {
    ...mapState('school', ['schoolActiveRoles', 'schoolMainInfoBusy']),
    loading() {
      return this.schoolMainInfoBusy
    },
interview:{
      get(){
        return this.schoolMainInfo.interview_enabled
      },
      set(value){
        this.$store.commit('school/updateInterviewEnabled', value);
      }
    },
    courseRegistrationModule:{
      get(){
        return this.isModulePublished('CourseRegistrationModule')
      },
     set(value){
       return value
     }
    },
    formativeAssessmentModule:{
      get(){
        return this.isModulePublished('FormativeAssessmentModule')
      },
      set(value){
        return value
      }
    },
    ehisModule:{
      get(){
        return this.isModulePublished('EhisModule')
      },
      set(value){
        return value
      }
    },
    urlPath() {
      return getEnv('VUE_APP_FRONTEND_API_URL')
    },
    schoolMainInfo: {
      get() {
        return this.$store.getters['school/schoolInfo']
      },
      set(value) {
        this.$store.commit('school/saveSchoolInfo', value)
      },
    },
    schoolModules: {
      get() {
        return this.$store.getters['school/schoolModules']
      },
      set(value) {
        this.$store.commit('school/saveSchoolModules', value)
      },
    },
  },
  created() {
     this.schoolId = parseInt(this.$route.params.id)
    this.$store.dispatch('getCountries')
      this.$store.dispatch('school/getSchoolModules', { id: this.schoolId }).then(() => {
        this.loadedModules=true
      })   
  },
  methods: {
    isModulePublished(moduleType) {
      if (!this.schoolModules) return false
      if(this.loadedModules===false) return false
      const module = this.schoolModules.find(
        (item) => item.dtype === moduleType
      );
      return module ? true : false;
    },
      getModuleId(moduleType){
         if (!this.schoolModules) return false
         if(this.loadedModules===false) return false
        const module = this.schoolModules.find(
          (item) => item.dtype === moduleType
        );
        return module.id
      },
    async changeModule(val,moduleType) {
      if (val==false){
       await this.$store.dispatch('school/addModule', {
          id:this.schoolId,
          data: { dtype: moduleType },
        }).then(() => {
           this.$eToast.success('Module was successfully added')
        },(error)=>this.$eToast.error(error))
        } else{
           const moduleId=this.getModuleId(moduleType)
          await this.$store.dispatch('school/deleteModule', {schoolId: this.schoolId,moduleId: moduleId}).then(() => {
            this.$eToast.success('Module was successfully deleted')
          }).catch(error => {
        this.$eToast.error(error);
        if (moduleType === 'FormativeAssessmentModule') {
          this.formativeAssessmentModule = true;
           this.formative+=1
        } else if (moduleType === 'CourseRegistrationModule') {
          this.courseRegistrationModule = true;
          this.courses+=1
        }
        else if (moduleType === 'EhisModule') {
          this.ehisModule = true;
          this.ehis+=1
        }
      });
    }},
    async changeDevModule(){
       await this.$store.dispatch('school/saveSchoolInfo', { id: this.schoolId, data: this.schoolMainInfo }).then(() => {
        if (this.schoolMainInfo.interview_enabled===true){
        this.$eToast.success('Module was successfully added')
        }
        else{
          this.$eToast.success('Module was successfully deleted')
        }
    })},
    showEditModal() {
      this.$eDialog({
        width: 424,
        component: EditSchoolInfoModal,
        componentProps: {
          schoolData: this.schoolMainInfo,
          schoolId: this.schoolId,
        }
      })
    },
    showExportModal() {
      this.$eDialog({
        component: ExportData,
      })
    },
    async MS365export() {
      const resp = await this.$http.get(`${this.urlPath}/schools/${this.schoolId}/ms365-export`, {
        responseType: 'blob',
      })
      const url = window.URL.createObjectURL(new Blob([resp.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.csv')
      document.body.appendChild(link)
      link.click()
    },
    changePublished(val) {
      this.$store.dispatch('school/updateSchoolInformation', {
        id: this.schoolId,
        published: !!val,
        key: 'published',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/layouts/styles/_variables';

.content {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 12px;
}
.frame {
  width: 92.67px;
  height: 70px;
  background: #f3f4f6;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.detail {
  display: flex;
}
.detail-description {
  width: 154px !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #161718;
}
.detail-content {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6b7280;
  flex-shrink: 999;
  overflow: hidden;
}
.button {
  width: 143px;
}
.school-info-card {
  height: fit-content;
  &--loading {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .school-info-card {
    min-width: 342px;
    max-width: 342px;
  }
}
</style>

<template>
  <StatisticsCard title="Messaging statistics" icon="icon-message-bubbles">
    <StatisticsCardField v-for="value in statisticsMessagingEntities" :key="value.key" :description="value.name" :value="value.count" />
    <StatisticsCardField v-for="value in statisticsPollingEntities" :key="'polling_' + value.key" :description="value.name" :value="value.count" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  components: {StatisticsCardField, StatisticsCard},
  computed: {
    ...mapState('school', [
      'schoolMessagingStatistics',
      'schoolPollingStatistics'
    ]),
    statisticsMessagingEntities () {
      return Object.entries(this.schoolMessagingStatistics)
        // transform to array of objects [{ key, name, value }]
        .map(entry => ({ key: entry[0], ...entry[1] }))
        // exclude unneeded entities
        .filter(el => !['groups'].includes(el.key))
    },

    statisticsPollingEntities () {
      return Object.entries(this.schoolPollingStatistics)
        .map(entry => ({ key: entry[0], ...entry[1] }))
    }
  },
}
</script>

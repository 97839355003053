<template>
  <component :is="resolveLayoutVariant">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view />
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import LayoutContentVerticalNav from '@/layouts/LayoutContentVerticalNav.vue'
import LayoutBlank from '@/layouts/LayoutBlank.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
import { getCurrentInstance } from '@vue/composition-api/dist/vue-composition-api'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
  },
  setup() {
    const { route } = useRouter()
    const { appRouteTransition } = useAppConfig()
    // const vm = getCurrentInstance().proxy
    // vm.$store.dispatch('getCountries')

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-vertical-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appRouteTransition,
    }
  },
}
</script>

<template>
  <div class="session-icon-wrapper"> 
    <browser-icon
      v-if="session.platform !== ( 'Android' || 'iOS')&& session.session_type!=='oauth'"
      class="session-icon"
    />
    <phone-icon
      v-if="session.platform === ( 'Android' || 'iOS') || session.session_type==='oauth'"
      class="session-icon"
    />
  </div>
</template>
<script>
import BrowserIcon from '@/components/BrowserIcon.vue'
import PhoneIcon from '@/components/PhoneIcon.vue'
export default {
  components: { BrowserIcon, PhoneIcon },
  props: {
    session: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.session-icon-wrapper{
  width: 44px;
height: 44px;
gap: 5.5px;
border-radius: 8px;
border: 1px solid rgba(243, 244, 246, 1);
background: rgba(255, 255, 255, 1);
display: flex;
justify-content: center;
align-items: center;
}
.session-icon{
  width: 20px;
  height: 20px;
}

//dark theme
.v-theme--dark{
  .session-icon-wrapper{
    background: rgba(22, 23, 24, 1);
    border: 1px solid rgba(55, 65, 81, 1)
  }
}
</style>

import axios from '@/plugins/axios'

// GET REQUESTS
export const getSchools = ({ page, perPage, search, searchBy, order, orderBy, onlyPublished }) => {
  let options = {
    params: {
      page,
      perPage,
      search,
      searchBy,
      order,
      orderBy,
      onlyPublished,
    },
  }
  return axios.get('schools', options)
}
export const getSchoolMainInfo = (id) => {
  return axios.get('schools/' + id)
}
export const getSchoolModules = (id) => {
  return axios.get('schools/' + id+'/modules')
}
export const getSchoolHeadmasters = (id) => {
  return axios.get('schools/' + id + '/headmasters')
}
export const getSchoolAdministrators = (id) => {
  return axios.get('schools/' + id + '/administrators')
}
export const getSchoolStudyYear = (id) => {
  return axios.get('schools/' + id + '/study-year')
}
export const getSchoolStudyYearsHistory = (id) => {
  return axios.get('schools/' + id + '/study-years')
}
export const getSchoolActiveRoles = (id) => {
  return axios.get('schools/' + id + '/active-roles')
}
export const getSchoolPayments = (id) => {
  return axios.get('schools/' + id + '/payments')
}
export const getSchoolMessagingStatistics = (id) => {
  return axios.get('schools/' + id + '/messaging-statistics')
}
export const getSchoolWorkPlanStatistics = (id) => {
  return axios.get('schools/' + id + '/workplan-statistics')
}
export const getSchoolJournalStatistics = (id) => {
  return axios.get('schools/' + id + '/journal-statistics')
}
export const getSchoolDisciplineStatistics = (id) => {
  return axios.get('schools/' + id + '/discipline-statistics')
}
export const getSchoolFormativeAssessmentStatistics = (id) => {
  return axios.get('schools/' + id + '/formative-assessment-statistics')
}
export const getSchoolDevelopmentInterviewStatistics = (id) => {
  return axios.get('schools/' + id + '/development-interview-statistics')
}
export const getSchoolSubstitutionStatistics = (id) => {
  return axios.get('schools/' + id + '/substitution-statistics')
}
export const getSchoolOpiqStatistics = (id) => {
  return axios.get('schools/' + id + '/opiq-statistics')
}
export const getConsentManagement = (id) => {
  return axios.get('schools/' + id + '/consents')
}
export const getSchoolPollingStatistics = (id) => {
  return axios.get('schools/' + id + '/polling-statistics')
}
export const getSchoolRiksStatistics = (id) => {
  return axios.get('schools/' + id + '/riks-statistics')
}
export const getSchoolTaskStatistics = (id) => {
  return axios.get('schools/' + id + '/task-statistics')
}
export const getSchoolDevelopmentCardStatistics = (id) => {
  return axios.get('schools/' + id + '/development-card-statistics')
}
export const getPaymentMethods = () => {
  return axios.get('payment-methods')
}
export const getGradeLogs = (schoolID, studentIDCode, journalName, dateFrom, dateTo) => {
  const params = {
    id_code: studentIDCode,
    journal_name: journalName,
    end_date: dateTo,
    start_date: dateFrom,
  }
  return axios.get(`schools/${schoolID}/grades-logs`, { params, responseType: 'blob' })
}
export const getSchoolAlarmbellStatistics = (id) => {
  return axios.get('schools/' + id + '/alarmbell-statistics')
}


// POST REQUESTS
export const setStudyYearHistoryStatus = (id, data) => {
  return axios.post('study-years/' + id, data)
}
export const addModule = (id, data) => {
  return axios.post('schools/' + id+'/modules', data)
  
}
export const saveSchoolInfo = (id, data) => {
  let options = {
    name: data.name,
    address: data.address,
    street: data.street,
    county: data.county,
    city: data.city,
    postal_index: data.postal_index,
    country_id: data.country_id,
    email: data.email,
    phone_number: data.phone_number,
    ehis_id: data.ehis_id,
    published: data.published,
    local_gevernment_id: data.local_gevernment_id,
    institution_type_id: data.institution_type_id,
    interview_enabled: data.interview_enabled,
  }
  return axios.post('schools/' + id, options)
}
export const addPaymentMethod = (name) => {
  return axios.post('payment-methods', name)
}
export const updatePaymentMethod = (id, name) => {
  return axios.post('payment-methods/' + id, { name })
}
export const saveNewPayment = (data) => {
  return axios.post('school-payments', data)
}
export const updatePayment = (data, id) => {
  return axios.post('school-payments/' + id, data)
}
export const updateSchoolInformation = (published, id) => {
  const data = {
    published,
  }
  return axios.post('schools/' + id, data)
}
export const createSchool = (
  schoolName,
  schoolLanguage,
  schoolUrl,
  schoolInsitutionId,
  schoolCountryId,
  schoolCounty,
  schoolPostalIndex,
  schoolStreet,
  schoolPersonId,
  schoolCity
) => {
  const data = {
    name: schoolName,
    language: schoolLanguage,
    url: schoolUrl,
    institution_type_id: schoolInsitutionId,
    country_id: schoolCountryId,
    county: schoolCounty,
    postal_index: schoolPostalIndex,
    street: schoolStreet,
    person_id: schoolPersonId,
    city: schoolCity,
  }

  return axios.post('schools/', data)
}



// DELETE REQUESTS
export const deletePaymentMethod = (id) => {
  return axios.delete('payment-methods/' + id)
}
export const deletePayment = (id) => {
  return axios.delete('school-payments/' + id)
}
export const deleteModule = (schoolId,moduleId) => {
  return axios.delete('schools/' + schoolId + '/modules/'+ moduleId)
}

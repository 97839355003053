import * as api from '../../api'

export default {
  namespaced: true,
  state: {
    localGovernments: [],
    localGovernmentsBusy: true,
    errorMessages: [],
    search: '',
  },
  mutations: {
    updateGovernmentSearch(state, search) {
      state.search = search
    },
    updateGovernmentValue(state, { name, value }) {
      state[name] = value
    },
    getLocalGovernmentsSuccess(state, response) {
      state.localGovernments = response.data.data
      state.localGovernmentsBusy = false
    },
    addGovernmentSuccess(state, response) {
      state.localGovernments.push(response.data.data)
    },
    updateGovernmentSuccess(state, response) {
      if (!response.data.success) {
        state.errorMessages = response.data
        return
      }
      let data = response.data.data
      state.localGovernments = state.localGovernments.map((item) => {
        if (item.id == data.id) {
          return data
        }
        return item
      })
    },
    deleteGovernmentSuccess(state, id) {
      state.localGovernments = state.localGovernments.filter((e) => e.id !== id)
    },
  },
  actions: {
    getLocalGovernments: (store) => {
      return api.getLocalGovernments().then(
        (response) => {
          store.commit('getLocalGovernmentsSuccess', response)
          return Promise.resolve(response)
        },
        (e) => {
          return Promise.reject(e)
        }
      )
    },
    addGovernment: (store, data) => {
      return api.addGovernment(data).then(
        (response) => {
          store.commit('addGovernmentSuccess', response)
          return Promise.resolve(response)
        },
        (e) => {
          return Promise.reject(e)
        }
      )
    },
    updateGovernment: (store, government) => {
      const id = government.id
      const payload = {
        name: government.name,
        school_count: government.school_count,
        population: government.population,
      }
      return api.updateGovernment(id, payload).then(
        (response) => {
          store.commit('updateGovernmentSuccess', response)
          return Promise.resolve(response)
        },
        (e) => {
          return Promise.reject(e)
        }
      )
    },
    deleteGovernment: (store, { id }) => {
      return api.deleteGovernment(id).then(
        (response) => {
          store.commit('deleteGovernmentSuccess', id)
          return Promise.resolve(response)
        },
        (e) => {
          return Promise.reject(e)
        }
      )
    },
  },
  getters: {
    governmentSearch: (state) => {
      return state.search
    },
    governments: (state) => {
      return state.localGovernments
    },
  },
}

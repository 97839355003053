<template>
  <div>
    <div class="d-flex justify-space-between flex-wrap-reverse">
      <div class="d-flex flex-grow-1 mb-3">
        <div class="d-flex flex-grow-1">
          <e-text-field v-model="search" placeholder="Search..." prepend-inner-icon="icon-search" />
        </div>

        <div class="pl-3">
          <e-select v-model="searchBy" :items="filters" class="select" />
        </div>
      </div>
    </div>
    <no-data-block
      v-if="search === '' && !showAllSchools"
      icon="icon-search"
      title="Please use search to find schools!"
      description="To find a school  - please use a search bar and type school name to find it."
    />
    <div v-else>
      <e-data-table
        class="clickable"
        item-key="id"
        :headers="headers"
        :items="(search === '' && !showAllSchools) || null || isLoading ? [] : schools"
        :hide-default-footer="false"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page="options.perPage"
        @click:row="handleClick"
      >
        <template v-slot:no-data>
          <no-data-block
            v-show="search !== '' || null"
            icon="icon-search"
            title="No results!"
            description="Nothing matched your search, please try a different combination."
          />
        </template>
        <template v-slot:no-results>
          <no-data-block
            v-show="search !== '' || null"
            icon="icon-search"
            title="No results!"
            description="Nothing matched your search, please try a different combination."
          />
        </template>
      </e-data-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import NoDataBlock from '@/components/NoDataBlock'
// import VueCookies from 'vue-cookies'

export default {
  components: { NoDataBlock },
  props: {
    showAllSchools: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: 'School name', value: 'name' },
        { text: 'Activity', value: 'last_active_study_year', sortable: false},
        { text: 'Type', value: 'institution_type', sortable: false },
        { text: 'Students', value: 'student_count', sortable: false },
      ],
      filters: [
        { text: 'All', value: '' },
        { text: 'ID', value: 'i.id' },
        { text: 'Name', value: 'i.name' },
        { text: 'School group', value: 'i_owner.name' },
        { text: 'Address', value: 'a.county' },
        { text: 'City', value: 'a.city' },
        { text: 'Type', value: 'it.name' },
      ],
      debouncedSearch: _.debounce(() => {
        this.getSchools()
      }, 1000),
      schools: [],
    }
  },
  watch: {
    options: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && typeof oldValue.itemsPerPage === 'number') {
          this.getSchools()
        }
      },
      deep: true,
    },
    searchBy: {
      handler() {
        this.options.page = 1
        // this.getSchools()
      },
    },
    search: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.options.page = 1
          this.loading = true
          this.debouncedSearch()
        }
      },
    },
    showAllSchools: {
      handler(val) {
        if(val){
          this.options.page = 1
          this.loading = true
          //this.getAllSchools()
          this.debouncedSearch()
        }
      },
    }
  },
  computed: {
    total() {
      return this.$store.getters.schoolsTable.recordsFiltered
    },
    schoolsBusy() {
      return this.$store.state.schools.schoolsBusy
    },
    isLoading() {
      return this.loading || this.schoolsBusy
    },
    activeSchoolName: {
      get() {
        return this.$store.getters.activeSchoolName
      },
      set(value) {
        this.$store.commit('updateActiveSchoolName', value)
      },
    },
    search: {
      get() {
        return this.$store.getters.schoolsSearch
      },
      set(value) {
        this.options.page = 1
        if (value.length > 2) {
          this.loading = true
          this.$store.commit('updateSchoolSearch', value)
          // this.debouncedSearch()
        } else if (value === '') {
          this.$store.commit('updateSchoolSearch', value)
        }
      },
    },
    searchBy: {
      get() {
        return this.$store.getters.schoolsSearchBy
      },
      set(value) {
        // if (this.search !== '') {
        this.$store.commit('updateSchoolSearchBy', value)
        // this.getSchools()
        // }
      },
    },
    options: {
      get() {
        return this.$store.getters.schoolsTableOptions
      },
      set(value) {
        this.$store.commit('updateSchoolsTableOptions', value)
      },
    },
  },
  methods: {
    handleClick(school) {
      this.$router.push({ name: 'data-schools-detailed', params: { id: school.id } })
      this.activeSchoolName = school.name
    },
    getSchools() {
      const { page, perPage, sortBy, sortDesc } = this.options
      let search = this.searchBy === 'i.id' ? Number(this.search) : this.search
      let searchBy = this.searchBy
      let order = !sortDesc.length ? undefined : sortDesc[0] ? 'asc' : 'desc'
      let orderBy = !sortBy.length ? undefined : sortBy[0]
      let onlyPublished = this.showAllSchools
      const payload = { page, perPage, search, searchBy, order, orderBy, onlyPublished }
      this.$store.dispatch('getSchools', payload).then(
        () => {
          this.schools = _.uniqBy(this.$store.getters.schoolsTable.data, 'id')
          localStorage.setItem('schools', JSON.stringify(this.schools))
        },
        (err) => {
          this.$eToast.clear()
          this.$eToast.error(err.message)
        }
      )
      this.loading = false
    },
  },
  async created() {
    if (this.search !== ' ') {
      this.schools = JSON.parse(localStorage.getItem('schools') || '[]')
    }
  },
}
</script>

<style scoped>
.select {
  width: 164px;
}
</style>

<template>
  <div>
    <e-dialog-header @click:close="close">
      Set password
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
          <!-- first password field -->
          <div class="text-field">
            <e-text-field
              large
              class="body-2"
              type="password"
              v-model="user.password"
              label="Password"
              :rules="[validators.required, validators.oneSymbolOneNumber, validators.minLength]"
              required
            />
          </div>

          <!-- second password field -->
          <div class="password-block text-field mt-2 mb-3">
            <e-text-field
              large
              class="body-2"
              type="password"
              v-model="user.password2"
              :rules="[validators.required, validators.match]"
              label="Confirm password"
              required
            />
          </div>
          <!-- checkbox -->
          <div class="mb-3 pb-4">
            <e-checkbox
              large
              class="body-3 graybase--text"
              label="Save old password"
              v-model="user.savePassword"
            />
          </div>
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="save">Set password</e-button>

    </e-dialog-actions>
  </div>
</template>

<script>
import { required } from '@core/utils/validation'

export default {
  name: 'ResetPasswordModal',
  data() {
    return {
      valid: false,
      user: this.userData,
    }
  },
  props: {
    userData: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
  },
  computed: {
    validators() {
      return {
        required,
        match: (value) => (this.user.password === this.user.password2 ? true : 'Passwords do not match'),
        oneSymbolOneNumber: (value) => {
          /* eslint-disable no-useless-escape */
          const regExp = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
          /* eslint-enable no-useless-escape */
          const validPassword = regExp.test(this.user.password)
          return validPassword || 'Password must contain at least one number and one letter'
        },
        minLength: (value) => {
          return this.user?.password?.length >= 8 || 'Password must contain at least 8 characters'
        },
      }
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async saveUserPassword() {
      this.$refs.form.validate()
      if (!this.valid) return
      try {
        let userData = this.user
        await this.$store.dispatch('saveUser', userData)
        // await this.getUserInfo()
        this.$root.$emit('submit', true)
      } catch (e) {
        this.$eToast.error('Something went wrong')
      }
    },

    async save() {
      if (!this.user.savePassword) {
        this.$eDialog({
          preset: 'dialog',
          presetProps: {
            title: 'Save old password',
            description: `Are you sure you don’t want to save old user password before setting new one?`,
            closeButtonLabel: 'Cancel',
            submitButtonLabel: 'Change password',
          },
          presetEvents: {
            'click:submit': async () => {
                await this.saveUserPassword()

              // return await this.deleteGovernment(government.id)
            }
          }
        })

      } else {
        await this.saveUserPassword()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
</style>

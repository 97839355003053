<template>
  <logs-table />
</template>

<script>
import LogsTable from './LogsTable.vue'
import PageContainer from '@/components/PageContainer'

export default {
  components: {
    PageContainer,
    LogsTable,
  },
}
</script>

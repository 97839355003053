<template>
  <div>
    <e-dialog-header @click:close="close">
      Logs
    </e-dialog-header>
    <e-dialog-content>
        <div v-if="!servicesBusy && logsError.success">
          <e-tabs class="mb-3" grow :value="tab" :tabs="computedTabs" @change="newTab => tab = newTab"/>
        </div>

        <e-circular-loading class="pt-8 pb-10" :value="servicesBusy" />
        <!-- information about logs -->
        <div v-if="!servicesBusy">
          <div v-for="(log, index) in logs" :key="index + '-log'" class="logs">
            <div v-if="log.month_name === tab">
              <div class="card mb-3 pl-3" v-for="payment in log.payments" :key="payment.transaction_id">
                <div class="text-block my-3">
                  <div class="callout-2 graybase--text mb-2 log-title-button"
                  >{{ payment.price }} · {{ payment.payment_method }}
                  <e-button v-if="payment.payment_method=='Paying with Google (NEW)'"   variant="tertiary" @click="openManageSubscription(payment)">Manage subscription</e-button>
                  </div>
                  <div class="body-2 grayscale500--text mb-2">{{ payment.description }}</div>
                  <div class="body-3 grayscale800--text mb-1">{{ payment.starts }} - {{ payment.expires }}</div>
                  <e-chip
                    v-if="payment.success"
                    prepend-icon="icon-checkmark"
                    variant="success"
                    class="mr-1 mt-1"
                  >Successful payment</e-chip
                  >
                  <e-chip
                    v-if="!payment.success"
                    prepend-icon="icon-exclamation"
                    variant="danger"
                    class="mr-1 mt-1"
                  >Unsuccessful payment</e-chip
                  >
                  <e-chip
                    v-if="!checkExpireDate(payment.expires)"
                    prepend-icon="icon-star"
                    variant="primary"
                    class="mr-1 mt-1"
                  >Active premium</e-chip
                  >
                  <e-chip
                    v-if="checkExpireDate(payment.expires)"
                    prepend-icon="icon-rotating-arrows"
                    class="mr-1 mt-1"
                  >Expired premium</e-chip
                  >
                  <e-chip
                    v-if="payment.type === 'trial'"
                    prepend-icon="icon-clock"
                    variant="warning"
                    class="mr-1 mt-1"
                  >Free trial</e-chip
                  >
                  <e-chip
                    v-if="payment.type === 'free'"
                    prepend-icon="icon-star"
                    variant="primary"
                    class="mr-1 mt-1"
                  >Premium for free</e-chip
                  >
                  <e-chip
                    v-if="payment.type === 'gift'"
                    prepend-icon="icon-star"
                    variant="primary"
                    class="mr-1 mt-1"
                  >Free premium for teacher</e-chip
                  >
                  <e-chip
                    v-if="payment.type === 'premium'"
                    prepend-icon="icon-star"
                    variant="primary"
                    class="mr-1 mt-1"
                  >Premium</e-chip
                  >
                </div>
                <v-spacer></v-spacer>
                <e-button
                  medium=""
                  icon="icon-paper-page"
                  class="mt-3 mr-3"
                  variant="ghost"
                  @click="copyPaymentLog(payment)"
                ></e-button>
              </div>
            </div>
          </div>
          <no-data-block
            v-if="!servicesBusy && !logsError.success"
            icon="icon-money-bill"
            :title="logsError.error"
            description="Click add premium to enable payments for this user."
          />
        </div>
    </e-dialog-content>
  </div>
</template>

<script>
import { required } from '@core/utils/validation'
import ResetPasswordModal from '@/views/data/users/user-details/modals/ResetPasswordModal.vue'
import moment from 'moment/moment'

export default {
  name: 'PremiumLogsModal',
  data() {
    return {
      monthCount: '',
      tab: '',
      list: '',
    }
  },
  props: {
  },
  mounted() {
    if (this.logs) {
      this.tab = this.logs[0].month_name
      this.monthCount = this.logs.length
    }
  },
  computed: {
    logs() {
      return this.$store.getters.userServiceLog
    },
    computedTabs() {
      return this.logs.map(log => ({ name: log.month_name, id: log.month_name }))
    },
    logsError() {
      return this.$store.getters.logsError
    },
    servicesBusy() {
      return this.$store.getters.servicesBusy
    },
    currentDate() {
      return moment().format('YYYY-MM-DD')
    },
  },
  methods: {
    async openManageSubscription(payment) {
         window.open(`https://play.google.com/console/u/0/developers/6818030252661427794/orders/${payment.transaction_id}`, '_blank')
    },
    close() {
      this.$root.$emit('submit')
    },
    checkExpireDate(date) {
      let dateExpires = false
      date = moment(date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
      if (date < this.currentDate) {
        dateExpires = true
      }
      return dateExpires
    },
    copyPaymentLog(payment) {
      const text = `${payment.price} · ${payment.payment_method}\n\n${payment.description}\n\n${payment.starts} - ${payment.expires}`
      const that = this
      navigator.clipboard.writeText(text).then(
        () => {
          that.$eToast.success('Copied!')
        },
        (err) => {
          that.$eToast.error(err)
        }
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.log-title-button{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

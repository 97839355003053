export default [
  {
    subheader: 'data',
  },
  {
    title: 'users',
    icon: 'icon-person',
    to: 'data-users',
  },
  {
    title: 'schools',
    icon: 'icon-school-building',
    to: 'data-schools',
  },
]

<template>
  <div>
    <e-dialog-header @click:close="close">
      Edit school details
    </e-dialog-header>
    <e-dialog-content>
      <div class="text-field">
        <e-text-field large class="body-2" label="School name" v-model="school.name" />
      </div>

      <div class="text-field">
        <e-select
          id="country_id"
          large
          label="Country"
          item-text="name"
          item-value="id"
          :items="countries"
          v-model="school.country_id"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
        </e-select>
      </div>
      <div class="text-field">
        <e-text-field large class="body-2" label="City" v-model="school.city" />
      </div>
      <div class="text-field">
        <e-text-field large class="body-2" label="County" v-model="school.county" />
      </div>
      <div class="text-field">
        <e-text-field large class="body-2" label="Postal index" v-model="school.postal_index" />
      </div>
      <div class="text-field">
        <e-text-field large class="body-2" label="Street" v-model="school.street" />
      </div>
      <div class="text-field">
        <e-text-field large class="body-2" label="Ehis ID" v-model="school.ehis_id" />
      </div>
      <div class="text-field">
        <e-text-field large class="body-2" label="Phone number" v-model="school.phone_number" />
      </div>
      <div class="text-field">
        <e-text-field large class="body-2" label="Email" v-model="school.email" />
      </div>
      <div class="text-field">
        <e-select
          v-model="school.institution_type_id"
          label="School type"
          :items="institutions"
          item-text="name"
          item-value="key"
        />
      </div>
      <!-- <e-switch v-model="school.interview_enabled" label="Interview Enabled" /> -->
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="save">Save edit</e-button>

    </e-dialog-actions>

    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
export default {
  name: 'EditSchoolInfoModal',
  data() {
    return {
      school: {},
      institutions: [
        { key: 1, name: 'Elementary school' },
        { key: 2, name: 'High school' },
        { key: 3, name: 'Primary school' },
        { key: 8, name: 'Vocational school' },
        { key: 9, name: 'Hobby school' },
        { key: 10, name: 'Local government' },
        { key: 18, name: 'User group' },
        { key: 19, name: 'Publisher' },
        { key: 20, name: 'Advertising Agency' },
      ],
      overlay: false,
    }
  },
  props: {
    schoolId: {
      type: Number,
      default: null,
    },
    schoolData: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.school = { ...this.schoolData }
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async save() {
      this.overlay = true
      try {
        await this.$store.dispatch('school/saveSchoolInfo', { id: this.schoolId, data: this.school })
        await this.$store.dispatch('school/getSchoolMainData', { id: this.schoolId })
        this.close()
        this.$eToast.success('A school has been changed!')
      } catch (e) {
        this.$eToast.error(e)
      } finally {
        this.overlay = false
      }

    },
  },
  computed: {
    countries() {
      return this.$store.getters.countries
    },
  }
}
</script>

<template>
  <e-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
   <template v-slot:activator="{ on, attrs }">
  <div v-bind="attrs" v-on="on">
    <e-avatar :src="$auth.user.picture" />
  </div>
   </template>
    <e-menu-list>
      <e-menu-list-item prepend-icon="icon-arrow-door-right" @click="logoutUser">
        Sign out
      </e-menu-list-item>
    </e-menu-list>
  </e-menu>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api'

export default {
  data() {
    return {
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const logoutUser = () => {
      localStorage.removeItem('accessTokenEkool')
      vm.$auth.logout({
        returnTo: window.location.origin,
      })
    }

    return {
      logoutUser,
    }
  },
}
</script>

<template>
  <div>
    <e-dialog-header @click:close="close">
      Edit features
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
          <div class="mt-6">
            <e-text-field
              large
              class="body-2 mb-10"
              label="Name"
              v-model="tempObjectName"
              required
              :rules="[validators.required]"
            />
            <e-text-field
              large
              class="body-2 mb-3"
              label="Key"
              v-model="tempObjectKey"
              required
              :rules="[validators.required]"
            />
          </div>
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="updatePackageFeature">Save edits</e-button>
    </e-dialog-actions>

    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
import { required } from '@core/utils/validation'
export default {
  props: ['title', 'mdName', 'item'],
  data() {
    return {
      valid: false,
      newFeatureName: '',
      newFeatureKey: null,
      open: false,
      open2: false,
      // tempObject: {},
      tempObjectName: '',
      tempObjectKey: '',
      overlay: false,
    }
  },
  computed: {
    validators() {
      return {
        required,
      }
    },
  },
  created() {
    this.tempObjectName = this.item.name
    this.tempObjectKey = this.item.feature_key
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async updatePackageFeature() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true
      // setTimeout(() => {
      //   this.overlay = true
      // }, 300)
      // let updateFeature = this.item
      try {
        await this.$store.dispatch('schoolPackages/updatePackageFeature', {
          name: this.tempObjectName,
          feature_key: this.tempObjectKey,
          id: this.item.id,
        })
        this.$eToast.success('A feature has been changed!')
        this.close()
      } catch (e) {
        this.$eToast.error(e)
      } finally {
        this.overlay = false
      }
    },
  },
}
</script>

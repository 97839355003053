<template>
  <div>
    <div>
      <v-layout column style="max-height: 662px;">
        <v-flex style="overflow: auto"> 
          <e-data-table
            itemKey="id"
            :headers="headers"
            :itemsPerPage="50"
            :items="rolesBusy ? [] : roles"
            :loading="rolesBusy"
            :hide-default-footer="false"
            class="table"
          >
            <template v-slot:[`item.school_name`]="{ item }">
              <e-button variant="link" small @click.prevent="chooseSchool(item.institution_id)">{{
                item.school_name
              }}</e-button></template
            >

            <template v-slot:[`item.type_id`]="{ item }">
              <span v-if="item.type_id == 'Parent'"
                >{{ item.type_id }}
                <e-button variant="link" small @click.prevent="chooseUser(item.person_id)"
                  >{{ item.name1 }} {{ item.name2 }}</e-button
                ></span
              >
              <span v-else>{{ item.type_id }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <e-chip v-if="item.status === 'Active'" variant="success">
                {{ item.status }}
              </e-chip>
              <e-chip v-else-if="item.status === 'Left'" variant="danger">
                {{ item.status }}
              </e-chip>
              <e-chip v-else>{{ item.status }}</e-chip>
            </template>
            <template v-slot:[`item.created`]="{ item }"> {{ setDateFormat(item.created) }}</template>
            <template v-slot:[`item.modified`]="{ item }"> {{ setDateFormat(item.modified) }}</template>
            <template v-slot:no-data>
              <no-data-block icon="icon-person-with-plus" title="No roles added!" />
            </template>
            <!-- <template v-slot:items><v-responsive class="overflow-y-auto" max-height="200px"> </v-responsive> </template
      > -->
          </e-data-table>
          </v-flex
        ></v-layout
      > 
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import moment from 'moment'
import NoDataBlock from '@/components/NoDataBlock'
export default {
  components: { NoDataBlock },
  data() {
    return {
      headers: [
        { text: 'Role', value: 'type_id', sortable: false },
        { text: 'School name', value: 'school_name' },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Created', value: 'created', sortable: false },
        { text: 'Modified', value: 'modified', sortable: false },
      ],
    }
  },
  computed: {
    rolesBusy() {
      return this.$store.getters.rolesBusy
    },
    ...mapState('usersEdit', ['userRoles', 'schoolAdministratorsBusy']),
    roles() {
      return _.flatten(this.$store.getters.userRoles?.map((i) => i.roles))
    },
  },
  methods: {
    setDateFormat(value) {
      return moment(value, 'DD/MM/YYYY HH:mm').format('DD.MM.Y HH:ss')
    },
    // getUserRoles() {
    //   const perPage = 50
    //   const page = 1
    //   let userId = this.$route.params.id
    //   return this.$store.dispatch('getUserRoles', { userId, page, perPage }).then(
    //     () => {},
    //     (err) => {
    //       console.error(err)
    //     }
    //   )
    // },
    chooseUser(personId) {
      this.$router.push({ path: `/data/users/${personId}/detailed` })
      // location.reload()
    },
    chooseSchool(schoolId) {
      this.$router.push('/data/schools/' + schoolId + '/detailed')
    },
  },
  created() {
    // this.getUserRoles()
  },
}
</script>

<style scoped>
.no-rules {
  width: 54px;
  height: 54px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rules-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main {
  border-radius: 6px;
  box-shadow: 0px 1px 3px rgba(3, 52, 104, 0.12);
}
.table-wrapper-scroll-y {
  display: block;
}
</style>

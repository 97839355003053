<template>
  <div>
    <e-dialog-header @click:close="close">
      Add payment method
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
        <e-text-field
          class="pt-5"
          v-model="methodName"
          label="Name"
          placeholder="e.g. American Express"
          required
          :rules="[validators.required]"
        />
        <loading-overlay :value="overlay" />
      </v-form>

    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="addPaymentMethod">Confirm</e-button>
    </e-dialog-actions>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from '@core/utils/validation'
export default {
  data() {
    return {
      valid: false,
      methodName: '',
      overlay: false,
    }
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async addPaymentMethod() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.overlay = true

      try {
        await this.$store.dispatch('payments/addPaymentMethod', { name: this.methodName })
        this.$eToast.success('Payment method was successfully added')
        this.overlay = false
        this.close()
      } catch (e) {
        this.$eToast.error(e)
      }
    },
  },
  computed: {
    ...mapState('payments', ['paymentMethods', 'paymentAddedSuccess', 'paymentAddedFail']),
    validators() {
      return {
        required,
      }
    },
  },
}
</script>

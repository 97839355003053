<template>
  <div>
    <e-dialog-header @click:close="close">
      Add school
    </e-dialog-header>
    <e-dialog-content>
      <v-form ref="form" v-model="valid">
        <e-text-field
          class="pb-8 pt-5"
          v-model="name"
          label="Name of school"
          required
          :rules="[validators.required]"
          placeholder="e.g. Bay elementary"
        />
        <e-text-field
          class="pb-8"
          v-model="personId"
          label="Personal ID"
          required
          :rules="[validators.required]"
          placeholder="e.g. 3456788"
        />
        <e-select
          class="pb-8"
          v-model="language"
          label="Language"
          placeholder="Estonian"
          required
          :rules="[validators.required]"
          :items="languages"
          item-text="name"
          item-value="key"
        />
        <e-select
          class="pb-8"
          v-model="institutionTypeId"
          label="Institution"
          placeholder="Elementary school"
          required
          :items="institutions"
          item-text="name"
          item-value="key"
        />
        <e-text-field class="pb-8" v-model="url" label="URL" placeholder="https://" />
        <e-select
          class="pb-8"
          v-model="countryId"
          label="Country"
          required
          :items="this.$store.getters.countries"
          item-text="name"
          item-value="id"
        />
        <e-text-field
          class="pb-8"
          v-model="city"
          label="City"
          required
          :rules="[validators.required]"
          placeholder="e.g. Tallin"
        />
        <e-text-field
          class="pb-8"
          v-model="county"
          label="County"
          required
          :rules="[validators.required]"
          placeholder="e.g. Harju county"
        />
        <!-- <e-select
          class="pb-8"
          v-model="localGovernment"
          label="Local government"
          placeholder="Yexes"
          required
          :rules="[(v) => !!v]"
          :items="['Yexes', 'asgedhtf']"
        /> -->
        <e-text-field class="pb-8" v-model="postalIndex" label="Postal index" placeholder="e.g. 12345" />
        <e-text-field
          class="pb-8"
          v-model="street"
          required
          :rules="[validators.required]"
          label="Street"
          placeholder="e.g. Mooni"
        />
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="validate">Add school</e-button>
    </e-dialog-actions>

    <loading-overlay :value="overlay"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { required } from '@core/utils/validation'
export default {
  name: 'AddSchoolModal',
  data() {
    return {
      dialog: false,
      valid: false,
      school: {},
      languages: [
        { key: 'en', name: 'English' },
        { key: 'et', name: 'Estonian' },
        { key: 'ru', name: 'Russian' },
        { key: 'de', name: 'German' },
        { key: 'fr', name: 'French' },
        { key: 'jp', name: 'Japanese' },
      ],
      institutions: [
        { key: 1, name: 'Elementary school' },
        { key: 2, name: 'High school' },
        { key: 3, name: 'Primary school' },
        { key: 8, name: 'Vocational school' },
        { key: 9, name: 'Hobby school' },
        { key: 10, name: 'Local government' },
        { key: 18, name: 'User group' },
        { key: 19, name: 'Publisher' },
        { key: 20, name: 'Advertising Agency' },
      ],
      language: 'et',
      institutionTypeId: 1,
      url: '',
      name: '',
      address: '',
      country: 'Estonia',
      county: '',
      city: '',
      countryId: 1,
      postalIndex: '',
      street: '',
      personId: '',
      localGovernment: '',
      countries: [],
      overlay: false,
      // createSchoolErrorMessages: [],
    }
  },
  computed: {
    ...mapGetters([
      'createSchoolErrorMessages',
      'schoolCreateBusy',
      'updateSchoolInformation',
      'schoolCreateSuccess',
      'schoolCreateFail',
    ]),
    validators() {
      return {
        required,
      }
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    setCountryName() {
      let result = this.$store.getters.countries.find((country) => country.id === this.countryId)
      this.countryName = result?.name || ''
    },
    createSchool() {
      this.overlay = true
      const schoolData = {
        schoolName: this.name,
        schoolLanguage: this.language,
        schoolUrl: this.url,
        schoolInsitutionId: this.institutionTypeId,
        schoolCountryId: this.countryId,
        schoolCounty: this.county,
        postalIndex: this.schoolPostalIndex,
        schoolStreet: this.street,
        schoolPersonId: this.personId,
        schoolCity: this.city,
      }
      this.$store
        .dispatch('createSchool', schoolData)
        .then(() => {
          this.overlay = false
          this.$eToast.success('A new school was added!')
          this.close()
        })
        .catch((error) => {
          // catch the error
          this.$eToast.error(error)
        })
    },
    validate() {
      if (!this.$refs.form.validate()) {
        this.$eToast.error('Please, fill the required fields!')
      } else {
        this.createSchool()
      }
    },
    async getCountries() {
      await this.$store.dispatch('getCountries')
      this.overlay = false
    },
  },
  created() {
    this.getCountries()
  },
}
</script>

<template>
  <div>
    <e-dialog-header @click:close="close">
      Edit study year
    </e-dialog-header>
    <e-dialog-content>
      <span class="graybase--text callout-3"> Status </span>
      <div>
        <e-radio-group v-model="code">
          <e-radio v-for="status in statusList" :key="status.code" :label="status.name" :value="status.code" />
        </e-radio-group>
      </div>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="setActiveStatusCode(code, id)">Save edits</e-button>
    </e-dialog-actions>

    <loading-overlay :value="overlay" />
  </div>
</template>

<script>
export default {
  props: ['statusCode', 'statusName', 'id'],
  data() {
    return {
      overlay: false,
      dialog: false,
      code: this.statusCode,
      statusList: [
        { name: 'Open', code: 0 },
        { name: 'Archived', code: 30 },
        { name: 'Archiving in progress', code: 20 },
        { name: 'Marked for archiving', code: 10 },
        { name: 'Archiving error', code: 99 },
      ],
    }
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    async setActiveStatusCode(value, id) {
      this.overlay = true
      try {
        let studyYearStatus = parseInt(value)
        await this.$store.dispatch('school/setStudyYearHistoryStatus', {
          id,
          data: { study_year_status: studyYearStatus },
        })
        this.$eToast.success('Study year status was successfully changed')
        this.close()
      } catch (e) {
        this.$eToast.error(e)
      } finally {
        this.overlay = false
      }
    },
  },
}
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>

import axios from '@/plugins/axios'

// GET REQUESTS
export const getLoginChartData = (dateFrom, dateTo, type) => {
  let options = {
    params: {
      start_date: dateFrom,
      end_date: dateTo,
      type,
    },
  }
  return axios.get('user-statistics', options)
}
export const getLoginMethodsChartData = (dateFrom, dateTo) => {
  let options = {
    params: {
      start_date: dateFrom,
      end_date: dateTo,
    },
  }
  return axios.get('user-statistics-login-method', options)
}
export const getReactionsChartData = (dateFrom, dateTo) => {
  let options = {
    params: {
      start_date: dateFrom,
      end_date: dateTo,
    },
  }
  return axios.get('reaction-statistics', options)
}
export const getMessagingChartData = (dateFrom, dateTo) => {
  let options = {
    params: {
      start_date: dateFrom,
      end_date: dateTo,
    },
  }
  return axios.get('messaging-statistics', options)
}

<template>
  <page-container title="Study years & history">
    <div class="row w-full gutter-2 px-3">
      <div v-for="card in schoolStudyYearsHistory" :key="card.id" class="col-12 col-sm-6 overflow-hidden pa-2">
        <study-year-card
          :status="card.status"
          :year="card.name"
          :date="card.modified"
          :statusID="card.study_year_status"
          :id="card.id"
          :created="card.created"
          :creator="card.creator"
          :modifier="card.modifier"
          :startDate="card.start_date"
          :endDate="card.end_date"
          :institutionId="card.institution_id"
        />
      </div>
    </div>
    <e-circular-loading class="py-10" :value="schoolStudyYearsHistoryBusy" />
    <no-data-block
      v-if="!schoolStudyYearsHistoryBusy && schoolStudyYearsHistory.length === 0"
      icon="icon-rotating-arrows"
      title="No Study years & history!"
      description="Study years and history will be displayed here when a school year has passed."
    />
  </page-container>
</template>

<script>
import { mapState } from 'vuex'
import StudyYearCard from './StudyYearCard.vue'
import NoDataBlock from '@/components/NoDataBlock'
import PageContainer from '@/components/PageContainer'

export default {
  components: {
    PageContainer,
    NoDataBlock,
    StudyYearCard,
  },
  computed: {
    ...mapState('school', ['schoolStudyYearsHistory', 'schoolStudyYearsHistoryBusy']),
  },
  async created() {
    let schoolId = this.$route.params.id
    this.$store.dispatch('school/getSchoolStudyYear', { id: schoolId })
    this.$store.dispatch('school/getSchoolStudyYearsHistory', { id: schoolId })
  },
}
</script>

<template>
  <page-container title="Services">
    <template #actions>
      <!--      <e-button class="mr-3" large variant="tertiary" @click.prevent="resetPremium">Reset trial</e-button>-->
      <!-- MD - Add premium -->
      <e-button large variant="primary" @click="openServiceLog">Logs</e-button>
    </template>
    <e-data-table :items="services" :itemsPerPage="5" itemKey="id" :headers="headers" :loading="servicesBusy">
      <template v-slot:[`item.name`]="{ item }"> {{ item.name }}</template>
      <template v-slot:[`item.members`]="{ item }">
        <a
          v-for="(member, index) in item.members"
          :key="member.id"
          @click.prevent="editUser(member.id)"
          class="table-link"
        >{{ member.name}}
          <span v-if="index != item.members.length - 1" class="no-txt">,&nbsp;</span>
        </a>
      </template>
      <template v-slot:[`item.payment_type`]="{ item }"> {{ getPaymentTypeText(item) }}</template>
      <template v-slot:[`item.logs`]="{ item }">
        <div v-if="item.logs">-</div>
        <div v-else>
          <e-menu min-width="240px">
            <template v-slot:activator="{ on, attrs }">
              <e-button small="" v-bind="attrs" v-on="on" icon="icon-dots-horizontal" variant="ghost"></e-button>
            </template>
            <e-menu-list>
              <e-menu-list-item
                class="mt-1"
                prepend-icon="icon-pie-chart"
                @click.prevent="openServiceLogItem(item.owner_id)"
              >
                View logs
              </e-menu-list-item>
              <e-menu-list-item
              v-show="item.method=='android' || (item.method=='web'&& item.stripe_customer_id)"
                @click="openManageSubscription(item)"
                prepend-icon="icon-pen-underline"
              >
                Manage subscription
              </e-menu-list-item>
            </e-menu-list>
          </e-menu>
        </div>
      </template>
      <!-- no data -->
      <template v-slot:no-data>
        <no-data-block
          icon="icon-d-cube"
          title="No premium added!"
          description="Click add premium to enable premium packages for this user."
        />
      </template>
    </e-data-table>
  </page-container>
</template>

<script>
import moment from 'moment'
import NoDataBlock from '@/components/NoDataBlock'
import PageContainer from '@/components/PageContainer'
import AddPremiumModal from '@/views/data/users/user-details/modals/AddPremiumModal.vue'
import PremiumLogsModal from '@/views/data/users/user-details/modals/PremiumLogsModal.vue'
import PremiumLogsEmptyModal from '@/views/data/users/user-details/modals/PremiumLogsEmptyModal.vue'
export default {
  components: {
    PageContainer,
    NoDataBlock,
  },
  data() {
    return {
      headers: [
        { text: 'Service', value: 'name', sortable: false },
        { text: 'Buyer', value: 'owner', sortable: false },
        { text: 'Other members', value: 'members', sortable: false },
        {text:"Payment type",value:'payment_type',sortable:false},
        { text: 'Settings', value: 'logs', sortable: false },
      ],
    }
  },
  computed: {
    services() {
      return this.$store.getters.userServices
    },
    servicesBusy() {
      return this.$store.getters.servicesBusy
    },
  },
  methods: {
    getPaymentTypeText(item) {
    if (item.method === 'web') {
      return 'Stripe';
    } else if (item.method === 'android') {
      return 'Google';
    } else if (item.method === 'sms') {
      return 'SMS';
    } 
    else if (item.method === 'ios') {
      return 'Apple';
    } 
    else {
      return '';
    }
  },
    showAddPremiumModal () {
      this.$eDialog({
        width: 424,
        component: AddPremiumModal,
      })
    },
    showCancelSubscription (item) {
      this.$eDialog({
        preset: 'dialog',
        presetProps: {
          title: `Are you sure you want to end this ${item.name} subscription?`,
          description: 'The user may need to be refunded.',
          closeButtonLabel: 'Cancel',
          deleteButtonLabel: 'Delete',
        },
        presetEvents: {
          'click:delete': async () => {
            return await this.cancelSubscription(item.premium_recurring_id)
            // return await this.deletePayment()
          }
        }
      })
    },
    getServices() {
      //test Id let userId = 105324996;
      //  let userId = 105324996;
      let userId = this.$route.params.id
      this.$store.dispatch('getUserServices', userId)
    },
    cancelSubscription(subscriptionId) {
      return this.$store.dispatch('cancelSubscription', subscriptionId).then(() => {
        this.getServices()
        return true
      }).catch(e => {
        this.$eToast.error('Something went wrong')
        return false
      })
    },
     async openServiceLogItem(buyerId) {
      let userId = this.$route.params.id
      this.$store.dispatch('getUserServiceLog', { userId, buyerId }).then((response)=>{
        if(response=='No payments in last 18 months.'){
          this.$eDialog({
          width: 524,
          component: PremiumLogsEmptyModal,
        })
        }else{
          this.$eDialog({
          width: 624,
          component: PremiumLogsModal,
        })
        }
      })
    },
    async openServiceLog() {
      let userId = this.$route.params.id
      let buyerId = this.$route.params.id
      this.$store.dispatch('getUserServiceLog', { userId, buyerId }).then((response)=>{
        if(response=='No payments in last 18 months.'){
          this.$eDialog({
          width: 524,
          component: PremiumLogsEmptyModal,
        })
        }else{
          this.$eDialog({
          width: 624,
          component: PremiumLogsModal,
        })
        }
      })
    },
    
    async openManageSubscription(selectedSubscription) {
      if(selectedSubscription.method=='android'){
         window.open('https://play.google.com/console/u/0/developers/6818030252661427794/orders', '_blank')
      }
      else{
        window.open('https://dashboard.stripe.com/customers/'+ selectedSubscription.stripe_customer_id, '_blank')
      }
    },
    editUser(personId) {
      this.$router.push('/data/users/' + personId + '/detailed')
      this.getServices()
    },
  },
  async created() {
    this.getServices()
  },
}
</script>

<style scoped lang="scss">
.required label::after {
  content: '*';
}
.date-block {
  margin-bottom: 34px;
}
.v-btn-toggle > .v-btn.v-btn {
  border-radius: 6px;
}
.card {
  display: flex;
  align-items: flex-start;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
}
.text-block {
  width: 378px;
}
.radio-info {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
}
.radio-details {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.v-application .mb-3 {
  margin-bottom: 12px !important;
}

.button-separator {
  display: flex;
  align-items: center;
  padding: 0 12px;
  &__inner {
    border-right: 1px solid $grayscale300;
    height: 14px;
  }
}
</style>

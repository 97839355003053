<template>
  <StatisticsCard title="Journal statistics" icon="icon-book-open">
    <StatisticsCardField description="Journal count" :value="schoolJournalStatistics.journal_count" />
    <StatisticsCardField description="Individual journal count" :value="schoolJournalStatistics.individual_journal_count" />
    <StatisticsCardField description="Individual lesson count" :value="schoolJournalStatistics.individual_lesson_count" />
    <StatisticsCardField description="Total count of marked tasks as done" :value="schoolTaskStatistics.done_count" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
import StatisticsCard from '@/components/StatisticsCard.vue'
export default {
  components: {StatisticsCard, StatisticsCardField},
  computed: {
    ...mapState('school', ['schoolJournalStatistics', 'schoolTaskStatistics']),
  },
}
</script>

import * as api from '../../api'
import * as types from '../mutation-types'

export default {
  state: {
    success: false,
    busy: false,
    fail: false,
  },
  mutations: {
    [types.LOGIN_USER](state) {
      state.busy = true
    },
    [types.LOGIN_USER_SUCCESS](state, response) {
      state.busy = false
      state.success = response.data.success || false
    },
    [types.LOGIN_USER_FAIL](state, error) {
      state.busy = false
      state.success = error.data.success || false
      state.fail = error
    },
  },
  actions: {
    loginUser: (store, { username, password }) => {
      store.commit(types.LOGIN_USER)
      return api.loginUser(username, password).then(
        (response) => {
          store.commit(types.LOGIN_USER_SUCCESS, response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit(types.LOGIN_USER_FAIL, error)
          return Promise.reject(error)
        }
      )
    },
  },
  getters: {
    loginSuccess: (state) => {
      return state.success
    },
    loginBusy: (state) => {
      return state.busy
    },
    loginError: (state) => {
      return state.fail ? state.fail.data.error || state.fail.statusText : false
    },
  },
}

// login
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'

// user
export const RECIEVE_USER = 'RECIEVE_USER'
export const RECIEVE_USER_SUCCESS = 'RECIEVE_USER_SUCCESS'
export const RECIEVE_USER_FAIL = 'RECIEVE_USER_FAIL'

// countries
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL'

export const GET_HORIZON_TOKEN = 'GET_HORIZON_TOKEN'
export const GET_HORIZON_TOKEN_SUCCESS = 'GET_HORIZON_TOKEN_SUCCESS'
export const GET_HORIZON_TOKEN_FAIL = 'GET_HORIZON_TOKEN_FAIL'

// users
export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'

// user logs
export const GET_USER_LOGS = 'GET_USER_LOGS'
export const GET_USER_LOGS_SUCCESS = 'GET_USER_LOGS_SUCCESS'
export const GET_USER_LOGS_FAIL = 'GET_USER_LOGS_FAIL'

// user info
export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL'

// user last login info
export const GET_USER_LAST_LOGIN = 'GET_USER_LAST_LOGIN'
export const GET_USER_LAST_LOGIN_SUCCESS = 'GET_USER_LAST_LOGIN_SUCCESS'
export const GET_USER_LAST_LOGIN_FAIL = 'GET_USER_LAST_LOGIN_FAIL'

// user roles
export const GET_USER_ROLES = 'GET_USER_SCHOOLS'
export const GET_USER_ROLES_SUCCESS = 'GET_USER_SCHOOLS_SUCCESS'
export const GET_USER_ROLES_FAIL = 'GET_USER_SCHOOLS_FAIL'

// user services
export const GET_USER_SERVICES = 'GET_USER_SERVICES'
export const GET_USER_SERVICES_SUCCESS = 'GET_USER_SERVICES_SUCCESS'
export const GET_USER_SERVICES_FAIL = 'GET_USER_SERVICES_FAIL'

// add premium
export const ADD_PREMIUM = 'ADD_PREMIUM'
export const ADD_PREMIUM_SUCCESS = 'ADD_PREMIUM_SUCCESS'
export const ADD_PREMIUM_FAIL = 'ADD_PREMIUM_FAIL'

// reset premium
export const RESET_PREMIUM = 'RESET_PREMIUM'
export const RESET_PREMIUM_SUCCESS = 'RESET_PREMIUM_SUCCESS'
export const RESET_PREMIUM_FAIL = 'RESET_PREMIUM_FAIL'

// cancel premium
export const CANCEL_PREMIUM = 'CANCEL_PREMIUM'
export const CANCEL_PREMIUM_SUCCESS = 'CANCEL_PREMIUM_SUCCESS'
export const CANCEL_PREMIUM_FAIL = 'CANCEL_PREMIUM_FAIL'

// gets premium users(for owner change)
export const GET_PREMIUM_USERS = 'GET_PREMIUM_USERS'
export const GET_PREMIUM_USERS_SUCCESS = 'GET_PREMIUM_USERS_SUCCESS'
export const GET_PREMIUM_USERS_FAIL = 'GET_PREMIUM_USERS_FAIL'

// change premium owner
export const CHANGE_PREMIUM_OWNER = 'CHANGE_PREMIUM_OWNER'
export const CHANGE_PREMIUM_OWNER_SUCCESS = 'CHANGE_PREMIUM_OWNER_SUCCESS'
export const CHANGE_PREMIUM_OWNER_FAIL = 'CHANGE_PREMIUM_OWNER_FAIL'

// user service logs
export const GET_USER_SERVICE_LOG = 'GET_USER_SERVICE_LOG'
export const GET_USER_SERVICE_LOG_SUCCESS = 'GET_USER_SERVICE_LOG_SUCCESS'
export const GET_USER_SERVICE_LOG_FAIL = 'GET_USER_SERVICE_LOG_FAIL'

// user service settings
export const GET_SERVICE_SETTINGS = 'GET_SERVICE_SETTINGS'
export const GET_SERVICE_SETTINGS_SUCCESS = 'GET_SERVICE_SETTINGS_SUCCESS'
export const GET_SERVICE_SETTINGS_FAIL = 'GET_SERVICE_SETTINGS_FAIL'

// save user info
export const SAVE_USER_INFO = 'SAVE_USER_INFO'
export const SAVE_USER_INFO_SUCCESS = 'SAVE_USER_INFO_SUCCESS'
export const SAVE_USER_INFO_FAIL = 'SAVE_USER_INFO_FAIL'

// delete user
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

// save user info
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

// pricing data charts
export const GET_CHART_DATA = 'GET_CHART_DATA'
export const GET_CHART_DATA_SUCCESS = 'GET_CHART_DATA_SUCCESS'
export const GET_CHART_DATA_FAIL = 'GET_CHART_DATA_FAIL'

// login data charts
export const GET_LOGIN_CHART_DATA = 'GET_LOGIN_CHART_DATA'
export const GET_LOGIN_CHART_DATA_SUCCESS = 'GET_LOGIN_CHART_DATA_SUCCESS'
export const GET_LOGIN_CHART_DATA_FAIL = 'GET_LOGIN_CHART_DATA_FAIL'

// login methods data charts
export const GET_LOGIN_TYPES_CHART_DATA = 'GET_LOGIN_TYPES_CHART_DATA'
export const GET_LOGIN_TYPES_CHART_DATA_SUCCESS = 'GET_LOGIN_TYPES_CHART_DATA_SUCCESS'
export const GET_LOGIN_TYPES_CHART_DATA_FAIL = 'GET_LOGIN_TYPES_CHART_DATA_FAIL'

// reactions data charts
export const GET_REACTIONS_CHART_DATA = 'GET_REACTIONS_CHART_DATA'
export const GET_REACTIONS_CHART_DATA_SUCCESS = 'GET_REACTIONS_CHART_DATA_SUCCESS'
export const GET_REACTIONS_CHART_DATA_FAIL = 'GET_REACTIONS_CHART_DATA_FAIL'

// messaging data charts
export const GET_MESSAGING_CHART_DATA = 'GET_MESSAGING_CHART_DATA'
export const GET_MESSAGING_CHART_DATA_SUCCESS = 'GET_MESSAGING_CHART_DATA_SUCCESS'
export const GET_MESSAGING_CHART_DATA_FAIL = 'GET_MESSAGING_CHART_DATA_FAIL'

// school search
export const GET_SCHOOLS = 'GET_SCHOOLS'
export const GET_SCHOOLS_SUCCESS = 'GET_SCHOOLS_SUCCESS'
export const GET_SCHOOLS_FAIL = 'GET_SCHOOLS_FAIL'

// user mlogin methods data charts
export const GET_USER_LOGIN_METHODS_CHART_DATA = 'GET_USER_LOGIN_METHODS_CHART_DATA'
export const GET_USER_LOGIN_METHODS_CHART_DATA_SUCCESS = 'GET_USER_LOGIN_METHODS_CHART_DATA_SUCCESS'
export const GET_USER_LOGIN_METHODS_CHART_DATA_FAIL = 'GET_USER_LOGIN_METHODS_CHART_DATA_FAIL'

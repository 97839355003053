import * as api from '../../api'
import * as types from '../mutation-types'

export default {
  state: {
    loginChartBusy: false,
    userLoginsData: [],
    dates: [],
  },
  mutations: {
    [types.GET_LOGIN_CHART_DATA](state) {
      state.loginChartBusy = true
    },
    [types.GET_LOGIN_CHART_DATA_SUCCESS](state, payload) {
      state.loginChartBusy = false
      state.dates = []

      let payloadData = {
        job: { jobId: payload.response.data.job_id },
        type: 'loginStatistics',
      }
      this.commit('addJob', payloadData)

      let data = payload.response.data.data
      let dataOverall = []

      // FIXME: rebuild this according to api changes
      data.entries.forEach((date) => {
        state.dates.push(date.name)
        date.types.forEach((typ) => {
          if (dataOverall.find((overallItem) => overallItem?.type === typ.name)) {
            dataOverall = dataOverall.map((item) => {
              if (item.type === typ.name) {
                return {
                  ...item,
                  [date.name]: typ.count_unique,
                }
              }
              return item
            })
          } else {
            dataOverall.push({
              type: typ.name,
              [date.name]: typ.count_unique,
            })
          }
        })
      })

      state.userLoginsData = dataOverall.map((item) => {
        let totalInPeriod = 0
        Object.values(item).forEach((it) => {
          totalInPeriod += it >= 0 ? it : 0
        })
        return {
          ...item,
          totalInPeriod,
        }
      })
    },
    [types.GET_LOGIN_CHART_DATA_FAIL](state) {
      state.loginChartBusy = false
    },
  },
  actions: {
    getLoginChartData: (store, { dateFrom, dateTo, type }) => {
      store.commit(types.GET_LOGIN_CHART_DATA)
      return api.getLoginChartData(dateFrom, dateTo, type).then(
        (response) => store.commit(types.GET_LOGIN_CHART_DATA_SUCCESS, { response, type }),
        (error) => store.commit(types.GET_LOGIN_CHART_DATA_FAIL, error)
      )
    },
  },
  created() {},
  getters: {
    loginDates: (state) => state.dates,
    userLoginsData: (state) => state.userLoginsData,
    loginChartBusy: (state) => state.loginChartBusy,
  },
}

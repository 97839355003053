<template>
  <div class="card">
    <div class="package-title">
      <div>
        <span class="graybase--text headline-3">{{ title }}</span>
      </div>
      <!-- <e-button icon="icon-dots-vertical" variant="ghost" /> -->
       <v-spacer />
      <e-menu>
        <template v-slot:activator="{ on, attrs }">
          <e-button icon="icon-dots-vertical" variant="ghost" v-bind="attrs" v-on="on"> </e-button>
        </template>
        <e-menu-list>
          <e-menu-list-item
            variant="danger"
            prepend-icon="icon-trashcan"
            @click="openDeleteModal"
          >
            Remove package
          </e-menu-list-item>
        </e-menu-list>
      </e-menu>
    </div>
    <div class="package-details">
      <span class="grayscale500--text caption-1"
        >{{ setDateFormat(startDate) }} - {{ setDateFormat(endDate) }}</span
      >
       <v-spacer></v-spacer>
      <span class="grayscale500--text caption-1">{{ packageFeaturesLength }} features </span>
    </div>
    <div>
      <e-chip v-if="checkExpireDate(endDate)" prepend-icon="icon-rotating-arrows" class="mr-1 mt-3"
        >Expired package</e-chip
      >
      <e-chip v-else prepend-icon="icon-star" variant="primary" class="mr-1 mt-3">Active package</e-chip>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  props: ['title', 'startDate', 'endDate', 'schoolPackageId', 'id'],
  data() {
    return {
      schoolId: '',
      // currentPackageFeatures: [],
    }
  },
  methods: {
    openDeleteModal() {
      this.$eDialog({
        preset: 'dialog',
        presetProps: {
          title: 'Are you sure you want to continue?',
          description: 'You are about to remove a package form the school’s packages, this action is irreversible.',
          closeButtonLabel: 'Cancel',
          deleteButtonLabel: 'Yes, remove',
        },
        presetEvents: {
          'click:delete': async () => {
            return await this.deletePackage()
          }
        }
      })
    },
    async deletePackage() {
      try {
        await this.$store.dispatch('school/deleteSchoolPackage', { schoolId: this.schoolId, packageId: this.id })
        this.$eToast.success('School package was successfully removed')
        return true
      } catch (e) {
        this.$eToast.error(e)
        return false
      }

    },
    setDateFormat(value) {
      if (value != null) {
        return moment(value).format('D MMM Y')
      }
    },
    checkExpireDate(date) {
      // let dateExpires = false
      // // date = moment(date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
      // if (date < this.currentDate) {
      //   dateExpires = true
      // }
      // return dateExpires
      return date < this.currentDate
    },
  },
  async created() {
    this.schoolId = this.$route.params.id
    await this.$store.dispatch('schoolPackages/getPackageFeatures', { id: this.schoolPackageId })
    // this.currentPackageFeatures = this.packageFeaturesList
  },
  computed: {
    ...mapState('schoolPackages', ['packageFeaturesList', 'featureList', 'features', 'packageFeaturesBusy']),
    packageFeaturesLength () {
      return this.features[this.schoolPackageId]?.length || 0
    },
    currentDate() {
      return moment().format('YYYY-MM-DD')
    },
  },
}
</script>

<style scoped>
.card {
  height: auto;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.package-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.package-details {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
}
</style>

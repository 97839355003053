<template>
  <div class="statistics-detail">
    <span class="statistics-detail-description">{{ description }}</span>
    <span class="statistics-detail-value" :class="warning ? 'statistics-detail-value-warning' : ''">{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: 'StatisticsCardField',
  props: {
    description: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    warning: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<template>
  <div>
    <e-dialog-header @click:close="close">
      Edit local government
    </e-dialog-header>
    <e-dialog-content>
      <loading-overlay :value="loading" />
      <v-form ref="form" v-model="valid">
        <e-text-field
          class="pb-8 pt-5"
          :value="government.name"
          label="Name"
          v-model="government.name"
          required
          :rules="[validators.required]"
        />

        <e-text-field
          class="pb-8"
          :value="government.school_count"
          label="Number of schools"
          v-model="government.school_count"
        />

        <e-text-field :value="government.population" label="Population" v-model="government.population" />
      </v-form>
    </e-dialog-content>
    <e-dialog-actions>
      <e-button variant="tertiary" @click="close">Cancel</e-button>
      <e-button @click="save">Save edit</e-button>
    </e-dialog-actions>
  </div>
</template>

<script>
import { required } from '@core/utils/validation'

export default {
  name: 'EditGovernmentModal',
  props: {
    governmentData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      valid: false,
      government: {
        id: null,
        name: '',
        school_count: null,
        population: null,
      },
      loading: false,
    }
  },

  mounted() {
    this.government = { ...this.governmentData }
  },
  computed: {
    validators() {
      return {
        required,
      }
    },
  },
  methods: {
    close() {
      this.$root.$emit('submit')
    },
    save() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.loading = true
      this.$store
        .dispatch('governments/updateGovernment', {
          id: this.government.id,
          name: this.government.name,
          school_count: this.government.school_count,
          population: this.government.population,
        })
        .then(() => {
          this.loading = false
          this.$store.dispatch('governments/getLocalGovernments')
          this.$eToast.success('Local government was successfully edited')
          this.close()
        },
          (err) => {
            this.loading = false
            this.$eToast.error(err)
            console.error(err)
          }
        )
    },
  },
}
</script>

<template>
  <StatisticsCard title="OPIQ statistics" icon="icon-file-folders">
    <StatisticsCardField description="Linked journals" :value="schoolOpiqStatistics.linked_journals" />
    <StatisticsCardField description="Linked homeworks" :value="schoolOpiqStatistics.linked_homeworks" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  components: {StatisticsCardField, StatisticsCard},
  computed: {
    ...mapState('school', ['schoolOpiqStatistics']),
  },
}
</script>

<template>
  <page-container title="Local governments">
    <template #actions>
      <e-button large variant="primary" @click="openModal">
        Add local government
      </e-button>
    </template>
    <government-table />
  </page-container>
</template>

<script>
import GovernmentTable from './GovernmentTable.vue'
import PageContainer from '@/components/PageContainer'
import AddGovernment from './modals/AddGovernmentModal.vue'

export default {
  components: {
    PageContainer,
    GovernmentTable,
    AddGovernment,
  },
  methods: {
    openModal() {
      this.$eDialog({
        component: AddGovernment
      })
    }
  }
}
</script>

import * as api from '../../api'
import * as types from '../mutation-types'

export default {
  state: {
    success: false,
    busy: false,
    fail: false,
    options: {
      page: 1,
      perPage: 50,
    },
    search: '',
    searchBy: 'person.id',
    draw: 0,
    users: [],
    response: {},
    filter: 'person.id',
  },
  mutations: {
    [types.GET_USER_LOGS](state) {
      state.busy = true
    },
    [types.GET_USER_LOGS_SUCCESS](state, response) {
      state.busy = false
      state.response = response || {}

      state.draw++
    },
    [types.GET_USER_LOGS_FAIL](state, error) {
      state.busy = false
      state.success = false
      state.fail = true
      state.response = error
      //console.log('error')
    },
    updateLogsTableOptions(state, options) {
      state.options = options
    },
    updateLogsSearch(state, search) {
      // Stateless
      state.search = search
    },
    updateLogsSearchBy(state, searchBy) {
      state.searchBy = searchBy
    },
    updateLogsPerPage(state, perPage) {
      state.perPage = perPage
    },
    updateLogsFilter(state, filter) {
      state.filter = filter
    },
  },
  actions: {
    getUserLogs: (store, { page, perPage, search, dateFrom, dateTo, searchBy }) => {
      store.commit(types.GET_USER_LOGS)
      return api.getUserLogs(page, perPage, search, dateFrom, dateTo, searchBy).then(
        (response) => {
          if (response.data.error) {
            store.commit(types.GET_USER_LOGS_FAIL, response.data.error)
            throw new Error(response.data.error)
          }
          store.commit(types.GET_USER_LOGS_SUCCESS, response)
        },
        (error) => store.commit(types.GET_USER_LOGS_FAIL, error)
      )
    },
  },
  getters: {
    userLogTable: (state) => {
      let filteredResponse = {}
      if (
        typeof state.response.data !== 'undefined' &&
        state.response.data.success &&
        state.response.data.data.length !== 0
      ) {
        filteredResponse['draw'] = state.draw
        filteredResponse['recordsFiltered'] = state.response.data.data.total
        filteredResponse['recordsTotal'] = state.response.data.data.total
        filteredResponse['data'] = state.response.data.data.data

        state.response.data.data.data.forEach((data) => {
          data['type'] = data['web'] === true ? 'WEB' : 'APP'
        })
      } else {
        filteredResponse['draw'] = state.draw
        filteredResponse['recordsFiltered'] = 0
        filteredResponse['recordsTotal'] = 0
        filteredResponse['data'] = []
      }

      return filteredResponse
    },
    userLogsTableOptions: (state) => {
      return state.options
    },
    userLogsSearch: (state) => {
      return state.search
    },
    userLogsSearchBy: (state) => {
      return state.searchBy
    },
    userLogsPerPage: (state) => {
      return state.perPage
    },
    userLogsFilter: (state) => {
      return state.filter
    },
  },
}

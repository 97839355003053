<template>
  <section>
    <!-- Left section -->
    <div class="d-flex flex-wrap flex-md-nowrap">
      <!-- <user-info :user="user"></user-info> -->
      <user-info class="flex-grow-1 flex-md-grow-0"></user-info>
      <!-- Right section -->
      <!-- v-if for correct tab-slider's size   -->

      <!-- <e-circular-loading :value="userBusy" class="pa-16"/> -->

      <div v-if="!userBusy" class="flex flex-column overflow-x-hidden w-full flex-md-row flex-lg-row ml-0 ml-md-5 mt-8 mt-md-0">
        <div class="pb-6 overflow-x-hidden">
          <e-tabs grow :tabs="tabs" :value="tab" @change="newTab => tab = newTab" />
        </div>
        <div v-if="tab === 'roles'">
          <user-roles></user-roles>
        </div>
        <div v-else-if="tab === 'services'">
          <user-services></user-services>
        </div>
        <div v-else-if="tab === 'notifications'">
          <user-email></user-email>
        </div>
        <div v-else-if="tab === 'sessions'">
          <user-sessions></user-sessions>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserInfo from './UserInfo.vue'
import UserRoles from './roles/UserRoles.vue'
import UserServices from './services/UserServices.vue'
import UserEmail from './email/UserEmail.vue'
import UserSessions from './sessions/UserSessions.vue'
export default {
  components: {
    UserInfo,
    UserRoles,
    UserServices,
    UserEmail,
    UserSessions
  },
  data() {
    return {
      tab: 'roles',
      tabs: [
        { id: 'roles', name: 'Roles'},
        { id: 'services', name: 'Services'},
        { id: 'notifications', name: 'Email notifications'},
        { id: 'sessions', name: 'Sessions'},
      ],
    }
  },
  computed: {
    userBusy() {
      return this.$store.getters.userInfoBusy
    },
  },
  async created() {
    let userId = this.$route.params.id
    //      let userId = 105326246;
    let page = 1
    let perPage = 50
    //   this.$store.dispatch('getUserInfo',{userId})
    // this.$store.dispatch('getUserRoles',{userId,page,perPage})
    this.$store.dispatch('getUserRoles', { userId, page, perPage })
  },
}
</script>

<style scoped>
/* s */
</style>

<template>
  <span class="cursor-pointer" @click="copy($slots.default ? $slots.default[0].text : text)">
    <e-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <slot>
            {{text}}
          </slot>
        </span>
      </template>
    <span>Copy to clipboard</span>
  </e-tooltip>
  </span>
</template>

<script>
export default {
  name: 'CopySpan',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    copy (text) {
      const that = this
      navigator.clipboard.writeText(text).then(function() {
        that.$eToast.success('Copied!')
      }, function(err) {
        that.$eToast.error(err)
      });
    },
  }
}
</script>

<template>
  <page-container title="Schools">
    <template #actions>
      <e-switch
        class="mr-4 align-center"
        v-model="showAllSchools"
        label="Show all published schools"
      />
      <e-button large variant="primary" @click="showModal"> Add school </e-button>
    </template>
    <school-table ref="school_table" :showAllSchools="showAllSchools"></school-table>
  </page-container>
</template>

<script>
import SchoolTable from './SchoolTable.vue'
import PageContainer from '@/components/PageContainer'
import AddSchoolModal from '../modals/AddSchool.vue'

export default {
  components: {
    PageContainer,
    SchoolTable,
    AddSchoolModal,
  },
  data() {
    return {
      showAllSchools: false,
    }
  },
  methods: {
    showModal() {
      this.$eDialog({
        width: 424,
        component: AddSchoolModal,
      })
    }
  }
}
</script>

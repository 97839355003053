import PackagesAndFeaturesMain from '@/views/premium/packagesAndFeatures/PackagesAndFeaturesMain.vue'
import PaymentMethods from '@/views/premium/paymentMethods/PaymentMethods.vue'

const premium = [
  {
    path: '/premium/packages-and-features',
    name: 'premium-packages',
    component:PackagesAndFeaturesMain,
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/premium/payments',
    name: 'premium-payment-methods',
    component: PaymentMethods,
    meta: {
      layout: 'content',
    },
  },
]

export default premium

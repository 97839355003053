export default [
  {
    subheader: 'statistics',
  },
  {
    title: 'logins',
    icon: 'icon-pie-chart',
    children: [
      {
        title: 'user_logins',
        to: 'statistics-logins-user',
      },
      {
        title: 'login_methods',
        to: 'statistics-login-methods',
      },
    ],
  },
  {
    title: 'usage',
    icon: 'icon-pie-chart',
    children: [
      {
        title: 'messaging',
        to: 'statistics-usage-messaging',
      },
      {
        title: 'reactions',
        to: 'statistics-usage-reactions',
      },
    ],
  },
  {
    title: 'horizon',
    icon: 'icon-pie-chart',
    to: 'statistics-horizon',
  },
]

<template>
  <div class="card">
    <div class="price">
      <div>
        <e-icon class="success300--text"> icon-money-bill </e-icon>
        <span class="ml-2 success300--text subhead-2">{{ price }} EUR</span>
      </div>
      <!-- <e-button icon="icon-dots-vertical" variant="ghost" /> -->
      <v-spacer></v-spacer>
      <div>
        <div class="text-center">
          <e-menu>
            <template v-slot:activator="{ on, attrs }">
              <e-button icon="icon-dots-vertical" variant="ghost" v-bind="attrs" v-on="on"> </e-button>
            </template>
            <e-menu-list class="menu">
              <e-menu-list-item
                prepend-icon="icon-pen-underline"
                @click="showEditPaymentModal"
              >
                Edit payment
              </e-menu-list-item>
              <e-divider />
              <e-menu-list-item
                variant="danger"
                prepend-icon="icon-trashcan"
                @click="showDeletePaymentModal"
              >
                Remove payment
              </e-menu-list-item>
            </e-menu-list>
          </e-menu>
        </div>
      </div>
    </div>
    <div class="details">
      <h3 class="graybase--text">{{ paymentName }}</h3>
      <div class="payer">
        <span class="grayscale500--text caption-1">{{ payer }}</span>
        <span> ⋅ </span>
        <span class="grayscale500--text caption-1">{{ customerId }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import EditPayment from '@/views/data/schools/modals/EditPayment.vue'
export default {
  props: ['price', 'paymentName', 'payer', 'customerId', 'id', 'paymentMethodId'],
  methods: {
    showEditPaymentModal() {
      this.$eDialog({
        component: EditPayment,
        componentProps: {
        paymentId: this.id,
        paymentFee: this.price,
        paymentName: this.paymentName,
        payer: this.payer,
        customerId: this.customerId,
        paymentMethodId: this.paymentMethodId
        }
      })
    },
    showDeletePaymentModal() {
      this.$eDialog({
        preset: 'dialog',
        presetProps: {
          title: 'Are you sure you want to continue?',
          description: 'You are about to remove a payment option from the school’s payments, this action is irreversible.',
          closeButtonLabel: 'Cancel',
          deleteButtonLabel: 'Yes, remove',
        },
        presetEvents: {
          'click:delete': async () => {
            return await this.deletePayment()
          }
        }
      })
    },
    async deletePayment() {
      try {
        await this.$store.dispatch('school/deletePayment', { id: this.id })
        this.$eToast.success('Payment method was successfully removed')
        return true
      } catch (e) {
        this.$eToast.error(e)
        return false
      }
    }
  }
}
</script>

<style scoped>
.card {
  height: 146px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.payer {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>

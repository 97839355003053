import Vue from 'vue'
import VueNativeSock from 'vue-native-websocket'

export default {
  state: {
    socket: {
      message: '',
      isConnected: false,
      reconnectError: false,
      reconnecting: false,
    },
    jobs: {
      userEdit: {},
      loginStatistics: {},
    },
  },
  mutations: {
    setUpSocket(state) {
      Vue.use(VueNativeSock, process.env.WEBSOCKET_URL_WS, {
        store: this,
        format: 'json',
        reconnection: true,
        reconnectionDelay: 30000,
      })
      state.socketController = this._vm.$socket
    },
    addJob(state, payload) {
      state.jobs[payload.type] = payload.job
    },
    SOCKET_ONOPEN(state) {
      let userId = this.getters.loggedUser.id
      state.socket.isConnected = true
      state.socketController.sendObj({ api: 'register', user_id: userId })
      function pingLooper() {
        if (state.socket.isConnected) {
          state.socketController.sendObj({ api: 'ping' })
        }
      }
      state.socket.reconnecting = false
      setInterval(pingLooper, 30000)
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR(state) {
      state.socket.isConnected = false
    },
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message
      if (message && message.job_id && message.response) {
        Object.keys(state.jobs).forEach(function (key) {
          if (state.jobs[key] && state.jobs[key].jobId === message.job_id) {
            if (key === 'loginStatistics') {
              let countArray = message.response.data.map(function (type) {
                return type.count_unique
              })
              let sumTotal = countArray.reduce(function (a, b) {
                return a + b
              }, 0)
              message.response.data.unshift({ name: 'Unique logins', count_unique: sumTotal })
            }
            Vue.set(state.jobs[key], 'data', message.response.data)
          }
        })
      }
    },
    SOCKET_RECONNECT() {},
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true
    },
  },
  actions: {},
  getters: {
    jobs: (state) => state.jobs,
  },
}

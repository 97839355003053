import * as api from '../../api'
import Vue from 'vue'
import { flatten } from 'lodash'
export default {
  namespaced: true,
  state: {
    // get package
    packageList: [],
    packageListBusy: false,
    // create package
    packageCreateBusy: false,
    // update package
    packageUpdateBusy: false,
    //get package features
    packageFeaturesList: [],
    packageFeaturesBusy: false,
    //create feature
    featureCreateSuccess: false,
    featureCreateFail: false,
    featureCreateBusy: false,
    //update feature
    featureUpdateSuccess: false,
    featureUpdateFail: false,
    featureUpdateBusy: false,
    //get feature list
    featureList: [],
    featureListBusy: false,
    activeFeatureId: null,
    packageFeatureListSuccess: false,
    errorMessage: '',

    // new approach
    features: {},
  },
  mutations: {
    getPackageListSuccess(state, response) {
      const data = response.data
      state.packageListBusy = false

      if (data.success) {
        state.packageList = data.data
        // state.packageList.forEach((el) => el.reverse())
      }
    },
    getPackageListFail(state) {
      state.packageListBusy = false
    },
    updatePackageListBusy(state, value) {
      state.packageListBusy = value
    },
    createPackageSuccess(state, response) {
      state.packageCreateBusy = false

      if (response.data.success) {
        state.packageList.push(response.data.data)
      }
    },
    createPackageFail(state, response) {
      state.packageCreateBusy = false
      state.packageCreateFail = true
      state.errorMessage = response.data.error
      // response
    },
    updatePackageCreateBusy(state, value) {
      state.packageCreateBusy = value
    },
    resetPackageSave(state) {
      state.schoolPackageUpdateSFail = false
    },
    updatePackageSuccess(state, response) {
      state.packageUpdateBusy = false
      if (response.data.success) {
        const packageData = response.data.data
        const index = state.packageList.findIndex((currentPackage) => currentPackage.id == packageData.id)
        if (index != null) {
          Vue.set(state.packageList, index, packageData)
        }
      }
    },
    updatePackageFail(state, response) {
      state.packageUpdateBusy = false
      response
    },
    updatePackageUpdateBusy(state, value) {
      state.packageUpdateBusy = value
    },
    getPackageFeaturesListSuccess(state, response) {
      const data = response.data

      if (data.success) {
        state.packageFeaturesList = data.data
      }

      state.packageFeaturesBusy = false
    },
    updatePackageFeaturesSuccess(state, response) {
      const data = response.data

      if (data.success) {
        if (data.data != 'Nothing new inserted') {
          state.packageFeaturesList = state.packageFeaturesList.concat(data.data)
        }
        state.packageFeatureListSuccess = true
      }
    },
    updatePackageFeatureListSuccess(state, value) {
      state.packageFeatureListSuccess = value
    },
    updatePackageFeaturesBusy(state, value) {
      state.packageFeaturesBusy = value
    },
    getPackageFeaturesListFail(state, response) {
      state.packageFeaturesBusy = false
      response
    },
    createPackageFeatureSuccess(state, response) {
      state.featureCreateSuccess = true
      state.featureCreateBusy = false
      if (response.data.success) {
        state.featureList.push(response.data.data)
      }
    },
    createPackageFeatureFail(state, response) {
      state.featureCreateFail = true
      state.featureCreateBusy = false

      response
    },
    updatePackageFeatureSuccess(state, response) {
      state.featureUpdateSuccess = true
      state.featureUpdateBusy = false
      if (response.data.success) {
        const featureData = response.data.data
        const index = state.featureList.findIndex((currentFeature) => currentFeature.id == featureData.id)
        if (index != null) {
          Vue.set(state.featureList, index, featureData)
        }
      }
    },
    updatePackageFeatureFail(state, response) {
      state.featureUpdateFail = true
      state.featureUpdateBusy = false
      response
    },
    deletePackageFeatureSuccess(state, response) {
      if (response.data.success) {
        const index = state.featureList.findIndex((currentFeature) => currentFeature.id == state.activeFeatureId)
        if (index != null) {
          Vue.delete(state.featureList, index)
        }
      }
    },
    getFeatureListSuccess(state, response) {
      state.featureListBusy = false
      state.featureList = response.data.data
    },
    extendFeatureList(state, response) {
      // TODO: temporary solution for feature's counter of uses
      const mappedResponse = flatten(response.map((r) => r.data.data))
      state.featureList = state.featureList.map((feature) => {
        return {
          ...feature,
          packagesCount: mappedResponse.filter(
            (packageFeature) => feature.id === packageFeature.school_package_feature_id
          ).length,
        }
      })
    },
    getFeatureListFail(state) {
      state.featureListBusy = false
    },
    updateFeatureListBusy(state, response) {
      state.featureListBusy = response
    },
    resetFeatureSave(state) {
      state.featureCreateSuccess = false
      state.featureCreateFail = false
      state.featureUpdateSuccess = false
      state.featureUpdateFail = false
    },
    setActiveFeatureId(state, value) {
      state.activeFeatureId = value
    },
    removePackageFeature(state, data) {
      let removeIds = data.delete
      if (removeIds.length == 0) {
        return
      }
      state.packageFeaturesList = state.packageFeaturesList.filter(
        (packageEntry) => !removeIds.includes(packageEntry.id)
      )
    },




    //new approach
    getPackageFeaturesSuccess(state, { response, id }) {
      state.features = {
        ...state.features,
        [id]: response.data.data
      }
    },
  },
  actions: {
    getPackageList(store) {
      store.commit('updatePackageListBusy', true)
      return api.getPackageList().then(
        (response) => {
          store.commit('getPackageListSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('getPackageListFail', error)
          return Promise.reject(error)
        }
      )
    },
    createPackage(store, { name, price }) {
      store.commit('updatePackageCreateBusy', true)
      return api.createPackage(name, price).then(
        (response) => {
          store.commit('createPackageSuccess', response)
          return Promise.resolve(response.data.data)
        },
        (error) => {
          store.commit('createPackageFail', error)
          return Promise.reject(error)
        }
      )
    },
    deletePackage(store, id) {
      store.commit('updatePackageUpdateBusy', true)
      return api.deletePackage(id).then(
        (response) => {
          store.commit('deletePackageSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('deletePackageFail', error)
          return Promise.reject(error)
        }
      )
    },
    updatePackage(store, { name, price, id }) {
      store.commit('updatePackageUpdateBusy', true)
      return api.updatePackage(name, price, id).then(
        (response) => {
          store.commit('updatePackageSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updatePackageFail', error)
          return Promise.reject(error)
        }
      )
    },
    getFeatureList(store) {
      store.commit('updateFeatureListBusy', true)
      return api.getFeatureList().then(
        (response) => {
          store.commit('getFeatureListSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('getFeatureListFail', error)
          return Promise.reject(error)
        }
      )
    },
    getPackageFeaturesList(store, { id }) {
      store.commit('updatePackageFeaturesBusy', true)
      return api.getPackageFeaturesList(id).then(
        (response) => {
          store.commit('getPackageFeaturesListSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('getPackageFeaturesListFail', error)
          return Promise.reject(error)
        }
      )
    },
    getFeaturesForEachPackage(store) {
      // store.commit('updatePackageFeaturesBusy', true)
      const packages = store.state.packageList
      return Promise.all(packages.map((p) => api.getPackageFeaturesList(p.id))).then(
        (response) => {
          store.commit('extendFeatureList', response)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    /* eslint-disable camelcase */
    createPackageFeature(store, { name, feature_key }) {
      return api.createPackageFeature(name, feature_key).then(
        (response) => {
          store.commit('createPackageFeatureSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('getPackageFeaturesListFail', error)
          return Promise.reject(error)
        }
      )
    },

    updatePackageFeature(store, { name, feature_key, id }) {
      return api.updatePackageFeature(name, feature_key, id).then(
        (response) => {
          store.commit('updatePackageFeatureSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          store.commit('updatePackageFeatureFail', error)
          return Promise.reject(error)
        }
      )
    },
    deletePackageFeature(store, { id }) {
      store.commit('setActiveFeatureId', id)
      return api.deletePackageFeature(id).then(
        (response) => {
          store.commit('deletePackageFeatureSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    updatePackageFeatureList(store, { packageId, featureList, data }) {
      store.commit('updatePackageFeatureListSuccess', false)
      store.commit('removePackageFeature', data)
      return api
        .updatePackageFeatureList(packageId, featureList, data)
        .then(
          (response) => {
            store.commit('updatePackageFeaturesSuccess', response)
            return Promise.resolve(response)
          },
          (error) => {
            return Promise.reject(error)
          }
        )
    },
    deletePackageFeatureList(store, { packageId, featureId }) {
      return api.deletePackageFeatureList(packageId, featureId)
      // .then((response) => store.commit('updatePackageFeatureListSuccess', response),
      // 	(error) => store.commit('updatePackageFeatureListFail', error))
    },




    // new approach
    getPackageFeatures(store, { id }) {
      return api.getPackageFeaturesList(id).then(
        (response) => {
          store.commit('getPackageFeaturesSuccess', { response, id })
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    //
  },
}

<template>
  <div class="vertical-nav-header pb-2 pt-4">
    <router-link to="/data/users" class="text-decoration-none">
      <div class="flex-cont">
        <e-logo small class="pl-3 pl-sm-5" />
        <e-divider vertical class="divider" />
        <h2 class="app-title"> Dashboard </h2>
      </div>
    </router-link>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import themeConfig from '@themeConfig'
import { inject } from '@vue/composition-api'

export default {
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
.vertical-nav-header {
  width: 260px;
  height: 66px;
}
.app-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #9ca3af;
}
.divider {
  height: 34px;
}
.flex-cont {
  display: flex;
  align-items: center;
  width: 260px;
  gap: 12px;
}
</style>

<template>
  <StatisticsCard title="Alarmbell statistics" icon="icon-alarm-bell">
    <StatisticsCardField description="Total alarm count" :value="schoolAlarmbellStatistics.total" />
    <StatisticsCardField description="Active alarms" :value="schoolAlarmbellStatistics.active" />
    <StatisticsCardField description="New alarms" :value="schoolAlarmbellStatistics.new" />
    <StatisticsCardField description="Closed alarms total count" :value="schoolAlarmbellStatistics.closed" />
    <StatisticsCardField description="Closed alarms in last month" :value="schoolAlarmbellStatistics.closed_last_month" />
  </StatisticsCard>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsCard from '@/components/StatisticsCard.vue'
import StatisticsCardField from '@/components/StatisticsCardField.vue'
export default {
  name: 'AlarmbellStatistics',
  components: {StatisticsCardField, StatisticsCard},
  computed: {
    ...mapState('school', ['schoolAlarmbellStatistics']),
  },
}
</script>

import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

import * as api from '../api'
import * as types from './mutation-types'

import login from './modules/login'
import users from './modules/users'
import usersEdit from './modules/usersEdit'
import userLogs from './modules/userLogs'
import userLogins from './modules/userLogins'
import userLoginMethods from './modules/userLoginMethods'
import reactions from './modules/reactions'
import messaging from './modules/messaging'
import socket from './modules/socket'
import schools from './modules/schools'
import school from './modules/school'
import payments from './modules/payments'
import governments from './modules/governments'
import gradeLogs from './modules/gradeLogs'
import schoolPackages from './modules/schoolPackages'
import sessions from './modules/sessions'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loaderCount: 0,
    loader: false,
    busy: false,
    fail: false,
    user: {},
    countries: [],
    token: '',
  },
  mutations: {
    [types.RECIEVE_USER](state) {
      state.busy = true
    },
    [types.RECIEVE_USER_SUCCESS](state, response) {
      state.busy = false
      state.user = response.data.data || {}
    },
    [types.RECIEVE_USER_FAIL](state, error) {
      state.busy = false
      state.fail = error
    },
    [types.GET_COUNTRIES](state) {
      state.busy = true
    },
    [types.GET_COUNTRIES_SUCCESS](state, response) {
      state.busy = false
      state.countries = response.data.data || {}
    },
    [types.GET_COUNTRIES_FAIL](state, error) {
      state.busy = false
      state.fail = error
    },

    [types.GET_HORIZON_TOKEN](state) {
      state.busy = true
    },
    [types.GET_HORIZON_TOKEN_SUCCESS](state, response) {
      state.busy = false
      state.token = response.data.data || {}
    },
    [types.GET_HORIZON_TOKEN_FAIL](state, error) {
      state.busy = false
      state.fail = error
    },
  },
  actions: {
    startLoader: (store) => {
      store.state.loaderCount += 1
    },
    stopLoader: (store) => {
      store.state.loaderCount -= 1
    },
    resetLoader: (store) => {
      store.state.loaderCount = 0
    },
    enableOverlayLoader: (store) => {
      store.state.loader = true
    },
    disableOverlayLoader: (store) => {
      store.state.loader = false
    },
    getUser: (store) => {
      store.commit(types.RECIEVE_USER)
      return api.cancelRequest('getUsersCancel')
        .then((response) => {
          store.commit(types.RECIEVE_USER_SUCCESS, response)
        })
        .catch((error) => store.commit(types.RECIEVE_USER_FAIL, error))
    },
    getCountries: (store) => {
      store.commit(types.GET_COUNTRIES)
      return api
        .getCountries()
        .then((response) => store.commit(types.GET_COUNTRIES_SUCCESS, response))
        .catch((error) => store.commit(types.GET_COUNTRIES_FAIL, error))
    },
    getHorizonToken: (store) => {
      store.commit(types.GET_HORIZON_TOKEN)
      return api
        .getHorizonToken()
        .then((response) => store.commit(types.GET_HORIZON_TOKEN_SUCCESS, response))
        .catch((error) => store.commit(types.GET_HORIZON_TOKEN_FAIL, error))
    },
  },
  getters: {
    loggedUser: (state) => {
      return state.user
    },
    showLoader: (state) => {
      return state.loaderCount > 0
    },
    showOverlayLoader: (state) => {
      return state.loader
    },
    countries: (state) => {
      return state.countries || []
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    login,
    users,
    usersEdit,
    userLogs,
    userLogins,
    userLoginMethods,
    reactions,
    messaging,
    socket,
    schools,
    school,
    payments,
    governments,
    gradeLogs,
    schoolPackages,
    sessions,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [],
})
